import "./style/index.scss";
import { Button, Flex, Modal, Typography, Upload, UploadFile, Image } from "antd";
import { RcFile, UploadProps } from "antd/es/upload";
import Spinner from "../../../components/spinner/spinner";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ConfiguracoesState, setLoading, setPaginaAgendamento, setPaginaAnterior, setVoltar } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { CameraOutlined, SendOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { AnexoModel, filesModel } from "../../../models/perfil/anexoModel";
import { PerfilState, cadastrarAnexoCarteirinhaFetch, cadastrarAnexoGuiaFetch } from "../../../redux/slices/perfil/perfilSlice";
import CardTitle from "../anexos/components/cardTitle";
import { AgendamentoState, buscarHorariosFetch } from "../../../redux/slices/agendamento/agendamentoSlice";
import { cadastrarAnexoCarteirinha, cadastrarAnexoGuiaAgendamento } from "../../../services/perfilService";
import agendConf from "../../../util/assets/agendConf.png";
import naoEncontrado from "../../../util/assets/nao_encontrado.png";
import { tempoBloqueio } from "../../../services/agendamentoService";

const { Title, Text } = Typography;

const AnexosObrigatorios = () => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);
  const perfilState: PerfilState = useAppSelector((state) => state.perfil);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const agendamentoState: AgendamentoState = useAppSelector((state) => state.agendamento);

  //Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //Estados Locais
  const [imgCarteirinha64, setImgCarteirinha64] = useState<string>("");
  const [imgCarteirinha, setImgCarteirinha] = useState<UploadFile[]>([]);
  const [imgGuia64, setImgGuia64] = useState<any[]>([]);
  const [imgGuia, setImgGuia] = useState<UploadFile[]>([]);
  const [previewOpenCarteirinha, setPreviewOpenCarteirinha] = useState(false);
  const [previewImgCarteirinha, setPreviewImgCarteirinha] = useState("");
  const [previewOpenGuia, setPreviewOpenGuia] = useState(false);
  const [previewImgGuia, setPreviewImgGuia] = useState("");

  const [solicitouCarteirinha, setSolicitouCarteirinha] = useState<boolean>(true);
  const [solicitouGuia, setSolicitouGuia] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalAvancar, setOpenModalAvancar] = useState<boolean>(false);
  const [tempo, setTempo] = useState<number>(0);
  const [timerMinutes, setTimerMinutes] = useState<number>(0);
  const [timerSeconds, setTimerSeconds] = useState<number>(0);
  const [loadingTimer, setLoadingTimer] = useState<boolean>(true);

  let interval: any;

  const startTimer = (tempo: number) => {
    const countDownDate = new Date().getTime() + tempo * 1000;

    interval = setInterval(() => {
      const now = new Date().getTime();

      const distance = countDownDate - now;

      const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
      const seconds = Math.floor((distance % (60 * 1000)) / 1000);

      if (distance < 0) {
        clearInterval(interval);
        setTimerMinutes(0);
        setTimerSeconds(0);
        setLoadingTimer(false);
        setOpenModal(true);
      } else {
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
      if (distance > 0) {
       setLoadingTimer(false);
      }
    }, 1000);
  };

  const fetchTimer = async () => {
    const retorno = await tempoBloqueio(agendamentoState.bloquearHorario.idAgendamento);
    startTimer(retorno.data.data);
  };

  useEffect(() => {
    dispatch(setVoltar(true));
    dispatch(setPaginaAnterior("horarios"));
    setSolicitouCarteirinha(agendamentoState.bloquearHorario.solicitarCarteirinha);
    agendamentoState.bloquearHorario.solicitarCarteirinha ? setImgCarteirinha([]) : setImgCarteirinha([{ uid: "-1", name: "", status: "done", url: "" }]);
    setSolicitouGuia(agendamentoState.bloquearHorario.obrigatorioSolicitarDocumentos);
    fetchTimer();
    let intervalId = setInterval(() => {
      setTempo((prevTempo) => {
        if (prevTempo >= 500) {
          clearInterval(intervalId);
          return prevTempo;
        }
        return prevTempo + 1;
      });
    }, 1);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const uploadButtonCarteirinha = (
    <div className="uploadButton">
      <CameraOutlined style={{ display: "block", fontSize: 30 }} rev={undefined} />
    </div>
  );

  const uploadButtonGuia = (
    <div className="uploadButton">
      <CameraOutlined style={{ display: "block", fontSize: 30 }} rev={undefined} />
    </div>
  );

  //Retorna string de base64 para ser enviada para API
  const toBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  //Função auxiliar para preview de imagem do upload
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleChangeCarteirinha: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    if (newFileList[0] !== undefined && (newFileList[0].type === "image/jpeg" || newFileList[0].type === "image/png" || newFileList[0].type === "application/pdf")) {
      if (newFileList[0].size !== undefined && newFileList[0].size / 1024 / 1024 < 10) {
        if (newFileList[0] !== undefined) {
          toBase64(newFileList[0].originFileObj, (imageUrlResponse: any) => {
            setImgCarteirinha64(imageUrlResponse);
          });
        }
        setImgCarteirinha(newFileList);
      } else {
        toast.warning("Tamanho máximo de arquivo é de 10MB");
      }
    } else {
      if (newFileList.length !== 0) {
        toast.warning("Formato de arquivo inválido");
      } else {
        setImgCarteirinha(newFileList);
      }
    }
  };

  const handlePreviewCarteirinha = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImgCarteirinha(file.url || (file.preview as string));
    setPreviewOpenCarteirinha(true);
  };

  const handleCancelCarteirinha = () => setPreviewOpenCarteirinha(false);

  const handleChangeGuia: UploadProps["onChange"] = async ({ fileList: newFileList }) => {
    if (newFileList[newFileList.length - 1] !== undefined && (newFileList[newFileList.length - 1].type === "image/jpeg" || newFileList[newFileList.length - 1].type === "image/png" || newFileList[newFileList.length - 1].type === "application/pdf")) {
      const lastSize = newFileList[newFileList.length - 1].size;
      if (lastSize !== undefined && lastSize / 1024 / 1024 < 10) {
        const newBase64Promises = newFileList.map(
          (file: UploadFile) =>
            new Promise((resolve) => {
              toBase64(file.originFileObj, (imageUrlResponse: any) => {
                resolve(imageUrlResponse);
              });
            })
        );
        const newBase64Strings = await Promise.all(newBase64Promises);
        setImgGuia(newFileList);
        setImgGuia64(newBase64Strings);
      } else {
        toast.warning("Tamanho máximo de arquivo é de 10MB");
      }
    } else {
      if (newFileList.length !== 0) {
        toast.warning("Formato de arquivo inválido");
      } else {
        setImgGuia(newFileList);
      }
    }
  };

  const handlePreviewGuia = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImgGuia(file.url || (file.preview as string));
    setPreviewOpenGuia(true);
  };

  const handleCancelGuia = () => setPreviewOpenGuia(false);

  const salvarAnexoCarteirinha = async () => {
    dispatch(setLoading(true));
    var anexosCarteirinha: AnexoModel = {
      idAtendimento: agendamentoState.bloquearHorario.idAtendimento,
      idAgendamento: agendamentoState.bloquearHorario.idAgendamento,
      anexos: [
        {
          nomeArquivo: imgCarteirinha[0].name,
          extensao: imgCarteirinha[0].type?.split("/")[1],
          arquivo: imgCarteirinha64,
        },
      ],
      userId: perfilState.perfil.idUsuario,
      clientId: loginState.empresa,
    };
    try {
      await cadastrarAnexoCarteirinha(anexosCarteirinha);
      setSolicitouCarteirinha(false);
      dispatch(setLoading(false));
      toast.success("Carteirinha enviada com sucesso");
    } catch (error: any) {
      setSolicitouCarteirinha(true);
      dispatch(setLoading(false));
      toast.error("Erro ao enviar carteirinha");
    }
  };

  const salvarAnexoGuia = async () => {
    dispatch(setLoading(true));
    var anexos: filesModel[] = [];
    imgGuia.forEach((file: UploadFile, index) => {
      anexos.push({
        nomeArquivo: file.name,
        extensao: file.type?.split("/")[1],
        arquivo: imgGuia64[index],
      });
    });
    var anexosGuia: AnexoModel = {
      idAgendamento: agendamentoState.bloquearHorario.idAgendamento,
      idAtendimento: agendamentoState.bloquearHorario.idAtendimento,
      anexos: anexos,
      userId: perfilState.perfil.idUsuario,
      clientId: loginState.empresa,
    };
    try {
      await cadastrarAnexoGuiaAgendamento(anexosGuia);
      setSolicitouGuia(false);
      dispatch(setLoading(false));
      toast.success("Requisições enviadas com sucesso");
    } catch (error: any) {
      setSolicitouGuia(true);
      dispatch(setLoading(false));
      toast.error("Erro ao enviar as requisiçõe");
    }
  };

  const avancar = () => {
    if (imgGuia.length !== 0 && imgCarteirinha.length !== 0 && (solicitouCarteirinha || solicitouGuia)) {
      setOpenModalAvancar(true);
    } else if (!solicitouCarteirinha && !solicitouGuia) {
      agendamentoState.bloquearHorario.jsonQuestionario !== null ? dispatch(setPaginaAgendamento("questionario")) : dispatch(setPaginaAgendamento("conclusao"));;
    } else {
      toast.warning("Envie os documentos para poder prosseguir com o agendamento.");
    }
  };

  const buscarHorarios = () => {
    setOpenModal(false);
    dispatch(buscarHorariosFetch({ buscarAParirDe: null, buscarApenasDia: null }));
  };

  const avancarModal = async () => {
    dispatch(setLoading(true));
    //obj guia
    var anexos: filesModel[] = [];
    imgGuia.forEach((file: UploadFile, index) => {
      anexos.push({
        nomeArquivo: file.name,
        extensao: file.type?.split("/")[1],
        arquivo: imgGuia64[index],
      });
    });
    var anexosGuia: AnexoModel = {
      idAgendamento: agendamentoState.bloquearHorario.idAgendamento,
      idAtendimento: agendamentoState.bloquearHorario.idAtendimento,
      anexos: anexos,
      userId: perfilState.perfil.idUsuario,
      clientId: loginState.empresa,
    };
    //obj carteirinha
    var anexosCarteirinha: AnexoModel = {
      idAtendimento: agendamentoState.bloquearHorario.idAtendimento,
      idAgendamento: agendamentoState.bloquearHorario.idAgendamento,
      anexos: [
        {
          nomeArquivo: imgCarteirinha[0].name,
          extensao: imgCarteirinha[0].type?.split("/")[1],
          arquivo: imgCarteirinha64,
        },
      ],
      userId: perfilState.perfil.idUsuario,
      clientId: loginState.empresa,
    };

    if (solicitouCarteirinha && !solicitouGuia) {
      try {
        await cadastrarAnexoCarteirinha(anexosCarteirinha);
        dispatch(setLoading(false));
        agendamentoState.bloquearHorario.jsonQuestionario !== null ? dispatch(setPaginaAgendamento("questionario")) : dispatch(setPaginaAgendamento("conclusao"));;
      } catch (error: any) {
        dispatch(setLoading(false));
        toast.error("Erro ao enviar a requisição");
      }
    } else if (solicitouGuia && !solicitouCarteirinha) {
      try {
        await cadastrarAnexoGuiaAgendamento(anexosGuia);
        agendamentoState.bloquearHorario.jsonQuestionario !== null ? dispatch(setPaginaAgendamento("questionario")) : dispatch(setPaginaAgendamento("conclusao"));;
        dispatch(setLoading(false));
        toast.success("Requisições enviadas com sucesso");
      } catch (error: any) {
        dispatch(setLoading(false));
        toast.error("Erro ao enviar as requisições");
      }
    } else {
      try {
        await cadastrarAnexoGuiaAgendamento(anexosGuia);
        await cadastrarAnexoCarteirinha(anexosCarteirinha);
        agendamentoState.bloquearHorario.jsonQuestionario !== null ? dispatch(setPaginaAgendamento("questionario")) : dispatch(setPaginaAgendamento("conclusao"));;
        dispatch(setLoading(false));
        toast.success("Requisições enviadas com sucesso");
      } catch (error: any) {
        dispatch(setLoading(false));
        toast.error("Erro ao enviar as requisições");
      }
    }
  };

  return (
    <>
      {configuracoesState.loadingSpinner || loadingTimer ? (
        <Spinner />
      ) : (
        <>
          <Flex vertical style={{ height: "100%" }} gap={10} flex={1}>
            <Flex justify="center" align="center" vertical>
              <Title className="subtituloAnexosObrigatorios" level={5}>
                Para concluir o agendamento, você precisa enviar as fotos da sua carteirinha e das requisições dos seus exames em até:
              </Title>
              <Text style={{ fontSize: "25px", textAlign: "center" }}>
                {timerMinutes.toString().padStart(2, "0")}:{timerSeconds.toString().padStart(2, "0")}
              </Text>
            </Flex>
            {agendamentoState.bloquearHorario.solicitarCarteirinha ? (
              <CardTitle
                title="Carteirinha plano de saúde"
                description="Clique no quadrado abaixo para selecionar ou tirar a foto de sua carteirinha, após isso clique no botão enviar"
                backgroundColor={solicitouCarteirinha ? "#909090" : loginState.configuracaoAparencia.corPrimaria}
                hasCardButton={solicitouCarteirinha}
              />
            ) : (
              <></>
            )}
            <Flex vertical justify="center" align="center" gap={10} style={{ textAlign: "center" }} className="carteirinha">
              {solicitouCarteirinha ? (
                <>
                  <Flex justify="center" align="center" style={{ width: "100%" }}>
                    <Upload
                      action={"dummyRequest"}
                      onChange={handleChangeCarteirinha}
                      beforeUpload={() => {
                        return false;
                      }}
                      listType="picture-card"
                      fileList={imgCarteirinha}
                      onPreview={handlePreviewCarteirinha}
                    >
                      {imgCarteirinha.length >= 1 ? null : uploadButtonCarteirinha}
                    </Upload>
                  </Flex>
                  <Button
                    disabled={imgCarteirinha.length === 0}
                    icon={<SendOutlined rev={undefined} />}
                    onClick={salvarAnexoCarteirinha}
                    style={imgCarteirinha.length === 0 ? { backgroundColor: "#909090", color: "#ffffff", width: "100%" } : { backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }}
                  >
                    ENVIAR
                  </Button>
                  <Modal open={previewOpenCarteirinha} title={"Carteirinha"} footer={null} onCancel={handleCancelCarteirinha} destroyOnClose>
                    <img alt="" style={{ width: "100%" }} src={previewImgCarteirinha} />
                  </Modal>
                </>
              ) : (
                <></>
              )}
            </Flex>
            <CardTitle
              title="Requisição do exame"
              description="Clique no quadrado abaixo para selecionar ou tirar as fotos de sua requisições, após isso clique no botão enviar"
              backgroundColor={solicitouGuia ? "#909090" : loginState.configuracaoAparencia.corPrimaria}
              hasCardButton={solicitouGuia}
            />
            <Flex vertical justify="center" align="center" gap={10} style={{ textAlign: "center", width: "100%" }} className="guia">
              {solicitouGuia ? (
                <>
                  <Flex justify="center" align="center" style={{ width: "100%" }}>
                    <Upload
                      action={"dummyRequest"}
                      onChange={handleChangeGuia}
                      beforeUpload={() => {
                        return false;
                      }}
                      listType="picture-card"
                      fileList={imgGuia}
                      onPreview={handlePreviewGuia}
                    >
                      {uploadButtonGuia}
                    </Upload>
                  </Flex>
                  <Button
                    disabled={imgGuia.length === 0}
                    icon={<SendOutlined rev={undefined} />}
                    onClick={salvarAnexoGuia}
                    style={imgGuia.length === 0 ? { backgroundColor: "#909090", color: "#ffffff", width: "100%" } : { backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }}
                  >
                    ENVIAR
                  </Button>
                  <Modal open={previewOpenGuia} title={"Requisições"} footer={null} onCancel={handleCancelGuia} destroyOnClose>
                    <img alt="" style={{ width: "100%" }} src={previewImgGuia} />
                  </Modal>
                </>
              ) : (
                <></>
              )}
            </Flex>
            <Button
              disabled={imgGuia.length === 0 || imgCarteirinha.length === 0}
              onClick={avancar}
              className="botaoAvancarObrigatorios"
              style={imgGuia.length === 0 || imgCarteirinha.length === 0 ? { backgroundColor: "#909090" } : { backgroundColor: loginState.configuracaoAparencia.corPrimaria }}
            >
              AVANÇAR
            </Button>
          </Flex>
          <Modal width={320} centered open={openModal} footer={null} destroyOnClose closeIcon={false}>
            <Flex vertical justify="center" align="center" gap={10}>
              <Image preview={false} src={agendConf} />
              <Text style={{ fontSize: "13px", textAlign: "left" }}>O tempo da reserva expirou e o horário escolhido foi liberado. Por favor, clique para realizar uma nova busca de horários disponíveis.</Text>
              <Button style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }} onClick={buscarHorarios}>
                BUSCAR HORÁRIOS
              </Button>
            </Flex>
          </Modal>
          <Modal width={320} centered open={openModalAvancar} footer={null} destroyOnClose onCancel={() => setOpenModalAvancar(false)}>
            <Flex vertical justify="center" align="center" gap={10}>
              <Image preview={false} src={naoEncontrado} />
              <Text style={{ fontSize: "13px", textAlign: "left" }}>Há documentos que ainda não foram enviados. Deseja enviá-los para poder avançar?</Text>
              <Flex style={{ width: "100%" }} gap={10}>
                <Button style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }} onClick={avancarModal}>
                  SIM
                </Button>
                <Button style={{ backgroundColor: "#909090", color: "#ffffff", width: "100%" }} onClick={() => setOpenModalAvancar(false)}>
                  NÃO
                </Button>
              </Flex>
            </Flex>
          </Modal>
        </>
      )}
    </>
  );
};
export default AnexosObrigatorios;

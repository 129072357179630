import { Flex, Space } from "antd";
import { ReactComponent as Swipe } from "../../util/assets/swipe.svg";
import { useEffect, useState } from "react";
import "./style/index.scss";

const SwipeComponent = () => {
  return (
    <Flex vertical style={{ position: "absolute", left:"80%", margin: "auto", height: "100%", zIndex: 99, pointerEvents: "none" }}>
      <Swipe
        className="swipe-animation" // Add the animation class
      />
    </Flex>
  );
};

export default SwipeComponent;

import { createSlice } from "@reduxjs/toolkit";
import { AgendamentoSelecaoCarrinhoModel } from "../../../models/carrinho/agendamentoSelecaoCarrinhoModel";
import { ListaCarrinhoModel } from "../../../models/carrinho/listaCarrinhoModel";
import { resetAll } from "../../actions/actions";

export interface CarrinhoState {
  loading: boolean;
  carrinho: ListaCarrinhoModel;
  montagemCarrinho: AgendamentoSelecaoCarrinhoModel;
  tipoExameSelecionado: number;
}

const initialState: CarrinhoState = {
  loading: false,
  carrinho: {} as ListaCarrinhoModel,
  montagemCarrinho: {} as AgendamentoSelecaoCarrinhoModel,
  tipoExameSelecionado: 0,
};

export const carrinhoSlice = createSlice({
  name: "carrinho",
  initialState,
  reducers: {
    adicionarCarrinhoFetch: (state, action) => {},
    listarCarrinhoFetch: () => {},
    limparCarrinhoFetch: () => {},
    limparCarrinhoLocalFetch: (state, action) => {},
    removerItemCarrinhoFetch: (state, action) => {
      state.loading = true;
    },
    setCarrinho: (state, action) => {
      state.carrinho = action.payload;
      state.loading = false;
    },
    resetCarrinho: (state) => {
      state.carrinho.clientId = null;
      state.carrinho.userId = null;
      state.carrinho.local = null;
      state.carrinho.procedimentos = null;
      state.carrinho.id = null;
      state.carrinho.valorTotal = null;
    },
    setMontagemCarrinho: (state, action) => {
      state.montagemCarrinho = action.payload;
    },
    setTipoExameSelecionado: (state, action) => {
      state.tipoExameSelecionado = action.payload;
    },
    resetTipoExameSelecionado: (state) => {
      state.tipoExameSelecionado = 0;
    },
    setLoadingCarrinho: (state, action) => {
      state.loading = action.payload;
    },
    resetMontagemCarrinho: (state) => {
      state.montagemCarrinho.idExecutantePreferencia = 0;
      state.montagemCarrinho.idProcedimento = 0;
      state.montagemCarrinho.tipoPreferencia = 0;
    },
  },
  extraReducers: (builder) => {
    //Resetar estado
    builder.addCase(resetAll, () => initialState);
  },
});

export const {
  adicionarCarrinhoFetch,
  listarCarrinhoFetch,
  limparCarrinhoFetch,
  limparCarrinhoLocalFetch,
  removerItemCarrinhoFetch,
  setCarrinho,
  resetCarrinho,
  setMontagemCarrinho,
  setTipoExameSelecionado,
  setLoadingCarrinho,
  resetTipoExameSelecionado,
  resetMontagemCarrinho,
} = carrinhoSlice.actions;

export default carrinhoSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "../../actions/actions";

export interface ConfiguracoesState {
  paginaAgendamento: string;
  paginaAnterior: string;
  paginaDependente: string;
  esqueciSenha: boolean;
  hasClient: boolean;
  paginaIdentidade: string;
  emailOrCell: string;
  menu: boolean;
  loadingSpinner: boolean;
  voltar: boolean;
  vemDeMeusAgendamentos: boolean;
  idAtendimento: number;
  solicitarCarteirinha: boolean;
  solicitarGuia: boolean;
  agilizouAnexos: boolean;
  orcamento: boolean;
}

const initialState: ConfiguracoesState = {
  paginaAgendamento: "notFound",
  paginaAnterior: "",
  paginaDependente: "dependentes",
  esqueciSenha: false,
  hasClient: false,
  paginaIdentidade: "notFound",
  emailOrCell: "",
  menu: false,
  loadingSpinner: false,
  voltar: false,
  vemDeMeusAgendamentos: false,
  idAtendimento: 0,
  solicitarCarteirinha: false,
  solicitarGuia: false,
  agilizouAnexos: false,
  orcamento: false,
};

export const ConfiguracoesSlice = createSlice({
  name: "configuracoes",
  initialState,
  reducers: {
    setPaginaAgendamento: (state, action) => {
      state.paginaAgendamento = action.payload;
    },
    setPaginaAnterior: (state, action) => {
      state.paginaAnterior = action.payload;
    },
    setPaginaDependente: (state, action) => {
      state.paginaDependente = action.payload;
    },
    setEsqueciSenha: (state, action) => {
      state.esqueciSenha = action.payload;
    },
    setHasClient: (state, action) => {
      state.hasClient = action.payload;
    },
    setFecharMenu: (state, action) => {
      state.menu = action.payload;
    },
    setPaginaIdentidade: (state, action) => {
      state.paginaIdentidade = action.payload;
    },
    setEmailorCell: (state, action) => {
      state.emailOrCell = action.payload;
    },
    setLoading: (state, action) => {
      state.loadingSpinner = action.payload;
    },
    setVoltar: (state, action) => {
      state.voltar = action.payload;
    },
    setVemdeMeusAgendamentos: (state, action) => {
      state.vemDeMeusAgendamentos = action.payload;
    },
    setIdAtendimento: (state, action) => {
      state.idAtendimento = action.payload;
    },
    setSolicitarCarteirinha: (state, action) => {
      state.solicitarCarteirinha = action.payload;
    },
    setSolicitarGuia: (state, action) => {
      state.solicitarGuia = action.payload;
    },
    setAgilizouAnexos: (state, action) => {
      state.agilizouAnexos = action.payload;
    },
    resetAgilizouAnexos: (state) => {
      state.agilizouAnexos = false;
    },
    setOrcamento: (state, action) => {
      state.orcamento = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Resetar estado
    builder.addCase(resetAll, () => initialState);
  },
});

export const {
  setEmailorCell,
  setEsqueciSenha,
  setFecharMenu,
  setHasClient,
  setPaginaAgendamento,
  setPaginaAnterior,
  setPaginaDependente,
  setPaginaIdentidade,
  setLoading,
  setVoltar,
  setVemdeMeusAgendamentos,
  setIdAtendimento,
  setSolicitarCarteirinha,
  setSolicitarGuia,
  setAgilizouAnexos,
  resetAgilizouAnexos,
  setOrcamento,
} = ConfiguracoesSlice.actions;

export default ConfiguracoesSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { ConfiguracaoAparenciaClienteModel } from "../../../models/account/configuracaoAparenciaClienteModel";
import { resetPersistedStates } from "../../actions/actions";

export interface LoginState {
  loading: boolean;
  token: string | null;
  empresa: string;
  configuracaoAparencia: ConfiguracaoAparenciaClienteModel;
  turnedSwipeExames: boolean;
  turnedSwipeMedicos: boolean;
  turnedSwipeHorarios: boolean;
}

const initialState: LoginState = {
  loading: false,
  token: null,
  empresa: "",
  configuracaoAparencia: {} as ConfiguracaoAparenciaClienteModel,
  turnedSwipeExames: false,
  turnedSwipeMedicos: false,
  turnedSwipeHorarios: false,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginFetch: (state, action) => {},
    setToken: (state, action) => {
      state.token = action.payload;
    },
    configFetch: (state, action) => {},
    setConfig: (state, action) => {
      state.configuracaoAparencia = action.payload;
    },
    setEmpresa: (state, action) => {
      state.empresa = action.payload;
    },
    resetToken: (state) => {
      state.token = null;
    },
    setTurnedSwipeExames: (state, action) => {
      state.turnedSwipeExames = action.payload;
    },
    setTurnedSwipeMedicos: (state, action) => {
      state.turnedSwipeMedicos = action.payload;
    },
    setTurnedSwipeHorarios: (state, action) => {
      state.turnedSwipeHorarios = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Resetar estado
    builder.addCase(resetPersistedStates, (state) => {
      state.loading = false;
      state.token = null;
      state.empresa = "";
      state.configuracaoAparencia = {} as ConfiguracaoAparenciaClienteModel;
    });
  },
});

export const { loginFetch, setToken, configFetch, setConfig, setEmpresa, resetToken, setTurnedSwipeExames, setTurnedSwipeMedicos, setTurnedSwipeHorarios } = loginSlice.actions;

export default loginSlice.reducer;

import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { ConfiguracoesState, setLoading, setPaginaAgendamento } from "../../slices/configuracoes/configuracoesSlice";
import { bloquearHorario, buscaDiasDisponiveis, buscaHorarios, confirmarAgendamento, convenio, convenioTodos, executante, local, procedimento, processarRespostaQuestionario, tipoExame } from "../../../services/agendamentoService";
import {
  resetBuscaDiasDisponiveis,
  setBloquearHorario,
  setBuscaDiasDisponiveis,
  setBuscarHorarios,
  setConfirmarAgendamento,
  setConvenio,
  setConvenioTodos,
  setConveniosFiltrados,
  setExecutante,
  setExecutantesFiltrados,
  setHorariosFiltrados,
  setLoadingAgendamento,
  setLoadingBuscarDiasDisponiveis,
  setLoadingExecutante,
  setLocal,
  setMensagemProcedimento,
  setProcedimento,
  setProcedimentosFiltrados,
  setProcessarRespostaQuestionario,
  setSegmentedValue,
  setTipoExame,
} from "../../slices/agendamento/agendamentoSlice";
import { CarrinhoState, setMontagemCarrinho, setTipoExameSelecionado } from "../../slices/carrinho/carrinhoSlice";
import { appSelect, useAppSelector } from "../../../util/hooks";

import moment from "moment";
import "moment/locale/pt-br";
import { PerfilState } from "../../slices/perfil/perfilSlice";
import { set } from "react-hook-form";
import { LoginState } from "../../slices/account/loginSlice";

moment.locale("pt-br");

//buscar horarios
function* callBuscarHorarios(action: any): any {
  try {
    if (action.payload.buscarApenasDia === null) {
      yield put(setLoading(true));
      yield put(resetBuscaDiasDisponiveis());
    }
    const data = yield call(buscaHorarios, action.payload);
    const response = data.data.data;
    if (response.horarios.length === 0) {
      toast.warning("Não foram encontrados horários disponíveis para a lista de exames selecionada", {
        position: "bottom-center",
        isLoading: false,
        autoClose: 2000,
        closeOnClick: true,
      });
    } else {
      if (action.payload.buscarApenasDia === null) {
        yield put(setBuscarHorarios({ diaBuscado: response.diaBuscado, horarios: response.horarios }));
        yield put(setHorariosFiltrados(response.horarios));
        yield put(setBuscaDiasDisponiveis(response.diasDisponiveis));
      } else {
        yield put(setBuscarHorarios({ diaBuscado: response.diaBuscado, horarios: response.horarios }));
        yield put(setHorariosFiltrados(response.horarios));
      }
      yield put(setPaginaAgendamento("horarios"));
    }
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* buscarHorariosSaga() {
  yield takeEvery("agendamento/buscarHorariosFetch", callBuscarHorarios);
}

//busca dias disponiveis
function* callBuscaDiasDisponiveis(action: any): any {
  try {
    const data = yield call(buscaDiasDisponiveis, action.payload);
    const response = data.data.data;
    if (response.diasDisponiveis.length === 0) {
      yield put(setLoadingBuscarDiasDisponiveis(false));
      toast.warning("Não foram encontrados dias disponíveis para os exames selecionados", {
        position: "bottom-center",
        isLoading: false,
        autoClose: 2000,
        closeOnClick: true,
      });
    } else {
      yield put(setBuscaDiasDisponiveis(response));
      yield put(setSegmentedValue(moment(response.diasDisponiveis.slice(0, 1)[0].data).format("YYYY-MM-DD")));
      yield callBuscarHorarios({ payload: { buscarAParirDe: null, buscarApenasDia: moment(response.diasDisponiveis.slice(0, 1)[0].data).format("YYYY-MM-DD") } });
    }
  } catch (error: any) {
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* buscaDiasDisponiveisSaga() {
  yield takeEvery("agendamento/buscaDiasDisponiveisFetch", callBuscaDiasDisponiveis);
}

//bloquear horarios
function* callBloquearHorario(action: any): any {
  try {
    yield put(setLoading(true));
    const data = yield call(bloquearHorario, action.payload);
    const response = data.data.data;
    yield put(setBloquearHorario(response));
    if (response.obrigatorioSolicitarDocumentos) {
      yield put(setPaginaAgendamento("anexosObrigatorios"));
    } else {
      response.jsonQuestionario !== null ? yield put(setPaginaAgendamento("questionario")) : yield put(setPaginaAgendamento("conclusao"));
    }
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* bloquearHorarioSaga() {
  yield takeEvery("agendamento/bloquearHorarioFetch", callBloquearHorario);
}

//bloquear horarios
function* callProcessarRespostaQuestionario(action: any): any {
  try {
    yield put(setLoading(true));
    const data = yield call(processarRespostaQuestionario, action.payload);
    const response = data.data.data;
    yield put(setProcessarRespostaQuestionario({ mensagem: response.mensagem, impeditivo: response.impeditivo }));
    if (response.mensagem === null) {
      yield put(setPaginaAgendamento("conclusao"));
    }
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* processarRespostaQuestionarioSaga() {
  yield takeEvery("agendamento/processarRespostaQuestionarioFetch", callProcessarRespostaQuestionario);
}

//confirmar agendamento
function* callConfirmarAgendamento(): any {
  try {
    yield put(setLoading(true));
    const data = yield call(confirmarAgendamento);
    const response = data.data.data;
    yield put(setConfirmarAgendamento(response));
    yield put(setPaginaAgendamento("finalizacao"));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* confirmarAgendamentoSaga() {
  yield takeEvery("agendamento/confirmarAgendamentoFetch", callConfirmarAgendamento);
}

//listar tipo exame
function* callTipoExame(action: any): any {
  const carrinhoState: CarrinhoState = yield appSelect((state) => state.carrinho) as Generator<any, any, any>;

  try {
    yield put(setLoading(true));
    const data = yield call(tipoExame, action.payload);
    const response = data.data.data;
    if (response.length !== 0) {
      yield put(setTipoExame(response));

      yield put(setMensagemProcedimento(response[0].mensagemExameNaoEncontrado));
      yield put(setProcedimento(response[0].procedimentos));
      yield put(setProcedimentosFiltrados(response[0].procedimentos));

      yield put(setTipoExameSelecionado(response[0].idTipoExameExterno));
    }
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* tipoExameSaga() {
  yield takeEvery("agendamento/tipoExameFetch", callTipoExame);
}

//listar convenio
function* callConvenio(action: any): any {
  const carrinhoState: CarrinhoState = yield appSelect((state) => state.carrinho) as Generator<any, any, any>;
  const configuracoestate: ConfiguracoesState = yield appSelect((state) => state.configuracoes) as Generator<any, any, any>;

  try {
    yield put(setLoading(true));
    const data = yield call(convenio, action.payload);
    const response = data.data.data;
    yield put(setConvenio(response));
    yield put(setConveniosFiltrados(response));
    if (configuracoestate.orcamento) {
      var particularPadrao = response.find((convenio: any) => convenio.particularPadrao === true);
      yield put(setMontagemCarrinho({ ...carrinhoState.montagemCarrinho, idConvenio: particularPadrao.idConvenioExterno, idPlano: particularPadrao.idPlanoPadrao }));
      yield put(setPaginaAgendamento("exames"));
    }
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* convenioSaga() {
  yield takeEvery("agendamento/convenioFetch", callConvenio);
}

//listar todos os convenio
function* callConvenioTodos(): any {
  try {
    const data = yield call(convenioTodos);
    const response = data.data.data;
    yield put(setConvenioTodos(response));
  } catch (error: any) {
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* convenioTodosSaga() {
  yield takeEvery("agendamento/convenioTodosFetch", callConvenioTodos);
}

//listar local
function* callLocal(): any {
  const loginState: LoginState = yield appSelect((state) => state.login) as Generator<any, any, any>;
  const carrinhoState: CarrinhoState = yield appSelect((state) => state.carrinho) as Generator<any, any, any>;
  try {
    yield put(setLoading(true));
    if (loginState.configuracaoAparencia.idLocalPadrao !== null) {
      yield put(setMontagemCarrinho({ ...carrinhoState.montagemCarrinho, idLocal: loginState.configuracaoAparencia.idLocalPadrao }));
      yield put(setPaginaAgendamento("convenios"));
    } else {
      const data = yield call(local);
      const response = data.data.data;
      yield put(setLocal(response));
    }
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* localSaga() {
  yield takeEvery("agendamento/localFetch", callLocal);
}

//listar executante
function* callExecutante(action: any): any {
  try {
    yield put(setLoading(true));
    const data = yield call(executante, action.payload);
    const response = data.data.data;
    yield put(setExecutante(response));
    yield put(setExecutantesFiltrados(response));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* executanteSaga() {
  yield takeEvery("agendamento/executanteFetch", callExecutante);
}

//listar procedimento
function* callProcedimento(action: any): any {
  try {
    yield put(setLoadingAgendamento(true));
    const data = yield call(procedimento, action.payload);
    const response = data.data.data;
    yield put(setProcedimento(response));
    yield put(setProcedimentosFiltrados(response));
    yield put(setLoadingAgendamento(false));
  } catch (error: any) {
    yield put(setLoadingAgendamento(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* procedimentoSaga() {
  yield takeEvery("agendamento/procedimentoFetch", callProcedimento);
}

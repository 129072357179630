import { call, put, takeEvery } from "redux-saga/effects";
import { ConfiguracoesState, setLoading, setPaginaAgendamento, setPaginaDependente, setSolicitarCarteirinha, setSolicitarGuia } from "../../slices/configuracoes/configuracoesSlice";
import {
  atualizarDependente,
  cadastrarAnexoCarteirinha,
  cadastrarAnexoGuiaAgendamento,
  criarDependente,
  meusAgendamentos,
  perfil,
  preparoAgendamento,
  preparoAtendimento,
  removerDependente,
  removerProcedimento,
  updatePerfil,
} from "../../../services/perfilService";
import { PerfilState, setMeusAgendamentos, setPdfPreparo, setPerfil } from "../../slices/perfil/perfilSlice";
import { toast } from "react-toastify";
import { CarrinhoState, setMontagemCarrinho } from "../../slices/carrinho/carrinhoSlice";
import { appSelect, useAppSelector } from "../../../util/hooks";
import { LoginState } from "../../slices/account/loginSlice";

//perfil
function* callPerfil(): any {
  const carrinhoState: CarrinhoState = yield appSelect((state) => state.carrinho) as Generator<any, any, any>;

  try {
    yield put(setLoading(true));
    const data = yield call(perfil);
    const response = data.data.data;
    yield put(setPerfil(response.value));
    yield put(setMontagemCarrinho({ ...carrinhoState.montagemCarrinho, idPaciente: response.value.idPaciente }));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* perfilSaga() {
  yield takeEvery("perfil/perfilFetch", callPerfil);
}

//update perfil
function* callUpdatePerfil(action: any): any {
  try {
    yield put(setLoading(true));
    const data = yield call(updatePerfil, action.payload);
    const response = data.data.data;
    yield put(setPerfil(response.value));
    yield put(setLoading(false));
    toast.success("Seu perfil foi atualizado com sucesso", {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* updatePerfilSaga() {
  yield takeEvery("perfil/updatePerfilFetch", callUpdatePerfil);
}

//cadastrar anexo carteirinha
function* callCadastrarAnexoCarteirinha(action: any): any {
  try {
    yield put(setLoading(true));
    const data = yield call(cadastrarAnexoCarteirinha, action.payload);
    const response = data;
    yield put(setSolicitarCarteirinha(false));
    yield put(setLoading(false));
    toast.success("Carteirinha enviada com sucesso", {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  } catch (error: any) {
    yield put(setSolicitarCarteirinha(true));
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* cadastrarAnexoCarteirinhaSaga() {
  yield takeEvery("perfil/cadastrarAnexoCarteirinhaFetch", callCadastrarAnexoCarteirinha);
}

//cadastrar anexo guia
function* callCadastrarAnexoGuia(action: any): any {
  try {
    yield put(setLoading(true));
    const data = yield call(cadastrarAnexoGuiaAgendamento, action.payload);
    const response = data;
    yield put(setSolicitarGuia(false));
    yield put(setLoading(false));
    toast.success("Requisições enviadas com sucesso", {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  } catch (error: any) {
    yield put(setSolicitarGuia(true));
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* cadastrarAnexoGuiaSaga() {
  yield takeEvery("perfil/cadastrarAnexoGuiaFetch", callCadastrarAnexoGuia);
}

//meus agendamentos
function* callMeusAgendamentos(): any {
  try {
    yield put(setLoading(true));
    const data = yield call(meusAgendamentos);
    const response = data.data.data;
    yield put(setMeusAgendamentos(response.value));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* meusAgendamentosSaga() {
  yield takeEvery("perfil/meusAgendamentosFetch", callMeusAgendamentos);
}

//remover procedimento
function* callRemoverProcedimento(action: any): any {
  try {
    yield put(setLoading(true));
    yield call(removerProcedimento, action.payload);
    const data = yield call(meusAgendamentos);
    const response = data.data.data;
    yield put(setMeusAgendamentos(response.value));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* removerProcedimentoSaga() {
  yield takeEvery("perfil/removerProcedimentoFetch", callRemoverProcedimento);
}

//preparo atendimento
function* callPreparoAtendimento(action: any): any {
  try {
    yield put(setLoading(true));
    const data = yield call(preparoAtendimento, action.payload);
    const response = data.data.data;
    yield put(setPdfPreparo(response.value));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* preparoAtendimentoSaga() {
  yield takeEvery("perfil/preparoAtendimentoFetch", callPreparoAtendimento);
}

//preparo agendamento
function* callPreparoAgendamento(action: any): any {
  try {
    yield put(setLoading(true));
    const data = yield call(preparoAgendamento, action.payload);
    const response = data.data.data;
    yield put(setPdfPreparo(response.value));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* preparoAgendamentoSaga() {
  yield takeEvery("perfil/preparoAgendamentoFetch", callPreparoAgendamento);
}

//criar dependente
function* callCriarDependente(action: any): any {
  const configuracoesState: ConfiguracoesState = yield appSelect((state) => state.configuracoes) as Generator<any, any, any>;
  const loginState: LoginState = yield appSelect((state) => state.login) as Generator<any, any, any>;

  try {
    yield put(setLoading(true));

    yield call(criarDependente, action.payload.value);

    const data = yield call(perfil);
    const response = data.data.data;
    yield put(setPerfil(response.value));

    yield put(setLoading(false));

    toast.success("O dependente foi criado com sucesso", {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });

    if (configuracoesState.paginaAnterior.includes("vindoDeDependentes")) {
      action.payload.navigate("/dependentes/" + loginState.empresa, { replace: true });
    } else if (configuracoesState.paginaAnterior.includes("vindoDePaciente")) {
      yield put(setPaginaAgendamento("paciente"));
      action.payload.navigate("/agendamento/" + loginState.empresa, { replace: true });
    }
  } catch (error: any) {
    yield put(setLoading(false));

    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* criarDependenteSaga() {
  yield takeEvery("perfil/criarDependenteFetch", callCriarDependente);
}

//atualiar dependente
function* callAtualziarDependente(action: any): any {
  const configuracoesState: ConfiguracoesState = yield appSelect((state) => state.configuracoes) as Generator<any, any, any>;
  const loginState: LoginState = yield appSelect((state) => state.login) as Generator<any, any, any>;

  try {
    yield put(setLoading(true));

    yield call(atualizarDependente, action.payload.value);

    const data = yield call(perfil);
    const response = data.data.data;
    yield put(setPerfil(response.value));

    yield put(setLoading(false));

    toast.success("O dependente foi atualizado com sucesso", {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });

    if (configuracoesState.paginaAnterior.includes("vindoDeDependentes")) {
      yield put(setPaginaDependente("dependentes"));
      action.payload.navigate("/dependentes/" + loginState.empresa, { replace: true });
    } else if (configuracoesState.paginaAnterior.includes("vindoDePaciente")) {
      yield put(setPaginaAgendamento("paciente"));
      action.payload.navigate("/agendamento/" + loginState.empresa, { replace: true });
    }
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* atualizarDependenteSaga() {
  yield takeEvery("perfil/atualizarDependenteFetch", callAtualziarDependente);
}

//remover dependente
function* callRemoverDependente(action: any): any {
  try {
    yield put(setLoading(true));

    yield call(removerDependente, action.payload);

    const data = yield call(perfil);
    const response = data.data.data;
    yield put(setPerfil(response.value));

    yield put(setLoading(false));

    toast.success("O dependente foi removido com sucesso", {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* removerDependenteSaga() {
  yield takeEvery("perfil/removerDependenteFetch", callRemoverDependente);
}

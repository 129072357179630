import "./style/index.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../util/hooks";
import BotaoPrimario from "../../components/botaoPrimario";
import { ConfiguracoesState, setEsqueciSenha, setLoading } from "../../redux/slices/configuracoes/configuracoesSlice";
import { LoginModel } from "../../models/account/loginModel";
import { LoginState, configFetch, loginFetch, setEmpresa } from "../../redux/slices/account/loginSlice";
import Spinner from "../../components/spinner/spinner";
import { Button, Col, Flex, Form, Input, Row, Space, Typography, Image } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import uniClinikaOnline from "../../util/assets/uniclinika_online.svg";
import { AccountState } from "../../redux/slices/account/accountSlice";
import { PerfilState } from "../../redux/slices/perfil/perfilSlice";

const { Link } = Typography;

interface FormAcesso {
  login: string;
  password: string;
}

const Acessar = () => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const perfilState: PerfilState = useAppSelector((state) => state.perfil);

  //Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  //Estados Locais
  const [form] = Form.useForm<FormAcesso>();

  const urlList = location.pathname.split("/");

  useEffect(() => {
    if (Object.values(perfilState.perfil).length !== 0) {
      navigate("/painel/" + loginState.empresa, { replace: true });
    }
  }, [perfilState.perfil]);

  const submit = async (value: FormAcesso) => {
    const args: LoginModel = {
      login: value.login.includes("@") ? value.login : value.login.replace(/[^0-9]/g, ""),
      password: value.password,
      clientId: loginState.empresa,
      grantType: "password",
      refreshToken: "",
    };
    dispatch(loginFetch(args));
  };

  const goIdentidade = (value: boolean) => {
    dispatch(setEsqueciSenha(value));
    navigate("/confirmarUsuario/" + loginState.empresa, { replace: true });
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <Flex vertical style={{ height: "100%" }} gap={10}>
          <Flex vertical justify="center">
            <Flex justify="center" style={{ marginBottom: "24px", marginTop: "10px" }}>
              <Image width={180} preview={false} src={uniClinikaOnline} />
            </Flex>
            <Form form={form} layout="vertical" onFinish={submit}>
              <Row gutter={16}>
                <Col span={24}>
                  <Flex vertical className="box">
                    <Row gutter={[24, 24]}>
                      <Col span={24}>
                        <Form.Item
                          label="E-mail ou Telefone"
                          name="login"
                          rules={[
                            {
                              required: true,
                              message: "Digite o seu e-mail",
                              validateTrigger: "onSubmit",
                            },
                          ]}
                        >
                          <Input type="text" placeholder="Seu email ou DDD+telefone" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                          label="Senha"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Digite a sua senha",
                              validateTrigger: "onSubmit",
                            },
                          ]}
                        >
                          <Input.Password placeholder="Digite a senha" iconRender={(visible) => (visible ? <EyeTwoTone rev={undefined} /> : <EyeInvisibleOutlined rev={undefined} />)} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={24}>
                        <BotaoPrimario title="ACESSAR" seta={false} />
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={24} className="esqueciSenha">
                        <Link
                          underline
                          onClick={() => {
                            goIdentidade(true);
                          }}
                        >
                          Esqueci minha senha
                        </Link>
                      </Col>
                    </Row>
                  </Flex>
                </Col>
              </Row>
            </Form>
            <Row gutter={16}>
              <Col span={24}>
                <Button
                  className="primeiroAcesso"
                  disabled={loginState.loading}
                  onClick={() => {
                    goIdentidade(false);
                  }}
                >
                  PRIMEIRO ACESSO
                </Button>
              </Col>
            </Row>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default Acessar;

import { LoginState } from "../../redux/slices/account/loginSlice";
import { useAppSelector } from "../../util/hooks";

const Flecha = () => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 30.263 64.246">
      <path
        id="Subtração_13"
        data-name="Subtração 13"
        d="M-3980,2927.913v-63.826l.42-.42,28.377,28.377a4.965,4.965,0,0,1,1.465,3.536,4.965,4.965,0,0,1-1.465,3.535l-28.8,28.8Z"
        transform="translate(3980 -2863.667)"
        fill={loginState.configuracaoAparencia.corPrimaria}
      />
    </svg>
  );
};

export default Flecha;

import api from "../util/api";
import { PacienteModel } from "../models/perfil/pacienteModel";
import { AnexoModel } from "../models/perfil/anexoModel";
import axios from "axios";
import environment from "../environment";

export const updatePerfil = async (args: PacienteModel) => {
  return await api.post("/Perfil/updatePerfil", args);
};

export const cadastrarAnexoCarteirinha = async (args: AnexoModel) => {
  return await api.post("/Perfil/CadastrarAnexoCarteirinha", args);
};

export const cadastrarAnexoGuiaAgendamento = async (args: AnexoModel) => {
  return await api.post("/Perfil/CadastrarAnexoGuiaAgendamento", args);
};

export const criarDependente = async (args: PacienteModel) => {
  return await api.post("/Perfil/criarDependente", args);
};

export const atualizarDependente = async (args: PacienteModel) => {
  return await api.post("/Perfil/atualizarDependente", args);
};

export const removerDependente = async (idPaciente: number) => {
  return await api.post("/Perfil/removerDependente/" + idPaciente);
};

export const perfil = async () => {
  return await api.get("/Perfil/Perfil");
};

export const perfilLogin = async (token: string) => {
  const instance = axios.create({});
  const authorazation = "Bearer " + token;
  instance.interceptors.request.use(async (config: any) => {
    config.baseURL = environment.baseURL;
    config.headers = {
      "Content-Type": "application/json",
      Authorization: authorazation,
    };
    return config;
  });
  return await instance.get("/Perfil/Perfil");
};

export const meusAgendamentos = async () => {
  return await api.get("/Perfil/MeusAgendamentos");
};

export const preparoAtendimento = async (idAtendimento: number) => {
  return await api.get("/Perfil/preparoAtendimento", { params: { idAtendimento: idAtendimento } });
};

export const preparoAgendamento = async (idAgendamento: number) => {
  return await api.get("/Perfil/preparoAgendamento", { params: { idAgendamento: idAgendamento } });
};

export const removerProcedimento = async (arg: number[]) => {
  return await api.delete("/Perfil/agendas", { data: arg });
};

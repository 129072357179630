import "./style/index.scss";
import { useEffect, useState } from "react";
import { AgendamentoState, resetConverio, resetLocal } from "../../../redux/slices/agendamento/agendamentoSlice";
import { ConfiguracoesState, setPaginaAgendamento, setPaginaAnterior, setVoltar } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import Spinner from "../../../components/spinner/spinner";
import { Button, Flex, Form, Space, Tooltip, Typography } from "antd";
import Flecha from "../../../components/flecha";
import { PerfilState, preparoAgendamentoFetch } from "../../../redux/slices/perfil/perfilSlice";
import { useNavigate } from "react-router-dom";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import { DoubleLeftOutlined, DoubleRightOutlined, DownloadOutlined, PrinterOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Document, Page, pdfjs } from "react-pdf";
import { useWindowWidth } from "@wojtekmaj/react-hooks";
import print from "print-js";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const { Title, Text } = Typography;

const Preparacao = () => {
  //Estados Globais
  const agendamentoState: AgendamentoState = useAppSelector((state) => state.agendamento);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const perfilState: PerfilState = useAppSelector((state) => state.perfil);
  const loginState: LoginState = useAppSelector((state) => state.login);

  //Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const width = useWindowWidth();

  //Estados Locais
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [ehMobile, setEhMobile] = useState(false);
  const [ehIos, setEhIos] = useState(false);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  useEffect(() => {
    if (!configuracoesState.vemDeMeusAgendamentos) {
      dispatch(setPaginaAnterior("finalizacao"));
    }
    dispatch(setVoltar(true));
    const isMobileDevice = /Mobi/.test(window.navigator.userAgent);
    if (isMobileDevice) {
      setEhMobile(true);
    } else {
      setEhMobile(false);
    }
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("android") > -1) {
      setEhIos(false);
    } else {
      setEhIos(true);
    }
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const sharePDF = async (pdf: string) => {
    try {
      const response = await fetch(`data:application/pdf;base64,${pdf}`);
      const blob = await response.blob();
      const file = new File([blob], "Orientações.pdf", {
        type: "application/pdf",
      });
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const shareUrl = reader.result as string;
      if (navigator.share) {
        navigator.share({
          files: [file],
        });
      } else if (navigator.clipboard) {
        navigator.clipboard
          .writeText(shareUrl)
          .then(() => {
            console.log("URL do arquivo copiada para o clipboard");
          })
          .catch((error) => {
            console.error("Erro ao copiar arquivo para o clibboard", error);
          });
      } else {
        console.log("Não é possível compartilhar nesse browser");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadPDF = async (pdf: string) => {
    const pdfLink = "data:application/pdf;base64," + pdf;
    const element = document.createElement("a");
    const fileName = "Orientações.pdf";
    element.href = pdfLink;
    element.download = fileName;
    element.click();
  };

  const printPDF = (pdf: string) => {
    const byteCharacters = atob(pdf);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const pdfBlob = new Blob([byteArray], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    print(pdfUrl);
  };

  const voltarInicio = () => {
    dispatch(resetLocal());
    dispatch(resetConverio());
    navigate("/painel/" + loginState.empresa, { replace: true });
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <Flex vertical style={{ height: "100%" }} gap={10} className="pdfLayout">
          <Flex justify="flex-start" align="center" gap={10}>
            <Flecha />
            <Title className="titulo" level={4}>
              Preparação do exame
            </Title>
          </Flex>
          <Flex justify="flex-end" gap={8}>
            {ehMobile ? (
              <>
                <Tooltip title="Compartilhar">
                  <Button
                    icon={<ShareAltOutlined rev={undefined} />}
                    onClick={() => sharePDF(agendamentoState.confirmarAgendamento.pdfPreparo!)}
                    style={{
                      backgroundColor: loginState.configuracaoAparencia.corPrimaria,
                      borderRadius: "5px",
                      color: "#ffffff",
                    }}
                  />
                </Tooltip>
                {ehIos ? (
                  <>
                    <Tooltip title="Imprimir">
                      <Button
                        icon={<PrinterOutlined rev={undefined} />}
                        onClick={() => printPDF(agendamentoState.confirmarAgendamento.pdfPreparo!)}
                        style={{
                          backgroundColor: "#404040",
                          borderRadius: "5px",
                          marginRight: "9px",
                          color: "#ffffff",
                        }}
                      />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip title="Baixar">
                      <Button
                        icon={<DownloadOutlined rev={undefined} />}
                        onClick={() => downloadPDF(agendamentoState.confirmarAgendamento.pdfPreparo!)}
                        style={{
                          backgroundColor: "#909090",
                          borderRadius: "5px",
                          marginRight: "9px",
                          color: "#ffffff",
                        }}
                      />
                    </Tooltip>
                  </>
                )}
              </>
            ) : (
              <>
                <Tooltip title="Compartilhar">
                  <Button
                    icon={<ShareAltOutlined rev={undefined} />}
                    onClick={() => sharePDF(agendamentoState.confirmarAgendamento.pdfPreparo!)}
                    style={{
                      backgroundColor: loginState.configuracaoAparencia.corPrimaria,
                      borderRadius: "5px",
                      color: "#ffffff",
                    }}
                  />
                </Tooltip>
                <Tooltip title="Baixar">
                  <Button
                    icon={<DownloadOutlined rev={undefined} />}
                    onClick={() => downloadPDF(agendamentoState.confirmarAgendamento.pdfPreparo!)}
                    style={{
                      backgroundColor: "#909090",
                      borderRadius: "5px",
                      color: "#ffffff",
                    }}
                  />
                </Tooltip>
                <Tooltip title="Imprimir">
                  <Button
                    icon={<PrinterOutlined rev={undefined} />}
                    onClick={() => printPDF(agendamentoState.confirmarAgendamento.pdfPreparo!)}
                    style={{
                      backgroundColor: "#404040",
                      borderRadius: "5px",
                      marginRight: "9px",
                      color: "#ffffff",
                    }}
                  />
                </Tooltip>
              </>
            )}
          </Flex>
          <Flex vertical flex={"1 1 auto"} gap={16} justify="center" align="center">
            <Document file={"data:application/pdf;base64," + agendamentoState.confirmarAgendamento.pdfPreparo} options={{ workerSrc: "/pdf.worker.js" }} onLoadSuccess={onDocumentLoadSuccess} loading={<Spinner />} className="pdfDocument">
              <Page width={width ? Math.min(width * 0.97, 450) : 450} pageNumber={pageNumber} />
            </Document>
            <Text>
              Página {pageNumber || (numPages ? 1 : "--")} de {numPages || "--"}
            </Text>
            <Flex justify="center" align="center" gap={5} className="pageButtons">
              <Tooltip title="Anterior">
                <Button icon={<DoubleLeftOutlined rev={undefined} style={{ color: "#404040" }} />} disabled={pageNumber <= 1} onClick={previousPage} />
              </Tooltip>
              <Tooltip title="Próximo">
                <Button icon={<DoubleRightOutlined rev={undefined} />} disabled={pageNumber >= numPages} onClick={nextPage} />
              </Tooltip>
            </Flex>
          </Flex>
          <Flex>
            <Button onClick={voltarInicio} className="botaoConcluirP" style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria }}>
              VOLTAR PARA O INÍCIO
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default Preparacao;

import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storageSession from "redux-persist/lib/storage/session";

import loginReducer from "./slices/account/loginSlice";
import accountReducer from "./slices/account/accountSlice";
import configuracoesReducer from "./slices/configuracoes/configuracoesSlice";
import agendamentoReducer from "./slices/agendamento/agendamentoSlice";
import perfilReducer from "./slices/perfil/perfilSlice";
import carrinhoReducer from "./slices/carrinho/carrinhoSlice";

const loginConfig = {
  key: "login",
  storage: storageSession,
  blacklist: ["loading", "success"],
};

const loginPersistedReducer = persistReducer(loginConfig, loginReducer);

const rootReducer: any = combineReducers({
  login: loginPersistedReducer,
  account: accountReducer,
  configuracoes: configuracoesReducer,
  agendamento: agendamentoReducer,
  perfil: perfilReducer,
  carrinho: carrinhoReducer,
});

export default rootReducer;

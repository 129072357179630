import api from "../util/api";
import { BuscarHorariosModel } from "../models/agendamento/buscarHorariosModel";
import { ListarExecutanteModel } from "../models/agendamento/listarExecutanteModel";
import { ListarProcedimentoModel } from "../models/agendamento/listarProcedimentoModel";
import { BuscarTipoExameModel } from "../models/agendamento/buscarTipoExameModel";
import { AgendamentoSugestaoModel } from "../models/agendamento/agendamentoSugestaoModel";

export const buscaHorarios = async (args: BuscarHorariosModel) => {
  return await api.post("/Agendamento/buscaHorarios", null, { params: args });
};

export const buscaDiasDisponiveis = async (data: Date) => {
  return await api.post("/Agendamento/buscaDiasDisponiveis/?BuscarAPartirDe=" + data);
};

export const bloquearHorario = async (args: AgendamentoSugestaoModel) => {
  return await api.post("/Agendamento/bloquearHorario", args);
};

export const processarRespostaQuestionario = async (args: { idAgendamento: number; jsonResposa: string }) => {
  return await api.post("/Agendamento/processarRespostaQuestionario", args);
};

export const confirmarAgendamento = async () => {
  return await api.post("/Agendamento/confirmarAgendamento");
};

export const tipoExame = async (args: BuscarTipoExameModel) => {
  return await api.get("/Agendamento/tipoExame", {
    params: args,
  });
};

export const convenio = async (idLocal: number) => {
  return await api.get("/Agendamento/convenio", {
    params: { idLocal: idLocal },
  });
};

export const convenioTodos = async () => {
  return await api.get("/Agendamento/convenioTodos");
};

export const local = async () => {
  return await api.get("/Agendamento/local");
};

export const executante = async (args: ListarExecutanteModel) => {
  return await api.get("/Agendamento/executante", {
    params: args,
  });
};

export const procedimento = async (args: ListarProcedimentoModel) => {
  return await api.get("/Agendamento/procedimento", {
    params: args,
  });
};

export const tempoBloqueio = async (idAgendamento: number) => {
  return await api.get("/Agendamento/TempoBloqueio", {
    params: { idAgendamento: idAgendamento },
  });
};

import axios from "axios";
import environment from "../environment";
import { store } from "../redux/store";

const api = axios.create({});
export default api;

api.interceptors.request.use(async (config: any) => {
  config.baseURL = environment.baseURL;
  config.headers = {
    "Content-Type": "application/json",
    Authorization: getAuthorization(),
  };
  return config;
});

const getAuthorization = (): string => {
  const token: string = store.getState().login.token;

  if (token) {
    return "Bearer " + token;
  } else {
    return "";
  }
};

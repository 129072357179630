import "./style/index.scss";
import Spinner from "../../../components/spinner/spinner";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import { ConfiguracoesState, setPaginaAgendamento, setPaginaAnterior, setVoltar } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { Survey } from "survey-react-ui";
import { Model, surveyLocalization } from "survey-core";
import { useCallback, useEffect, useState } from "react";
import { DefaultLightPanelless } from "survey-core/themes";
import { AgendamentoState, buscarHorariosFetch, processarRespostaQuestionarioFetch, resetConverio, resetLocal, resetProcessarRespostaQuestionario } from "../../../redux/slices/agendamento/agendamentoSlice";
import { Button, Flex, Modal, Image, Typography } from "antd";
import imgNaoEncontrado from "../../../util/assets/nao_encontrado.png";
import Parser from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { tempoBloqueio } from "../../../services/agendamentoService";
import agendConf from "../../../util/assets/agendConf.png";

const { Title, Text } = Typography;

const Questionario = () => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const agendamentoState: AgendamentoState = useAppSelector((state) => state.agendamento);

  //Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //Estados Locais
  const jsonQuesObj = JSON.parse(agendamentoState.bloquearHorario.jsonQuestionario!);
  jsonQuesObj.textUpdateMode = "onTyping";

  const [survey, setSurvey] = useState<Model>(new Model(jsonQuesObj));
  const [tempo, setTempo] = useState<number>(0);
  const [timerMinutes, setTimerMinutes] = useState<number>(0);
  const [timerSeconds, setTimerSeconds] = useState<number>(0);
  const [loadingTimer, setLoadingTimer] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);

  let interval: any;

  const startTimer = (tempo: number) => {
    const countDownDate = new Date().getTime() + tempo * 1000;

    interval = setInterval(() => {
      const now = new Date().getTime();

      const distance = countDownDate - now;

      const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
      const seconds = Math.floor((distance % (60 * 1000)) / 1000);

      if (distance < 0) {
        clearInterval(interval);
        setTimerMinutes(0);
        setTimerSeconds(0);
        setLoadingTimer(false);
        setOpenModal(true);
      } else {
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
      if (distance > 0) {
        setLoadingTimer(false);
      }
    }, 1000);
  };

  const fetchTimer = async () => {
    const retorno = await tempoBloqueio(agendamentoState.bloquearHorario.idAgendamento);
    startTimer(retorno.data.data);
  };

  useEffect(() => {
    dispatch(setVoltar(true));
    if (agendamentoState.bloquearHorario.obrigatorioSolicitarDocumentos) {
      dispatch(setPaginaAnterior("anexosObrigatorios"));
    } else {
      dispatch(setPaginaAnterior("horarios"));
    }
    setSurvey(new Model(jsonQuesObj));
    fetchTimer();
    let intervalId = setInterval(() => {
      setTempo((prevTempo) => {
        if (prevTempo >= 500) {
          clearInterval(intervalId);
          return prevTempo;
        }
        return prevTempo + 1;
      });
    }, 1);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  survey.applyTheme({
    ...DefaultLightPanelless,
    colorPalette: loginState.configuracaoAparencia.corPrimaria,
    cssVariables: {
      "--sjs-article-font-xx-large-fontStyle": '"Montserrat", sans-serif',
      "--sjs-article-font-x-large-fontStyle": '"Montserrat", sans-serif',
      "--sjs-article-font-large-fontStyle": '"Montserrat", sans-serif',
      "--sjs-article-font-medium-fontStyle": '"Montserrat", sans-serif',
      "--sjs-article-font-default-fontStyle": '"Montserrat", sans-serif',
      "--primary": loginState.configuracaoAparencia.corPrimaria,
      "--sjs-primary-backcolor-dark": loginState.configuracaoAparencia.corPrimaria,
    },
  });
  survey.completeText = "ENVIAR";
  surveyLocalization.locales[surveyLocalization.defaultLocale].requiredError = "Este é um campo obrigatório";

  const surveyComplete = useCallback((survey: any) => {
    survey.showCompletedPage = false;
    dispatch(processarRespostaQuestionarioFetch({ idAgendamento: agendamentoState.bloquearHorario.idAgendamento, jsonResposta: JSON.stringify(survey.data) }));
  }, []);

  survey.onComplete.add(surveyComplete);

  const estouCiente = () => {
    if (!agendamentoState.respostaQuestionario.impeditivo) {
      dispatch(setPaginaAgendamento("conclusao"));
    } else {
      dispatch(resetLocal());
      dispatch(resetConverio());
      dispatch(resetProcessarRespostaQuestionario());
      navigate("/painel/" + loginState.empresa, { replace: true });
    }
  };

  const buscarHorarios = () => {
    setOpenModal(false);
    dispatch(buscarHorariosFetch({ buscarAParirDe: null, buscarApenasDia: null }));
  };

  return (
    <>
      {configuracoesState.loadingSpinner || loadingTimer ? (
        <Spinner />
      ) : (
        <Flex vertical style={{ height: "100%" }} gap={10} flex={1}>
          <Flex justify="center" align="center" vertical>
            <Title className="subtituloAnexosObrigatorios" level={5}>
              Você deve concluir seu agendamento em:
            </Title>
            <Text style={{ fontSize: "25px", textAlign: "center" }}>
              {timerMinutes.toString().padStart(2, "0")}:{timerSeconds.toString().padStart(2, "0")}
            </Text>
          </Flex>
          <Survey model={survey} />
          <Modal width={320} centered open={agendamentoState.respostaQuestionario.mensagem !== null} footer={null} destroyOnClose closeIcon={false}>
            <Flex vertical justify="center" align="center" gap={10}>
              <Image preview={false} src={imgNaoEncontrado} />
              {Parser(agendamentoState.respostaQuestionario.mensagem ? agendamentoState.respostaQuestionario.mensagem : "")}
              <Button style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }} onClick={estouCiente}>
                OK
              </Button>
            </Flex>
          </Modal>
          <Modal width={320} centered open={openModal} footer={null} destroyOnClose closeIcon={false}>
            <Flex vertical justify="center" align="center" gap={10}>
              <Image preview={false} src={agendConf} />
              <Text style={{ fontSize: "13px", textAlign: "left" }}>O tempo da reserva expirou e o horário escolhido foi liberado. Por favor, clique para realizar uma nova busca de horários disponíveis.</Text>
              <Button style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }} onClick={buscarHorarios}>
                BUSCAR HORÁRIOS
              </Button>
            </Flex>
          </Modal>
        </Flex>
      )}
    </>
  );
};

export default Questionario;

import "./style/index.scss";
import { useEffect } from "react";
import { ConfiguracoesState, setLoading, setPaginaAgendamento, setPaginaAnterior, setPaginaDependente, setVoltar } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import { Button, Card, Col, Flex, Form, Input, Radio, RadioChangeEvent, Row, Select, Space, Typography } from "antd";
import StepsComponent from "../../../components/steps";
import Flecha from "../../../components/flecha";
import { toast } from "react-toastify";
import { CarrinhoState, setMontagemCarrinho } from "../../../redux/slices/carrinho/carrinhoSlice";
import { PerfilState, perfilFetch, setAtualizarDependente } from "../../../redux/slices/perfil/perfilSlice";
import Spinner from "../../../components/spinner/spinner";
import { EditOutlined, PlusCircleOutlined, RightOutlined } from "@ant-design/icons";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import { useNavigate } from "react-router-dom";
import { PacienteModel } from "../../../models/perfil/pacienteModel";
import { AgendamentoSelecaoCarrinhoModel } from "../../../models/carrinho/agendamentoSelecaoCarrinhoModel";
import { local } from "../../../services/agendamentoService";
import { setLocal } from "../../../redux/slices/agendamento/agendamentoSlice";
import { Users } from "@phosphor-icons/react";

const { Title, Text, Link } = Typography;

const Paciente = () => {
  //Estados Globais
  const carrinhoState: CarrinhoState = useAppSelector((state) => state.carrinho);
  const perfilState: PerfilState = useAppSelector((state) => state.perfil);
  const loginState: LoginState = useAppSelector((state) => state.login);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);

  //Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //Estados Locais

  const fetchLocal = async () => {
    if (loginState.configuracaoAparencia.idLocalPadrao !== null) {
      try {
        const data = await local();
        const response = data.data.data;
        dispatch(setLocal(response));
      } catch (e) {
        toast.error("Ocorreu um erro ao selecionar o paciente");
      }
    }
  };

  useEffect(() => {
    dispatch(setVoltar(true));
    dispatch(setPaginaAnterior("painel"));
    fetchLocal();
    if (Object.values(perfilState.perfil).length === 0) {
      dispatch(perfilFetch());
    }
  }, [perfilState.perfil]);

  const onChangeDependente = (values: PacienteModel) => {
    var montagemCarrinho: AgendamentoSelecaoCarrinhoModel = { ...carrinhoState.montagemCarrinho };
    montagemCarrinho.idPaciente = values.idPaciente !== null ? values.idPaciente : 0;
    configuracoesState.orcamento ? (montagemCarrinho.orcamento = true) : (montagemCarrinho.orcamento = false);
    dispatch(setMontagemCarrinho(montagemCarrinho));

    dispatch(setPaginaAgendamento("unidade"));
  };
  const onChangePaciente = async () => {
    dispatch(setLoading(true));
    var montagemCarrinho: AgendamentoSelecaoCarrinhoModel = { ...carrinhoState.montagemCarrinho };
    montagemCarrinho.idPaciente = perfilState.perfil.idPaciente !== null ? perfilState.perfil.idPaciente : 0;
    configuracoesState.orcamento ? (montagemCarrinho.orcamento = true) : (montagemCarrinho.orcamento = false);
    dispatch(setMontagemCarrinho(montagemCarrinho));

    dispatch(setPaginaAgendamento("unidade"));
  };

  const cadastrarDependente = () => {
    dispatch(setAtualizarDependente({}));
    dispatch(setPaginaAnterior("vindoDePaciente"));
    dispatch(setPaginaDependente("novoDependente"));
    navigate("/dependentes/" + loginState.empresa, { replace: true });
  };

  const irParaMeusDependentes = () => {
    dispatch(setPaginaAnterior("vindoDePaciente"));
    dispatch(setPaginaDependente("dependentes"));
    navigate("/dependentes/" + loginState.empresa, { replace: true });
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <>
          <Flex vertical style={{ height: "100%" }} gap={10}>
            <Flex justify="flex-start" align="center" gap={10}>
              <Flecha />
              <Flex style={{ width: "100%" }} justify="space-between" align="center">
                <Title className="tituloPacientes" level={4}>
                  Selecione um Paciente
                </Title>
              </Flex>
            </Flex>
            <Flex justify="flex-end" align="center" onClick={irParaMeusDependentes} style={{ cursor: "pointer", width: "100%" }}>
              <Text style={{ fontWeight: "bold", fontSize: "12px", textDecoration: "underline" }}> Meus dependentes</Text>
              <Users style={{ marginLeft: "5px", fontWeight: "bold" }} />
            </Flex>
            <Flex vertical gap={10}>
              <Card
                key={"paciente"}
                className="cardPacientes"
                onClick={(e) => {
                  e.stopPropagation();
                  onChangePaciente();
                }}
              >
                <Flex style={{ width: "100%" }} gap={16} justify="space-between" align="center">
                  <Flex vertical>
                    <Text style={{ wordBreak: "break-all", fontSize: "14px" }}>{perfilState.perfil.nomePaciente}</Text>
                    <Flex gap={8}>
                      <Text style={{ wordBreak: "break-all", fontSize: "12px", color: "#606060" }}>{`Você, ${perfilState.perfil.idade} anos, ${perfilState.perfil.peso}kg`}</Text>
                    </Flex>
                  </Flex>
                  <Flex align="center" justify="center">
                    <RightOutlined rev={undefined} style={{ color: "#909090", fontSize: "12px" }} />
                  </Flex>
                </Flex>
              </Card>
              {Object.values(perfilState.perfil).length !== 0 &&
                perfilState.perfil.dependentes.length !== 0 &&
                perfilState.perfil.dependentes.map((a: PacienteModel, index) => (
                  <Card key={index} className="cardPacientes" onClick={() => onChangeDependente(a)}>
                    <Flex style={{ width: "100%" }} gap={16} justify="space-between" align="center">
                      <Flex vertical>
                        <Text style={{ wordBreak: "break-all", fontSize: "14px" }}>{a.nomePaciente}</Text>
                        <Text style={{ wordBreak: "break-all", fontSize: "12px", color: "#606060" }}>{`${a.parentesco}, ${a.idade} anos, ${a.peso}kg`}</Text>
                      </Flex>
                      <Flex align="center" justify="center">
                        <RightOutlined rev={undefined} style={{ color: "#909090", fontSize: "12px" }} />
                      </Flex>
                    </Flex>
                  </Card>
                ))}
              <Card key={"cadastro"} className="cardDependentes " onClick={cadastrarDependente}>
                <Flex style={{ width: "100%" }} gap={16} justify="center" align="center">
                  <Text style={{ fontSize: "14px" }}>Adicionar dependente</Text>
                  <PlusCircleOutlined style={{ fontSize: "35px", color: "#b3b3b3" }} rev={undefined} />
                </Flex>
              </Card>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

export default Paciente;

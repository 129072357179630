const environment = {
    "baseURL": "http://localhost:55071/api/"
};

if (process.env.REACT_APP_ENV === "development") {
    environment.baseURL = "https://servicosonlineapidev.azurewebsites.net/api/"
}

if (process.env.REACT_APP_ENV === "staging") {
    environment.baseURL = "https://servicosonlineapihomolog.azurewebsites.net/api/"
}

if (process.env.REACT_APP_ENV === "production") {
    environment.baseURL = "https://servicosonlineapi.azurewebsites.net/api/"
}

export default environment;

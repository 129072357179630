import "./style/index.scss";
import { useEffect, useState } from "react";
import { AgendamentoState, executanteFetch, setExecutantesFiltrados } from "../../../redux/slices/agendamento/agendamentoSlice";
import { ConfiguracoesState, setPaginaAgendamento, setPaginaAnterior, setVoltar } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import Spinner from "../../../components/spinner/spinner";
import { Button, Card, Flex, Image, Input, Typography } from "antd";
import StepsComponent from "../../../components/steps";
import Flecha from "../../../components/flecha";
import { CarrinhoState, adicionarCarrinhoFetch } from "../../../redux/slices/carrinho/carrinhoSlice";
import { AgendamentoSelecaoCarrinhoModel } from "../../../models/carrinho/agendamentoSelecaoCarrinhoModel";
import { ExecutanteModel } from "../../../models/agendamento/executanteModel";
import { LeftOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import IconeCarrinhoComponent from "../../../components/carrinho";
import iconGeral from "../../../util/assets/icons/geral.svg";
import { ProcedimentoModel } from "../../../models/agendamento/procedimentoModel";
import { ConvenioModel } from "../../../models/agendamento/convenioModel";
import { LoginState, setTurnedSwipeMedicos } from "../../../redux/slices/account/loginSlice";

const { Title, Text } = Typography;

const tipoMedico = [
  {
    value: 1,
    label: "Qualquer médico",
  },
  {
    value: 2,
    label: "Médico homem",
  },
  {
    value: 3,
    label: "Médico mulher",
  },
  {
    value: 4,
    label: "Médico de preferência",
  },
];

const Medico = () => {
  //Estados Globais
  const agendamentoState: AgendamentoState = useAppSelector((state) => state.agendamento);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const carrinhoState: CarrinhoState = useAppSelector((state) => state.carrinho);
  const loginState: LoginState = useAppSelector((state) => state.login);

  //Hooks
  const dispatch = useAppDispatch();

  //Estados Locais
  const [openExecutante, setOpenExecutante] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setPaginaAnterior("exames"));
    dispatch(setVoltar(true));
    dispatch(
      executanteFetch({
        idLocal: carrinhoState.montagemCarrinho.idLocal,
        idTipoExame: carrinhoState.tipoExameSelecionado,
        idConvenio: carrinhoState.montagemCarrinho.idConvenio,
        idProcedimento: carrinhoState.montagemCarrinho.idProcedimento,
        idPlano: carrinhoState.montagemCarrinho.idPlano,
      })
    );
  }, []);

  const onChangeTipoMedico = (value: any) => {
    var carrinhoArgs: AgendamentoSelecaoCarrinhoModel = { ...carrinhoState.montagemCarrinho };
    switch (value) {
      case 1:
        carrinhoArgs.tipoPreferencia = 1;
        carrinhoArgs.idExecutantePreferencia = null;
        dispatch(adicionarCarrinhoFetch(carrinhoArgs));
        dispatch(setPaginaAgendamento("buscarHorarios"));
        break;
      case 2:
        carrinhoArgs.tipoPreferencia = 2;
        carrinhoArgs.idExecutantePreferencia = null;
        dispatch(adicionarCarrinhoFetch(carrinhoArgs));
        dispatch(setPaginaAgendamento("buscarHorarios"));
        break;
      case 3:
        carrinhoArgs.tipoPreferencia = 3;
        carrinhoArgs.idExecutantePreferencia = null;
        dispatch(adicionarCarrinhoFetch(carrinhoArgs));
        dispatch(setPaginaAgendamento("buscarHorarios"));
        break;
      default:
        setOpenExecutante(true);
        break;
    }
  };

  const onChangeExecutante = (value: number) => {
    var carrinhoArgs: AgendamentoSelecaoCarrinhoModel = { ...carrinhoState.montagemCarrinho };
    carrinhoArgs.tipoPreferencia = 4;
    carrinhoArgs.idExecutantePreferencia = value;
    dispatch(adicionarCarrinhoFetch(carrinhoArgs));
    dispatch(setPaginaAgendamento("buscarHorarios"));
  };

  const filtroExecutante = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputWords = e.target.value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .split(" ")
      .filter((word) => word); // Remove empty strings

    const listaOriginal = agendamentoState.listExecutante;

    let filtrado = listaOriginal.filter((a) =>
      inputWords.every((inputWord) =>
        a.denominacao
          .toLowerCase()
          .split(" ")
          .some((b) => b.includes(inputWord))
      )
    );

    dispatch(setExecutantesFiltrados(filtrado));
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <Flex vertical style={{ height: "100%" }} gap={14}>
          <StepsComponent current={1} />
          <Flex justify="flex-start" align="center" gap={10}>
            <Flecha />
            <Title className="titulo" level={4}>
              Escolha sua preferência
            </Title>
            <IconeCarrinhoComponent />
          </Flex>
          <Card style={{ padding: "8px" }}>
            <Flex align="center" justify="flex-start" gap={14}>
              <Image preview={false} style={{ marginLeft: "5px" }} src={iconGeral} height={"30px"} width={"30px"} />
              <Flex vertical style={{ width: "100%" }}>
                <Text style={{ fontSize: "13px" }}>{agendamentoState.listProcedimento.filter((a: ProcedimentoModel) => a.idProcedimentoExterno === carrinhoState.montagemCarrinho.idProcedimento)[0].denominacao}</Text>
                <Text style={{ fontSize: "10px" }}>{agendamentoState.listConvenio.filter((a: ConvenioModel) => a.idConvenioExterno === carrinhoState.montagemCarrinho.idConvenio)[0].denominacao}</Text>
              </Flex>
            </Flex>
          </Card>
          {openExecutante ? (
            <Flex vertical gap={10}>
              <Button icon={<LeftOutlined rev={undefined} />} onClick={() => setOpenExecutante(false)} style={{ color: "#909090", fontSize: "12px", textAlign: "left", width: "fit-content", height: "fit-content" }}>
                VOLTAR
              </Button>
              <Input
                onChange={filtroExecutante}
                allowClear
                prefix={<SearchOutlined style={{ color: "#d9d9d9" }} rev={undefined} />}
                placeholder="Busque pelo nome do médico"
                type="text"
                style={{ backgroundColor: "#ffffff", height: 40, border: "1px solid #b3b3b3", marginBottom: "10px" }}
              />
              {agendamentoState.executantesFiltrados.map((a: ExecutanteModel, index) => (
                <Card key={index} className="cardMedicos" onClick={() => onChangeExecutante(a.idExecutanteExterno)}>
                  <Flex style={{ width: "100%" }} gap={16} justify="space-between" align="center">
                    <Text style={{ wordBreak: "break-all", fontSize: "13px" }}>{a.denominacao}</Text>
                    <Flex align="center" justify="center">
                      <RightOutlined rev={undefined} style={{ color: "#909090", fontSize: "12px" }} />
                    </Flex>
                  </Flex>
                </Card>
              ))}
            </Flex>
          ) : (
            <Flex vertical gap={10}>
              {tipoMedico.map((a, index) => (
                <Card key={index} className="cardMedicos" onClick={() => onChangeTipoMedico(a.value)}>
                  <Flex style={{ width: "100%" }} gap={16} justify="space-between" align="center">
                    <Text style={{ wordBreak: "break-all", fontSize: "13px" }}>{a.label}</Text>
                    <Flex align="center" justify="center">
                      <RightOutlined rev={undefined} style={{ color: "#909090", fontSize: "12px" }} />
                    </Flex>
                  </Flex>
                </Card>
              ))}
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
};

export default Medico;

import "./style/menuBurguer.scss";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { ConfiguracoesState, setEsqueciSenha, setFecharMenu, setOrcamento, setPaginaAgendamento, setPaginaDependente } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { LoginState, resetToken } from "../../../redux/slices/account/loginSlice";
import { useAppSelector } from "../../../util/hooks";
import { Flex, Menu, Image, Layout } from "antd";
import { UserOutlined, FileAddOutlined, LogoutOutlined, CloseOutlined, HomeOutlined, FileTextOutlined, UsergroupAddOutlined, DollarOutlined } from "@ant-design/icons";
import iconeResultado from "../../../util/assets/icone_resultado.png";
import { AgendamentoState, localFetch, resetConverio, resetLocal, resetProcessarRespostaQuestionario } from "../../../redux/slices/agendamento/agendamentoSlice";
import { resetAll, resetPersistedStates } from "../../../redux/actions/actions";
import { CalendarPlus, Users } from "@phosphor-icons/react";
import { CarrinhoState, limparCarrinhoFetch } from "../../../redux/slices/carrinho/carrinhoSlice";

const { Header, Footer, Content } = Layout;

const MenuBurguer = (props: any) => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const agendamentoState: AgendamentoState = useAppSelector((state) => state.agendamento);
  const carrinhoState: CarrinhoState = useAppSelector((state) => state.carrinho);

  //Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goPerfil = () => {
    dispatch(setFecharMenu(false));
    dispatch(setPaginaAgendamento("unidade"));
    navigate("/perfil/" + loginState.empresa, { replace: true });
  };

  const goPaginaInicial = () => {
    dispatch(setFecharMenu(false));
    dispatch(resetLocal());
    dispatch(resetConverio());
    dispatch(resetProcessarRespostaQuestionario());
    navigate("/painel/" + loginState.empresa, { replace: true });
  };

  const goMeusAgendamentos = () => {
    dispatch(setFecharMenu(false));
    dispatch(setPaginaAgendamento("paciente"));
    navigate("/meusAgendamentos/" + loginState.empresa, { replace: true });
  };

  const goAgendamentos = () => {
    if (configuracoesState.orcamento === true && carrinhoState.carrinho.procedimentos !== null && carrinhoState.carrinho.procedimentos.length > 0) {
      dispatch(limparCarrinhoFetch());
    }
    dispatch(setOrcamento(false));
    dispatch(setFecharMenu(false));
    dispatch(setPaginaAgendamento("paciente"));
    navigate("/agendamento/" + loginState.empresa, { replace: true });
  };

  const goPlanoExameOrcamento = () => {
    dispatch(setFecharMenu(false));
    dispatch(setOrcamento(true));
    if (carrinhoState.carrinho.procedimentos !== null && carrinhoState.carrinho.procedimentos.length > 0) {
      dispatch(limparCarrinhoFetch());
    }
    dispatch(setPaginaAgendamento("paciente"));
    navigate("/agendamento/" + loginState.empresa, { replace: true });
  };

  const goDependentes = () => {
    dispatch(setFecharMenu(false));
    dispatch(setPaginaDependente("dependentes"));
    navigate("/dependentes/" + loginState.empresa, { replace: true });
  };

  const sair = () => {
    dispatch(resetAll());
    dispatch(resetPersistedStates());
    dispatch(setFecharMenu(false));
    navigate("/" + loginState.empresa, { replace: true });
  };

  const fecharMenu = () => {
    dispatch(setFecharMenu(false));
  };

  return (
    <Layout style={!configuracoesState.menu ? { display: "none" } : { zIndex: 99999999, height: "100%" }}>
      <Header className="headerBurguer" style={{ backgroundColor: props.color }}>
        <Flex style={{ width: "100%" }} justify="space-between" align="center" gap={10}>
          <Image preview={false} onClick={() => (loginState.token !== null ? goPaginaInicial : sair)} src={loginState.configuracaoAparencia.logo} alt="logotipo" />
          {loginState.token !== null ? <CloseOutlined rev={undefined} className="iconCloseMenuBurguer" onClick={fecharMenu} /> : <></>}
        </Flex>
      </Header>
      <Content className="menuBurguer">
        <Menu style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria }}>
          <Menu.Item key="1" icon={<HomeOutlined rev={undefined} />} onClick={goPaginaInicial}>
            Página inicial
          </Menu.Item>
          <Menu.Item key="2" icon={<CalendarPlus size={16} />} onClick={goAgendamentos}>
            Agendamento de exames
          </Menu.Item>
          <Menu.Item key="3" icon={<FileAddOutlined rev={undefined} />} onClick={goMeusAgendamentos}>
            Meus agendamentos
          </Menu.Item>
          <Menu.Item key="4" icon={<UserOutlined rev={undefined} />} onClick={goPerfil}>
            Meu perfil
          </Menu.Item>
          <Menu.Item key="5" icon={<Users size={16} />} onClick={goDependentes}>
            Meus dependentes
          </Menu.Item>
          <Menu.Item key="6" icon={<DollarOutlined rev={undefined} />} onClick={goPlanoExameOrcamento}>
            Orçamento de exames
          </Menu.Item>
          <Menu.Item key="7" icon={<LogoutOutlined rev={undefined} />} onClick={sair}>
            Sair
          </Menu.Item>
        </Menu>
      </Content>
      <Footer style={{ backgroundColor: props.color }}>
        <hr className="barraBranca" />
      </Footer>
    </Layout>
  );
};

export default MenuBurguer;

import { Navigate, Route, Routes, BrowserRouter, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./util/hooks";
import MainTemplate from "./components/templates/main/mainTemplate";
import Acessar from "./views/acessar";
import Painel from "./views/painel";
import Agendamento from "./views/agendamento/agendamentoPageController";
import Perfil from "./views/perfil";
import Identidade from "./views/identidade/identidadePageController";
import ConfirmarUsuario from "./views/confirmarUsuario";
import { LoginState, setConfig, setEmpresa } from "./redux/slices/account/loginSlice";
import MeusAgendamentos from "./views/meusAgendamentos";
import { Flex, Typography, Image, Layout } from "antd";
import { useEffect, useState } from "react";
import { setLoading } from "./redux/slices/configuracoes/configuracoesSlice";
import { configuracaoEmpresa } from "./services/accountService";
import Dependentes from "./views/dependentes";
import atualizacao from "./util/assets/atualizacao-de-site.jpg";
import { Content } from "antd/lib/layout/layout";

const { Title, Text, Link } = Typography;

const NormalRouteTemplateLogin = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const fetchConfig = async (dispatch: any, empresa: string) => {
    try {
      dispatch(setLoading(true));
      const data = await configuracaoEmpresa(empresa);
      const response = data.data.data;
      if (response.manutencao) {
        navigate("/manutencao", { replace: true });
        dispatch(setLoading(false));
      } else {
        document.documentElement.style.setProperty("--color-variable", response.corPrimaria);
        dispatch(setEmpresa(empresa));
        dispatch(setConfig(response));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      navigate("/naoEncontrado", { replace: true });
    }
  };

  useEffect(() => {
    const urlList = location.pathname.split("/");
    fetchConfig(dispatch, urlList[1]);
  }, []);

  return <MainTemplate>{children}</MainTemplate>;
};

const PrivateRouteTemplateLogin = ({ children }: any) => {
  const loginState: LoginState = useAppSelector((state) => state.login);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const fetchConfig = async (dispatch: any, empresa: string) => {
    try {
      dispatch(setLoading(true));
      const data = await configuracaoEmpresa(empresa);
      const response = data.data.data;
      document.documentElement.style.setProperty("--color-variable", response.corPrimaria);
      dispatch(setEmpresa(empresa));
      dispatch(setConfig(response));
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      navigate("/naoEncontrado", { replace: true });
    }
  };

  useEffect(() => {
    const urlList = location.pathname.split("/");
    fetchConfig(dispatch, urlList[2]);
  }, []);

  return loginState.token !== null ? <MainTemplate>{children}</MainTemplate> : <Navigate to={"/" + loginState.empresa} replace={true} />;
};

const NormalRouteTemplate = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const fetchConfig = async (dispatch: any, empresa: string) => {
    try {
      dispatch(setLoading(true));
      const data = await configuracaoEmpresa(empresa);
      const response = data.data.data;
      document.documentElement.style.setProperty("--color-variable", response.corPrimaria);
      dispatch(setEmpresa(empresa));
      dispatch(setConfig(response));
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      navigate("/naoEncontrado", { replace: true });
    }
  };

  useEffect(() => {
    const urlList = location.pathname.split("/");
    fetchConfig(dispatch, urlList[2]);
  }, []);

  return <MainTemplate>{children}</MainTemplate>;
};

const irSiteUniclinika = () => {
  window.open("https://uniclinika.com.br", "_blank");
};

const ExternalRedirect = () => {
  useEffect(() => {
    window.location.href = "https://uniclinika.com.br";
  }, []);

  return null;
};

const RouteComponent = () => (
  <BrowserRouter>
    <Routes>
      <Route
        path="/:empresa"
        element={
          <NormalRouteTemplateLogin>
            <Acessar />
          </NormalRouteTemplateLogin>
        }
      />
      <Route
        path="/confirmarUsuario/:empresa"
        element={
          <NormalRouteTemplate>
            <ConfirmarUsuario />
          </NormalRouteTemplate>
        }
      />
      <Route
        path="/identidade/:empresa"
        element={
          <NormalRouteTemplate>
            <Identidade />
          </NormalRouteTemplate>
        }
      />
      <Route
        path="/agendamento/:empresa"
        element={
          <PrivateRouteTemplateLogin>
            <Agendamento />
          </PrivateRouteTemplateLogin>
        }
      />
      <Route
        path="/painel/:empresa"
        element={
          <PrivateRouteTemplateLogin>
            <Painel />
          </PrivateRouteTemplateLogin>
        }
      />
      <Route
        path="/dependentes/:empresa"
        element={
          <PrivateRouteTemplateLogin>
            <Dependentes />
          </PrivateRouteTemplateLogin>
        }
      />
      <Route
        path="/perfil/:empresa"
        element={
          <PrivateRouteTemplateLogin>
            <Perfil />
          </PrivateRouteTemplateLogin>
        }
      />
      <Route
        path="/meusAgendamentos/:empresa"
        element={
          <PrivateRouteTemplateLogin>
            <MeusAgendamentos />
          </PrivateRouteTemplateLogin>
        }
      />
      <Route
        path="naoEncontrado"
        element={
          <Flex style={{ padding: 24 }} vertical>
            <Title level={2}>Página não encontrada</Title>
            <Text style={{ marginTop: "-10px" }}>Para mais informações entre no link abaixo:</Text>
            <Link onClick={irSiteUniclinika}>uniclinika.com.br</Link>
          </Flex>
        }
      />
      <Route
        path="manutencao"
        element={
          <Layout className="layoutPrincipal">
            <Layout style={{ backgroundColor: "#ffffff" }} className="layoutSecundario">
              <div style={{ height: "100%" }}>
                <Content className="conteudo">
                  <Flex style={{ padding: 8 }} justify="center" align="center" vertical gap={5}>
                    <Image src={atualizacao} preview={false} />
                    <Title style={{ marginTop: 15, textAlign: "center" }} level={4}>
                      SISTEMA EM ATUALIZAÇÃO
                    </Title>
                    <Text style={{ marginTop: "-10px", fontSize: "16px", textJustify: "inter-word", textAlign: "justify" }}>
                      Estamos realizando uma atualização no sistema, que está temporariamente indisponível. Por favor, tente novamente mais tarde.
                    </Text>
                  </Flex>
                </Content>
              </div>
            </Layout>
          </Layout>
        }
      />
      <Route
        path="*"
        element={
          <Flex style={{ padding: 24 }} vertical>
            <Title level={2}>Página não encontrada</Title>
            <Text style={{ marginTop: "-10px" }}>Para mais informações entre no link abaixo:</Text>
            <Link onClick={irSiteUniclinika}>uniclinika.com.br</Link>
          </Flex>
        }
      />
      <Route path="/" element={<ExternalRedirect />} />
    </Routes>
  </BrowserRouter>
);

export default RouteComponent;

import { useEffect, useRef } from "react";
import "./style/index.scss";
import Flecha from "../../components/flecha";
import { useAppDispatch, useAppSelector } from "../../util/hooks";
import { PerfilState, perfilFetch, updatePerfilFetch } from "../../redux/slices/perfil/perfilSlice";
import moment from "moment";
import { AgendamentoState, convenioTodosFetch } from "../../redux/slices/agendamento/agendamentoSlice";
import { ConvenioModel } from "../../models/agendamento/convenioModel";
import { PacienteModel, TelefoneModel } from "../../models/perfil/pacienteModel";
import { PlanoSaudePacienteModel } from "../../models/perfil/planoSaudePacienteModel";
import { Button, Col, Flex, Form, Input, InputNumber, List, Row, Select, Typography } from "antd";
import { ConfiguracoesState, setLoading, setVoltar } from "../../redux/slices/configuracoes/configuracoesSlice";
import { LoginState } from "../../redux/slices/account/loginSlice";
import Spinner from "../../components/spinner/spinner";
import { formatCPF, formatTelefone } from "../../util/formats";

type FormPerfil = {
  email: string;
  nome: string;
  sexo: string;
  dataNascimento: string;
  peso: string;
  convenio: number;
};

const { Title, Text } = Typography;

const Perfil = () => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);
  const perfilState: PerfilState = useAppSelector((state) => state.perfil);
  const agendamentoState: AgendamentoState = useAppSelector((state) => state.agendamento);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);

  //Hooks
  const dispatch = useAppDispatch();

  //Estados Locais
  const [form] = Form.useForm<FormPerfil>();

  useEffect(() => {
    dispatch(convenioTodosFetch());
    if (Object.values(perfilState.perfil).length === 0) {
      dispatch(perfilFetch());
    } else {
      form.setFieldsValue({
        email: perfilState.perfil.email,
        nome: perfilState.perfil.nomePaciente,
        sexo: perfilState.perfil.sexo,
        dataNascimento: moment(perfilState.perfil.dataNascimento).format("DD/MM/YYYY"),
        peso: perfilState.perfil.peso,
        convenio: perfilState.perfil.idConvenioPreferencia !== null ? perfilState.perfil.idConvenioPreferencia : 0,
      } as FormPerfil);
    }
  }, [perfilState.perfil]);

  useEffect(() => {
    if (configuracoesState.paginaAnterior === "vindoDePaciente") {
      dispatch(setVoltar(true));
    } else {
      dispatch(setVoltar(false));
    }
  }, []);

  const submit = async (values: FormPerfil) => {
    var plano: PlanoSaudePacienteModel = {
      idPlanoSaude: values.convenio,
      matriculaPlano: null,
      validade: null,
    };
    var args: PacienteModel = {
      idPaciente: perfilState.perfil.idPaciente,
      idPacienteResponsavel: perfilState.perfil.idPacienteResponsavel,
      idUsuario: perfilState.perfil.idUsuario,
      email: perfilState.perfil.email,
      telefones: perfilState.perfil.telefones,
      nomePaciente: perfilState.perfil.nomePaciente,
      cpf: perfilState.perfil.cpf,
      sexo: perfilState.perfil.sexo,
      dataNascimento: perfilState.perfil.dataNascimento,
      peso: values.peso,
      idConvenioPreferencia: values.convenio,
      plano: plano,
      dependentes: perfilState.perfil.dependentes,
      parentesco: null,
      idade: perfilState.perfil.idade,
    };
    dispatch(updatePerfilFetch(args));
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <Flex vertical style={{ height: "100%" }} gap={10}>
          <Flex justify="flex-start" align="center" gap={10}>
            <Flecha />
            <Title className="titulo" level={4}>
              Meu Perfil
            </Title>
          </Flex>
          <Form layout="vertical" form={form} onFinish={submit}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Email de contato" name="email">
                  <Input type="text" disabled style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Nome completo" name="nome">
                  <Input type="text" disabled style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={10}>
                <Form.Item label="CPF">
                  <Button disabled style={{ width: "100%", textAlign: "left" }}>
                    {formatCPF(perfilState.perfil.cpf)}
                  </Button>
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label="Sexo Biológico" name="sexo">
                  <Select
                    disabled
                    style={{ width: "100%" }}
                    options={[
                      { value: "m", label: "Masculino" },
                      { value: "f", label: "Feminino" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={5} className="inputNumberClass">
                <Form.Item label="Peso" name="peso">
                  <InputNumber controls={false} maxLength={3} style={{ width: "100%" }} min={0} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24} className="listaTelefones">
                <Form.Item label="Telefones">
                  <List
                    dataSource={perfilState.perfil.telefones}
                    renderItem={(item: TelefoneModel, index) => (
                      <List.Item key={index}>
                        <Button disabled>{formatTelefone(item.numero)}</Button>
                      </List.Item>
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Convênio de Preferência" name="convenio">
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Selecione um convênio de preferência"
                    filterOption={(input, option) => option?.label.toLowerCase().includes(input.toLowerCase()) === true}
                    options={agendamentoState.listConvenioTodos.map((a: ConvenioModel) => ({
                      value: a.idConvenioExterno,
                      label: a.denominacao,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Button htmlType="submit" style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }}>
                  ATUALIZAR PERFIL
                </Button>
              </Col>
            </Row>
          </Form>
        </Flex>
      )}
    </>
  );
};

export default Perfil;

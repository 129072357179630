import { call, put, takeEvery } from "redux-saga/effects";
import { setLoading, setPaginaAgendamento } from "../../slices/configuracoes/configuracoesSlice";
import { toast } from "react-toastify";
import { adicionarItemCarrinho, limparCarrinho, listarItensCarrinho, removerItemCarrinho } from "../../../services/carrinhoAgendamentoService";
import { CarrinhoState, resetCarrinho, setCarrinho, setLoadingCarrinho, setMontagemCarrinho } from "../../slices/carrinho/carrinhoSlice";
import { appSelect } from "../../../util/hooks";

//adicionar item carrinho
function* callAdicionar(action: any): any {
  try {
    yield put(setLoading(true));
    yield call(adicionarItemCarrinho, action.payload);
    const data = yield call(listarItensCarrinho);
    const response = data.data.data;
    yield put(setCarrinho(response));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* adicionarCarrinhoSaga() {
  yield takeEvery("carrinho/adicionarCarrinhoFetch", callAdicionar);
}

//listar itens carrinho
function* callListar(): any {
  try {
    const data = yield call(listarItensCarrinho);
    const response = data.data.data;
    yield put(setCarrinho(response));
  } catch (error: any) {
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* listarCarrinhoSaga() {
  yield takeEvery("carrinho/listarCarrinhoFetch", callListar);
}

//limpar carrinho
function* callLimpar(): any {
  try {
    yield put(setLoadingCarrinho(true));
    yield call(limparCarrinho);
    yield put(resetCarrinho());
    yield put(setLoadingCarrinho(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* limparCarrinhoSaga() {
  yield takeEvery("carrinho/limparCarrinhoFetch", callLimpar);
}

//limpar carrinho local
function* callLimparLocal(action: any): any {
  const carrinhoState: CarrinhoState = yield appSelect((state) => state.carrinho) as Generator<any, any, any>;
  try {
    yield put(setLoading(true));
    yield call(limparCarrinho);
    yield put(resetCarrinho());
    var montagemCarrinho = { ...carrinhoState.montagemCarrinho };
    montagemCarrinho.idLocal = action.payload;
    yield put(setMontagemCarrinho(montagemCarrinho));
    yield put(setPaginaAgendamento("convenios"));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* limparCarrinhoLocalSaga() {
  yield takeEvery("carrinho/limparCarrinhoLocalFetch", callLimparLocal);
}

//remover item carrinho
function* callRemoverItem(action: any): any {
  try {
    yield call(removerItemCarrinho, action.payload);
    const data = yield call(listarItensCarrinho);
    const response = data.data.data;
    yield put(setCarrinho(response));
  } catch (error: any) {
    toast.error(error.response.data.Message, {
      position: "bottom-center",
      isLoading: false,
      autoClose: 2000,
      closeOnClick: true,
    });
  }
}

export function* removerItemCarrinhoSaga() {
  yield takeEvery("carrinho/removerItemCarrinhoFetch", callRemoverItem);
}

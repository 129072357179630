import { Flex, Typography } from "antd";

type CardOptionsProps = {
  title: string;
  description: string;
  buttonTitle: string;
  backgroundColor: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const { Title, Text } = Typography;

const CardOptions = ({ title, description, buttonTitle, backgroundColor, onClick }: CardOptionsProps) => {
  const styles = {
    container: {
      cursor: "pointer",
      width: "100%",
      minHeight: "120px",
    },
    emptyCard: {
      minHeight: "inherit",
      width: "20px",
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
      backgroundColor: backgroundColor,
    },
    cardDescription: {
      backgroundColor: "#ffffff",
      paddingLeft: "6px",
      paddingRight: "6px",
      flex: 1,
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
    },
    titulo: {
      fontSize: "15px",
      fontWeight: "bold",
      width: "100%",
      marginTop: "6px",
    },
    text: {
      fontSize: "12px",
    },
    cardButton: {
      width: "80px",
      textAlign: "center" as "center",
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
      backgroundColor: backgroundColor,
      flexShrink: 0,
      color: "#ffffff",
      paddingLeft: "6px",
      paddingRight: "6px",
    },
  };

  return (
    <Flex style={styles.container} justify="center" align="stretch" onClick={onClick}>
      {/* <div style={styles.emptyCard}></div> */}
      <Flex style={styles.cardDescription} vertical>
        <Title style={styles.titulo} level={5}>
          {title}
        </Title>
        <Text style={styles.text}> {description}</Text>
      </Flex>
      <Flex style={styles.cardButton} align="center" justify="center">
        {buttonTitle}
      </Flex>
    </Flex>
  );
};

export default CardOptions;

import "./style/headerTemplate.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../util/hooks";
import { useDispatch } from "react-redux";
import { ConfiguracoesState, resetAgilizouAnexos, setFecharMenu, setPaginaAgendamento, setPaginaAnterior, setPaginaDependente } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import { Header } from "antd/lib/layout/layout";
import { Flex, Image } from "antd";
import { MenuOutlined, LeftOutlined } from "@ant-design/icons";
import { resetConverio, resetLocal, resetProcessarRespostaQuestionario } from "../../../redux/slices/agendamento/agendamentoSlice";

import uniclinikaLogo from "../../../util/assets/uniclinika_branco.svg";
import { resetPaciente, setConfirmouUsuario } from "../../../redux/slices/account/accountSlice";
import { resetAll, resetPersistedStates } from "../../../redux/actions/actions";
import { resetPerfil } from "../../../redux/slices/perfil/perfilSlice";

const HeaderTemplate = (props: any) => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);

  //Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  //Estados Locais
  const urlList = location.pathname.split("/");

  const goMenu = () => {
    dispatch(setFecharMenu(!configuracoesState.menu));
  };

  const goVoltar = () => {
    if (configuracoesState.vemDeMeusAgendamentos) {
      navigate("/meusAgendamentos/" + loginState.empresa, { replace: true });
    } else if (configuracoesState.paginaAnterior.includes("painel")) {
      dispatch(resetLocal());
      dispatch(resetConverio());
      dispatch(resetProcessarRespostaQuestionario());
      navigate("/painel/" + loginState.empresa, { replace: true });
    } else if (configuracoesState.paginaAnterior.includes("vindoDeDependentes")) {
      if(configuracoesState.paginaAgendamento === "paciente"){
        dispatch(setPaginaAnterior("vindoDePaciente"));
      }
      dispatch(setPaginaDependente("dependentes"));
      navigate("/dependentes/" + loginState.empresa, { replace: true });
    } else if (configuracoesState.paginaAnterior.includes("vindoDePaciente")) {
      dispatch(setPaginaAgendamento("paciente"));
      navigate("/agendamento/" + loginState.empresa, { replace: true });
    } else {
      dispatch(setPaginaAgendamento(configuracoesState.paginaAnterior));
    }
  };

  const goHome = () => {
    if (loginState.token !== null) {
      dispatch(resetLocal());
      dispatch(resetConverio());
      dispatch(resetAgilizouAnexos());
      dispatch(resetProcessarRespostaQuestionario());
      navigate("/painel/" + loginState.empresa, { replace: true });
    } else {
      if (urlList.length !== 2) {
        dispatch(resetAll());
        dispatch(resetPersistedStates());
        navigate("/" + loginState.empresa, { replace: true });
      }
    }
  };

  return (
    <Header className="header" style={{ backgroundColor: props.color }}>
      <Flex style={{ width: "100%" }} align="center" justify="space-between" gap={10}>
        <LeftOutlined className={configuracoesState.voltar ? "iconLeftHeader" : "hiddenVisibility "} rev={undefined} onClick={goVoltar} />
        <Image style={{ cursor: "pointer" }} preview={false} onClick={goHome} src={loginState.configuracaoAparencia.logo} />
        <MenuOutlined rev={undefined} className={loginState.token !== null ? "iconMenuHeader" : "hiddenVisibility "} onClick={goMenu} />
      </Flex>
    </Header>
  );
};

export default HeaderTemplate;

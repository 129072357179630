import "./style/index.scss";
import { Button, Card, Flex, Modal, Typography } from "antd";
import { PerfilState, perfilFetch, removerDependenteFetch, setAtualizarDependente } from "../../redux/slices/perfil/perfilSlice";
import { useAppDispatch, useAppSelector } from "../../util/hooks";
import { ConfiguracoesState, setPaginaAnterior, setPaginaDependente, setVoltar } from "../../redux/slices/configuracoes/configuracoesSlice";
import { useEffect, useState } from "react";
import Spinner from "../../components/spinner/spinner";
import { CloseCircleOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import Flecha from "../../components/flecha";
import { LoginState } from "../../redux/slices/account/loginSlice";
import { PacienteModel } from "../../models/perfil/pacienteModel";
import NovoDependente from "./novoDependente";

const { Title, Text, Link } = Typography;

const Dependentes = () => {
  //Estados Globais
  const perfilState: PerfilState = useAppSelector((state) => state.perfil);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const loginState: LoginState = useAppSelector((state) => state.login);

  //Hooks
  const dispatch = useAppDispatch();

  //Estados Locais
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [idPacienteRemover, setIdPacienteRemover] = useState<number>(0);

  useEffect(() => {
    dispatch(setVoltar(true));
    if (configuracoesState.paginaAgendamento === "paciente") {
      dispatch(setPaginaAnterior("vindoDePaciente"));
    } else {
      dispatch(setPaginaAnterior("painel"));
    }
    if (Object.values(perfilState.perfil).length === 0) {
      dispatch(perfilFetch());
    }
  }, [perfilState.perfil]);

  const cadastrarDependente = () => {
    dispatch(setAtualizarDependente({}));
    dispatch(setPaginaAnterior("vindoDeDependentes"));
    dispatch(setPaginaDependente("novoDependente"));
  };

  const atualizarDependente = (value: PacienteModel) => {
    dispatch(setAtualizarDependente(value));
    dispatch(setPaginaAnterior("vindoDeDependentes"));
    dispatch(setPaginaDependente("novoDependente"));
  };

  const openModalRemover = (idPaciente: number) => {
    setIdPacienteRemover(idPaciente);
    setOpenModal(true);
  };

  const removerDependente = () => {
    dispatch(removerDependenteFetch(idPacienteRemover));
    setOpenModal(false);
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <>
          {configuracoesState.paginaDependente === "novoDependente" ? (
            <>
              <NovoDependente />
            </>
          ) : (
            <>
              <Flex vertical style={{ height: "100%" }} gap={10}>
                <Flex justify="flex-start" align="center" gap={10}>
                  <Flecha />
                  <Title className="titulo" level={4}>
                    Dependentes
                  </Title>
                </Flex>
                <Flex vertical gap={10}>
                  {Object.values(perfilState.perfil).length !== 0 &&
                    perfilState.perfil.dependentes.length !== 0 &&
                    perfilState.perfil.dependentes.map((a: PacienteModel, index) => (
                      <Card key={index} className="cardMeusDependentes">
                        <Flex style={{ width: "100%" }} gap={24} justify="space-between" align="center">
                          <Flex vertical>
                            <Text style={{ wordBreak: "break-all", fontSize: "14px" }}>{a.nomePaciente}</Text>
                            <Text style={{ wordBreak: "break-all", fontSize: "12px", color: "#606060" }}>{`${a.parentesco}, ${a.idade} anos, ${a.peso}kg`}</Text>
                          </Flex>
                          <Flex justify="flex-end" gap={16}>
                            <EditOutlined style={{ fontSize: "20px", color: "#b3b3b3" }} rev={undefined} onClick={() => atualizarDependente(a)} />
                            <CloseCircleOutlined style={{ fontSize: "20px", color: "red", marginRight: 8 }} rev={undefined} onClick={() => openModalRemover(a.idPaciente !== null ? a.idPaciente : 0)} />
                          </Flex>
                        </Flex>
                      </Card>
                    ))}
                  <Card key={"cadastro"} className="cardDependentes" onClick={cadastrarDependente}>
                    <Flex style={{ width: "100%" }} gap={16} justify="center" align="center">
                      <Text style={{ fontSize: "14px" }}>Adicionar dependente</Text>
                      <PlusCircleOutlined style={{ fontSize: "35px", color: "#b3b3b3" }} rev={undefined} />
                    </Flex>
                  </Card>
                </Flex>
              </Flex>
              <Modal width={320} centered open={openModal} footer={null} destroyOnClose closeIcon={false}>
                <Flex vertical justify="center" align="center" gap={10}>
                  <Text style={{ textAlign: "center", color: "#101010" }}>Deseja remover o dependente?</Text>
                  <Flex gap={20}>
                    <Button style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100px" }} onClick={removerDependente}>
                      SIM
                    </Button>
                    <Button style={{ width: "100px" }} onClick={() => setOpenModal(false)}>
                      NÃO
                    </Button>
                  </Flex>
                </Flex>
              </Modal>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Dependentes;

import { all, fork } from "redux-saga/effects";
import {
  alterarSenhaSaga,
  configSaga,
  confirmacaoUsuarioSaga,
  criarUsuarioSaga,
  enviarCodigoSaga,
  loginSaga,
  mensagemAtualizacaoCadastroSaga,
  recuperarSenhaSaga,
  verificarCodigoSaga,
  verificarPodeUtilizarLoginSaga,
} from "./sagas/account/accountSaga";
import {
  bloquearHorarioSaga,
  buscaDiasDisponiveisSaga,
  buscarHorariosSaga,
  confirmarAgendamentoSaga,
  convenioSaga,
  convenioTodosSaga,
  executanteSaga,
  localSaga,
  procedimentoSaga,
  processarRespostaQuestionarioSaga,
  tipoExameSaga,
} from "./sagas/agendamento/agendamentoSaga";
import {
  atualizarDependenteSaga,
  cadastrarAnexoCarteirinhaSaga,
  cadastrarAnexoGuiaSaga,
  criarDependenteSaga,
  meusAgendamentosSaga,
  perfilSaga,
  preparoAgendamentoSaga,
  preparoAtendimentoSaga,
  removerDependenteSaga,
  removerProcedimentoSaga,
  updatePerfilSaga,
} from "./sagas/perfil/perfilSaga";
import { adicionarCarrinhoSaga, limparCarrinhoSaga, limparCarrinhoLocalSaga, listarCarrinhoSaga, removerItemCarrinhoSaga } from "./sagas/carrinho/carrinhoSaga";

export function* rootSaga() {
  yield all([
    //account
    fork(loginSaga),
    fork(configSaga),
    fork(criarUsuarioSaga),
    fork(alterarSenhaSaga),
    fork(recuperarSenhaSaga),
    fork(enviarCodigoSaga),
    fork(verificarCodigoSaga),
    fork(confirmacaoUsuarioSaga),
    fork(mensagemAtualizacaoCadastroSaga),
    fork(verificarPodeUtilizarLoginSaga),
    //agendamento
    fork(buscarHorariosSaga),
    fork(buscaDiasDisponiveisSaga),
    fork(bloquearHorarioSaga),
    fork(processarRespostaQuestionarioSaga),
    fork(confirmarAgendamentoSaga),
    fork(tipoExameSaga),
    fork(convenioSaga),
    fork(convenioTodosSaga),
    fork(localSaga),
    fork(executanteSaga),
    fork(procedimentoSaga),
    //perfil
    fork(perfilSaga),
    fork(updatePerfilSaga),
    fork(cadastrarAnexoCarteirinhaSaga),
    fork(cadastrarAnexoGuiaSaga),
    fork(meusAgendamentosSaga),
    fork(removerProcedimentoSaga),
    fork(preparoAtendimentoSaga),
    fork(preparoAgendamentoSaga),
    fork(criarDependenteSaga),
    fork(atualizarDependenteSaga),
    fork(removerDependenteSaga),
    //carrinho
    fork(adicionarCarrinhoSaga),
    fork(listarCarrinhoSaga),
    fork(limparCarrinhoSaga),
    fork(limparCarrinhoLocalSaga),
    fork(removerItemCarrinhoSaga),
  ]);
}

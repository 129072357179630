import { useEffect, useState } from "react";
import { ReactComponent as Loading } from "../../util/assets/loading.svg";
import { Flex, Space, Typography } from "antd";

type SpinnerProps = {
  texto?: string;
};

const { Text } = Typography;

const Spinner = ({ texto }: SpinnerProps) => {
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setRotation((r) => r + 30);
    }, 75);
    return () => clearInterval(interval);
  }, [75]);

  return (
    <Space direction="vertical">
      <Flex vertical gap={10} justify="center" align="center" style={{ position: "absolute", margin: "auto", top: 0, bottom: 0, left: 0, right: 0, height: "100%", zIndex: 999999999 }}>
        <Loading
          style={{
            transform: `rotate(${rotation}deg)`,
            fill: "blue",
            width: "75px",
            height: "75px",
          }}
        />
        {texto ? <Text style={{ fontSize: "15px" }}>{texto}</Text> : ""}
      </Flex>
    </Space>
  );
};

export default Spinner;

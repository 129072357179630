import { Steps } from "antd";
import "./style/index.scss";

const StepsComponent = (props: any) => {
  return (
    <Steps
      labelPlacement="vertical"
      current={props.current}
      size="small"
      responsive={false}
      items={[
        {
          title: "Unidade",
        },
        {
          title: "Exames",
        },
        {
          title: "Horários",
        },
        {
          title: "Conclusão",
        },
      ]}
    />
  );
};

export default StepsComponent;

import "./style/index.scss";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import { toast } from "react-toastify";
import { AccountState, enviarCodigoFetch, mensagemAtualizacaoCadastroFetch, verificarPodeUtilizarLoginFetch } from "../../../redux/slices/account/accountSlice";
import { ConfiguracoesState, setEmailorCell, setPaginaIdentidade } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { EnviarCodigoModel } from "../../../models/account/enviarCodigoModel";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import { Button, Divider, Flex, Image, List, Modal, Radio, RadioChangeEvent, Typography } from "antd";
import { TelefoneModel } from "../../../models/perfil/pacienteModel";
import { formatEmailHidden, formatTelefoneHidden } from "../../../util/formats";
import Spinner from "../../../components/spinner/spinner";
import { useNavigate } from "react-router-dom";
import imgNaoEncontrado from "../../../util/assets/nao_encontrado.png";
import Parser from "html-react-parser";
import { resetAll, resetPersistedStates } from "../../../redux/actions/actions";

const { Title, Text } = Typography;

const EnviarCodigo = () => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);
  const accountState: AccountState = useAppSelector((state) => state.account);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);

  //Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //Estados Locais
  const [value, setValue] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);

  const onChangeRadio = ({ target: { value } }: RadioChangeEvent) => {
    setValue(value);
    dispatch(setEmailorCell(value));
  };

  const enviarCodigo = async () => {
    if (value !== "") {
      var args: EnviarCodigoModel = {
        emailOrCell: value,
        clientId: loginState.empresa,
      };
      if (configuracoesState.esqueciSenha) {
        dispatch(enviarCodigoFetch(args));
      } else {
        dispatch(verificarPodeUtilizarLoginFetch(args));
      }
    } else {
      toast.error("Escolha uma das opções", {
        autoClose: 1000,
      });
    }
  };

  const nenhumaOpcao = () => {
    if (accountState.paciente.idPaciente !== null && accountState.paciente.idUsuario !== null) {
      dispatch(mensagemAtualizacaoCadastroFetch(loginState.empresa));
      setOpenModal(true);
    } else {
      dispatch(setPaginaIdentidade("informarTelefone"));
    }
  };

  const voltarLogin = () => {
    dispatch(resetAll());
    dispatch(resetPersistedStates());
    navigate("/" + loginState.empresa, { replace: true });
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <>
          <Flex vertical style={{ height: "100%" }} className="enviarCodigo " gap={10}>
            <Title style={{ textAlign: "center" }} level={4}>
              CONFIRMAÇÃO DE IDENTIDADE
            </Title>
            <Text style={{ textAlign: "center", color: "#101010", marginTop: "-15px" }}>
              {configuracoesState.esqueciSenha ? "Identificamos seu cadastro. " : "Identificamos seu cadastro. Vamos agora criar seu usuário na plataforma. "}Escolha um desses contatos para receber o código de validação da sua conta:
            </Text>
            <Radio.Group onChange={onChangeRadio}>
              {accountState.paciente.telefones?.map((a: TelefoneModel) =>
                a.numero !== null ? (
                  <Radio key={a.numero} value={a.numero}>
                    {formatTelefoneHidden(a.numero)}
                  </Radio>
                ) : null
              )}
              {accountState.paciente.email !== null ? (
                <Radio key={accountState.paciente.email} value={accountState.paciente.email}>
                  {formatEmailHidden(accountState.paciente.email)}
                </Radio>
              ) : null}
            </Radio.Group>
            <Divider />
            <Button style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff" }} onClick={enviarCodigo}>
              RECEBER CÓDIGO
            </Button>
            <Button className="noOption" onClick={nenhumaOpcao}>
              NENHUMA DAS OPÇÕES ACIMA
            </Button>
          </Flex>
          <Modal width={320} centered open={openModal} footer={null} onCancel={() => setOpenModal(false)} destroyOnClose closeIcon={false}>
            <Flex vertical justify="center" align="center" gap={10}>
              <Image preview={false} src={imgNaoEncontrado} />
              {Parser(accountState.mensagem)}
              <Button style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }} onClick={voltarLogin}>
                VOLTAR PARA LOGIN
              </Button>
            </Flex>
          </Modal>
        </>
      )}
    </>
  );
};

export default EnviarCodigo;

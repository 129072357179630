import "./style/mainTemplate.scss";
import "antd/dist/reset.css";
import "react-toastify/dist/ReactToastify.css";
import HeaderTemplate from "../header/headerTemplate";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import { useAppSelector } from "../../../util/hooks";
import { ConfiguracoesState } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { Layout } from "antd";
import { ToastContainer } from "react-toastify";
import MenuBurguer from "../menuBurguer/menuBurguer";
import { AgendamentoState } from "../../../redux/slices/agendamento/agendamentoSlice";

const { Content } = Layout;

const MainTemplate = ({ children }: any) => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const agendamentoState: AgendamentoState = useAppSelector((state) => state.agendamento);

  return (
    <Layout className="layoutPrincipal">
      <Layout style={{ backgroundColor: "#f0f0f0" }} className="layoutSecundario">
        <MenuBurguer color={loginState.configuracaoAparencia.corPrimaria} />
        <div style={configuracoesState.menu ? { display: "none" } : { height: "100%" }}>
          <div className={configuracoesState.loadingSpinner ? "visibilityHidden" : ""}>
            <HeaderTemplate color={loginState.configuracaoAparencia.corPrimaria} />
          </div>
          <Content className="conteudo">{children}</Content>
        </div>
      </Layout>
      <ToastContainer position="bottom-center" hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </Layout>
  );
};

export default MainTemplate;

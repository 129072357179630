import { CameraOutlined, PlusOutlined, SendOutlined, UpOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Flex, Modal, Typography, Upload, UploadFile } from "antd";
import { RcFile, UploadProps } from "antd/es/upload";
import { useEffect, useState } from "react";
import Flecha from "../../../components/flecha";
import { ConfiguracoesState, setAgilizouAnexos, setPaginaAgendamento, setPaginaAnterior, setSolicitarCarteirinha, setSolicitarGuia, setVoltar } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import { AnexoModel, filesModel } from "../../../models/perfil/anexoModel";
import { PerfilState, cadastrarAnexoCarteirinhaFetch, cadastrarAnexoGuiaFetch } from "../../../redux/slices/perfil/perfilSlice";
import { MeusAgendamentosModel } from "../../../models/perfil/meusAgendamentosModel";
import { AgendamentoState, resetConverio, resetLocal, resetProcessarRespostaQuestionario } from "../../../redux/slices/agendamento/agendamentoSlice";
import { ThumbsUp } from "@phosphor-icons/react";
import Spinner from "../../../components/spinner/spinner";
import CardOptions from "../finalizacao/components/cardOptions";
import CardTitle from "./components/cardTitle";
import { set } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const { Title, Text } = Typography;

const Anexos = () => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);
  const perfilState: PerfilState = useAppSelector((state) => state.perfil);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const agendamentoState: AgendamentoState = useAppSelector((state) => state.agendamento);

  //Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //Estados Locais
  const [imgCarteirinha64, setImgCarteirinha64] = useState<string>("");
  const [imgCarteirinha, setImgCarteirinha] = useState<UploadFile[]>([]);
  const [imgGuia64, setImgGuia64] = useState<any[]>([]);
  const [imgGuia, setImgGuia] = useState<UploadFile[]>([]);
  const [previewOpenCarteirinha, setPreviewOpenCarteirinha] = useState(false);
  const [previewImgCarteirinha, setPreviewImgCarteirinha] = useState("");
  const [previewOpenGuia, setPreviewOpenGuia] = useState(false);
  const [previewImgGuia, setPreviewImgGuia] = useState("");

  useEffect(() => {
    dispatch(setVoltar(true));
    if (!configuracoesState.vemDeMeusAgendamentos) {
      dispatch(setPaginaAnterior("finalizacao"));
    }
  }, []);

  const uploadButtonCarteirinha = (
    <div className="uploadButton">
      <CameraOutlined style={{ display: "block", fontSize: 30 }} rev={undefined} />
    </div>
  );

  const uploadButtonGuia = (
    <div className="uploadButton">
      <CameraOutlined style={{ display: "block", fontSize: 30 }} rev={undefined} />
    </div>
  );

  //Retorna string de base64 para ser enviada para API
  const toBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  //Função auxiliar para preview de imagem do upload
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleChangeCarteirinha: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    if (newFileList[0] !== undefined && (newFileList[0].type === "image/jpeg" || newFileList[0].type === "image/png" || newFileList[0].type === "application/pdf")) {
      if (newFileList[0].size !== undefined && newFileList[0].size / 1024 / 1024 < 10) {
        if (newFileList[0] !== undefined) {
          toBase64(newFileList[0].originFileObj, (imageUrlResponse: any) => {
            setImgCarteirinha64(imageUrlResponse);
          });
        }
        setImgCarteirinha(newFileList);
      } else {
        toast.warning("Tamanho máximo de arquivo é de 10MB");
      }
    } else {
      if (newFileList.length !== 0) {
        toast.warning("Formato de arquivo inválido");
      } else {
        setImgCarteirinha(newFileList);
      }
    }
  };

  const handlePreviewCarteirinha = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImgCarteirinha(file.url || (file.preview as string));
    setPreviewOpenCarteirinha(true);
  };

  const handleCancelCarteirinha = () => setPreviewOpenCarteirinha(false);

  const handleChangeGuia: UploadProps["onChange"] = async ({ fileList: newFileList }) => {
    if (newFileList[newFileList.length - 1] !== undefined && (newFileList[newFileList.length - 1].type === "image/jpeg" || newFileList[newFileList.length - 1].type === "image/png" || newFileList[newFileList.length - 1].type === "application/pdf")) {
      const lastSize = newFileList[newFileList.length - 1].size;
      if (lastSize !== undefined && lastSize / 1024 / 1024 < 10) {
        const newBase64Promises = newFileList.map(
          (file: UploadFile) =>
            new Promise((resolve) => {
              toBase64(file.originFileObj, (imageUrlResponse: any) => {
                resolve(imageUrlResponse);
              });
            })
        );
        const newBase64Strings = await Promise.all(newBase64Promises);
        setImgGuia(newFileList);
        setImgGuia64(newBase64Strings);
      } else {
        toast.warning("Tamanho máximo de arquivo é de 10MB");
      }
    } else {
      if (newFileList.length !== 0) {
        toast.warning("Formato de arquivo inválido");
      } else {
        setImgGuia(newFileList);
      }
    }
  };

  const handlePreviewGuia = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImgGuia(file.url || (file.preview as string));
    setPreviewOpenGuia(true);
  };

  const handleCancelGuia = () => setPreviewOpenGuia(false);

  const salvarAnexoCarteirinha = () => {
    var anexosCarteirinha: AnexoModel = {
      idAgendamento: null,
      idAtendimento: configuracoesState.idAtendimento,
      anexos: [
        {
          nomeArquivo: imgCarteirinha[0].name,
          extensao: imgCarteirinha[0].type?.split("/")[1],
          arquivo: imgCarteirinha64,
        },
      ],
      userId: perfilState.perfil.idUsuario,
      clientId: loginState.empresa,
    };
    dispatch(cadastrarAnexoCarteirinhaFetch(anexosCarteirinha));
  };

  const salvarAnexoGuia = () => {
    var anexos: filesModel[] = [];
    imgGuia.forEach((file: UploadFile, index) => {
      anexos.push({
        nomeArquivo: file.name,
        extensao: file.type?.split("/")[1],
        arquivo: imgGuia64[index],
      });
    });
    var anexosGuia: AnexoModel = {
      idAtendimento: configuracoesState.idAtendimento,
      idAgendamento: null,
      anexos: anexos,
      userId: perfilState.perfil.idUsuario,
      clientId: loginState.empresa,
    };
    dispatch(cadastrarAnexoGuiaFetch(anexosGuia));
  };

  const voltarPainel = () => {
    dispatch(resetLocal());
    dispatch(resetConverio());
    dispatch(setAgilizouAnexos(true));
    dispatch(resetProcessarRespostaQuestionario());
    navigate("/painel/" + loginState.empresa, { replace: true });
  };

  const voltar = () => {
    dispatch(setAgilizouAnexos(true));
    dispatch(setPaginaAgendamento("finalizacao"));
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <Flex vertical style={{ height: "100%" }} gap={10}>
          <Flex justify="flex-start" align="center" gap={10}>
            <Flecha />
            <Title className="tituloAnexos" level={4}>
              Agilize seu atendimento
            </Title>
          </Flex>
          <Flex>
            <Title className="subtituloAnexos" level={5}>
              Envie as fotos da sua carteirinha do seu plano de saúde e as requisições dos exames e reduza seu tempo de espera no dia do exame
            </Title>
          </Flex>
          <Flex vertical justify="center" align="center" gap={10} style={{ textAlign: "center" }} className="carteirinha">
            {configuracoesState.solicitarCarteirinha ? (
              <>
                <CardTitle
                  title="Carteirinha plano de saúde"
                  description="Clique no quadrado abaixo para selecionar ou tirar a foto de sua carteirinha, após isso clique no botão enviar"
                  backgroundColor={configuracoesState.solicitarCarteirinha ? "#909090" : loginState.configuracaoAparencia.corPrimaria}
                  hasCardButton={configuracoesState.solicitarCarteirinha}
                />
                <Flex justify="center" align="center" style={{ width: "100%" }}>
                  <Upload
                    action={"dummyRequest"}
                    onChange={handleChangeCarteirinha}
                    beforeUpload={() => {
                      return false;
                    }}
                    listType="picture-card"
                    fileList={imgCarteirinha}
                    onPreview={handlePreviewCarteirinha}
                  >
                    {imgCarteirinha.length >= 1 ? null : uploadButtonCarteirinha}
                  </Upload>
                </Flex>
                <Button
                  disabled={imgCarteirinha.length === 0}
                  icon={<SendOutlined rev={undefined} />}
                  onClick={salvarAnexoCarteirinha}
                  style={imgCarteirinha.length === 0 ? { backgroundColor: "#909090", color: "#ffffff", width: "100%" } : { backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }}
                >
                  ENVIAR
                </Button>
                <Modal open={previewOpenCarteirinha} title={"Carteirinha"} footer={null} onCancel={handleCancelCarteirinha} destroyOnClose>
                  <img alt="" style={{ width: "100%" }} src={previewImgCarteirinha} />
                </Modal>
              </>
            ) : (
              <></>
            )}
          </Flex>
          <CardTitle
            title="Requisição do exame"
            description="Clique no quadrado abaixo para selecionar ou tirar as fotos de sua requisições, após isso clique no botão enviar"
            backgroundColor={configuracoesState.solicitarGuia ? "#909090" : loginState.configuracaoAparencia.corPrimaria}
            hasCardButton={configuracoesState.solicitarGuia}
          />
          <Flex vertical justify="center" align="center" gap={10} style={{ textAlign: "center", width: "100%" }} className="guia">
            {configuracoesState.solicitarGuia ? (
              <>
                <Flex justify="center" align="center" style={{ width: "100%" }}>
                  <Upload
                    action={"dummyRequest"}
                    onChange={handleChangeGuia}
                    beforeUpload={() => {
                      return false;
                    }}
                    listType="picture-card"
                    fileList={imgGuia}
                    onPreview={handlePreviewGuia}
                  >
                    {uploadButtonGuia}
                  </Upload>
                </Flex>
                <Button
                  disabled={imgGuia.length === 0}
                  icon={<SendOutlined rev={undefined} />}
                  onClick={salvarAnexoGuia}
                  style={imgGuia.length === 0 ? { backgroundColor: "#909090", color: "#ffffff", width: "100%" } : { backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }}
                >
                  ENVIAR
                </Button>
                <Modal open={previewOpenGuia} title={"Requisições"} footer={null} onCancel={handleCancelGuia} destroyOnClose>
                  <img alt="" style={{ width: "100%" }} src={previewImgGuia} />
                </Modal>
              </>
            ) : (
              <></>
            )}
          </Flex>
          {!configuracoesState.vemDeMeusAgendamentos && !configuracoesState.solicitarCarteirinha && !configuracoesState.solicitarGuia ? (
            <Flex vertical justify="center" align="center" gap={10} style={{ width: "100%" }}>
              <Button onClick={voltar} style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }}>
                Voltar a página anterior
              </Button>
              <Button onClick={voltarPainel} style={{ backgroundColor: "#909090", color: "#ffffff", width: "100%" }}>
                Voltar ao início
              </Button>
            </Flex>
          ) : (
            <></>
          )}
        </Flex>
      )}
    </>
  );
};

export default Anexos;

import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Flex, Typography } from "antd";

type CardOptionsProps = {
  title: string;
  description: string;
  backgroundColor: string;
  hasCardButton: boolean;
};

const { Title, Text } = Typography;

const CardTitle = ({ title, description, backgroundColor, hasCardButton }: CardOptionsProps) => {
  const styles = {
    container: {
      cursor: "pointer",
      width: "100%",
      minHeight: "50px",
    },
    cardTitle: {
      backgroundColor: "#ffffff",
      padding: 6,
      flex: 1,
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
    },
    titulo: {
      fontSize: "15px",
      fontWeight: "bold",
      width: "100%",
      marginTop: "6px",
    },
    text: {
      fontSize: "12px",
    },
    cardButton: {
      width: "80px",
      textAlign: "center" as "center",
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
      backgroundColor: backgroundColor,
      flexShrink: 0,
      color: "#ffffff",
      paddingLeft: "6px",
      paddingRight: "6px",
    },
  };

  return (
    <Flex style={styles.container} justify="center" align="stretch">
      <Flex style={styles.cardButton} align="center" justify="center">
        {!hasCardButton ? <CheckCircleOutlined style={{ fontSize: "40px", color: "ffffff" }} rev={undefined} /> : <ExclamationCircleOutlined style={{ fontSize: "40px", color: "ffffff" }} rev={undefined} />}
      </Flex>
      <Flex style={styles.cardTitle} vertical>
        <Title style={styles.titulo} level={5}>
          {title}
        </Title>
        <Text style={styles.text}> {description}</Text>
      </Flex>
    </Flex>
  );
};

export default CardTitle;

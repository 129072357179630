import { createSlice } from "@reduxjs/toolkit";
import { AgendamentoSugestaoModel } from "../../../models/agendamento/agendamentoSugestaoModel";
import { AgendamentoResumoModel } from "../../../models/agendamento/agendamentoResumoModel";
import { TipoExameModel } from "../../../models/agendamento/tipoExameModel";
import { ConvenioModel, PlanoModel } from "../../../models/agendamento/convenioModel";
import { LocalModel } from "../../../models/agendamento/localModel";
import { ExecutanteModel } from "../../../models/agendamento/executanteModel";
import { ProcedimentoModel } from "../../../models/agendamento/procedimentoModel";
import { DataDisponivel } from "../../../models/agendamento/agendamentoDiasDisponiveisModel";
import { resetAll } from "../../actions/actions";
import { AgendamentoSugestaoHorarioModel } from "../../../models/agendamento/agendamentoSugestaoHorarioModel";
import { processarRespostaQuestionario } from "../../../services/agendamentoService";
import { RespostaQuestionarioModel } from "../../../models/agendamento/respostaQuestionarioModel";

export interface AgendamentoState {
  loadingAgendamento: boolean;
  loadingExecutante: boolean;
  horariosFiltrados: AgendamentoSugestaoHorarioModel[];
  buscarHorarios: AgendamentoSugestaoModel;
  loadingBuscarHorarios: boolean;
  loadingBuscarDiasDisponiveis: boolean;
  bloquearHorario: AgendamentoResumoModel;
  respostaQuestionario: RespostaQuestionarioModel;
  confirmarAgendamento: AgendamentoResumoModel;
  listTipoExame: TipoExameModel[];
  loadingTipoExame: boolean;
  listConvenio: ConvenioModel[];
  conveniosFiltrados: ConvenioModel[];
  planosFiltrados: PlanoModel[];
  listConvenioTodos: ConvenioModel[];
  listLocal: LocalModel[];
  loadingLocal: boolean;
  listExecutante: ExecutanteModel[];
  executantesFiltrados: ExecutanteModel[];
  listProcedimento: ProcedimentoModel[];
  procedimentosFiltrados: ProcedimentoModel[];
  mensagemProcedimento: string;
  modalDiasDisponiveis: boolean;
  segmentedValue: string;
  primeiroDia: string;
  listaDiasDisponiveis: DataDisponivel[];
}

const initialState: AgendamentoState = {
  loadingAgendamento: false,
  loadingExecutante: false,
  buscarHorarios: {
    diaBuscado: new Date(),
    horarios: [],
    diasDisponiveis: {
      diasDisponiveis: [],
      inicioDataBusca: null,
      fimDataBusca: null,
    },
  } as AgendamentoSugestaoModel,
  horariosFiltrados: [],
  loadingBuscarHorarios: false,
  loadingBuscarDiasDisponiveis: false,
  bloquearHorario: {} as AgendamentoResumoModel,
  respostaQuestionario: { impeditivo: false, mensagem: null } as RespostaQuestionarioModel,
  confirmarAgendamento: {} as AgendamentoResumoModel,
  listTipoExame: [],
  loadingTipoExame: false,
  listConvenio: [],
  conveniosFiltrados: [],
  planosFiltrados: [],
  listConvenioTodos: [],
  listLocal: [],
  loadingLocal: false,
  listExecutante: [],
  executantesFiltrados: [],
  listProcedimento: [],
  procedimentosFiltrados: [],
  mensagemProcedimento: "",
  modalDiasDisponiveis: false,
  segmentedValue: "",
  primeiroDia: "",
  listaDiasDisponiveis: [],
};

export const agendamentoSlice = createSlice({
  name: "agendamento",
  initialState,
  reducers: {
    setLoadingAgendamento: (state, action) => {
      state.loadingAgendamento = action.payload;
    },
    setLoadingExecutante: (state, action) => {
      state.loadingExecutante = action.payload;
    },
    buscarHorariosFetch: (state, action) => {
      state.loadingBuscarHorarios = true;
    },
    setBuscarHorarios: (state, action) => {
      state.buscarHorarios.diaBuscado = action.payload.diaBuscado;
      state.buscarHorarios.horarios = action.payload.horarios;
      state.loadingBuscarHorarios = false;
    },
    setHorariosFiltrados: (state, action) => {
      state.horariosFiltrados = action.payload;
    },
    buscaDiasDisponiveisFetch: (state, action) => {
      state.loadingBuscarDiasDisponiveis = true;
    },
    setBuscaDiasDisponiveis: (state, action) => {
      state.buscarHorarios.diasDisponiveis.diasDisponiveis.push(...action.payload.diasDisponiveis);
      state.listaDiasDisponiveis = action.payload.diasDisponiveis;
      state.buscarHorarios.diasDisponiveis.fimDataBusca = action.payload.fimDataBusca;
      state.buscarHorarios.diasDisponiveis.inicioDataBusca = action.payload.inicioDataBusca;
      state.loadingBuscarDiasDisponiveis = false;
    },
    resetBuscaDiasDisponiveis: (state) => {
      state.buscarHorarios.diasDisponiveis.diasDisponiveis = [];
      state.listaDiasDisponiveis = [];
      state.buscarHorarios.diasDisponiveis.fimDataBusca = null;
      state.buscarHorarios.diasDisponiveis.inicioDataBusca = null;
    },
    setLoadingBuscarDiasDisponiveis: (state, action) => {
      state.loadingBuscarDiasDisponiveis = action.payload;
    },
    bloquearHorarioFetch: (state, action) => {},
    setBloquearHorario: (state, action) => {
      state.bloquearHorario = action.payload;
    },
    processarRespostaQuestionarioFetch: (state, action) => {},
    setProcessarRespostaQuestionario: (state, action) => {
      state.respostaQuestionario = action.payload;
    },
    resetProcessarRespostaQuestionario: (state) => {
      state.respostaQuestionario = {
        impeditivo: false,
        mensagem: null,
      } as RespostaQuestionarioModel;
    },
    confirmarAgendamentoFetch: () => {},
    setConfirmarAgendamento: (state, action) => {
      state.confirmarAgendamento = action.payload;
    },
    tipoExameFetch: (state, action) => {
      state.loadingTipoExame = true;
    },
    setTipoExame: (state, action) => {
      state.listTipoExame = action.payload;
      state.loadingTipoExame = false;
    },
    resetTipoExame: (state) => {
      state.listTipoExame = [];
    },
    convenioFetch: (state, action) => {},
    setConvenio: (state, action) => {
      state.listConvenio = action.payload;
    },
    resetConverio: (state) => {
      state.listConvenio = [];
    },
    setConveniosFiltrados: (state, action) => {
      state.conveniosFiltrados = action.payload;
    },
    setPlanosFiltrados: (state, action) => {
      state.planosFiltrados = action.payload;
    },
    convenioTodosFetch: () => {},
    setConvenioTodos: (state, action) => {
      state.listConvenioTodos = action.payload;
    },
    localFetch: (state) => {
      state.loadingLocal = true;
    },
    setLocal: (state, action) => {
      state.listLocal = action.payload;
      state.loadingLocal = false;
    },
    resetLocal: (state) => {
      state.listLocal = [];
    },
    executanteFetch: (state, action) => {},
    setExecutante: (state, action) => {
      state.listExecutante = action.payload;
    },
    setExecutantesFiltrados: (state, action) => {
      state.executantesFiltrados = action.payload;
    },
    procedimentoFetch: (state, action) => {},
    setProcedimento: (state, action) => {
      state.listProcedimento = action.payload;
    },
    resetProcedimento: (state) => {
      state.listProcedimento = [];
    },
    setProcedimentosFiltrados: (state, action) => {
      state.procedimentosFiltrados = action.payload;
    },
    setMensagemProcedimento: (state, action) => {
      state.mensagemProcedimento = action.payload;
    },
    setModalDiasDisponiveis: (state, action) => {
      state.modalDiasDisponiveis = action.payload;
    },
    setSegmentedValue: (state, action) => {
      state.segmentedValue = action.payload;
    },
    setPrimeiroDia: (state, action) => {
      state.primeiroDia = action.payload;
    },
    setListaDiasDisponiveis: (state, action) => {
      state.listaDiasDisponiveis = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Resetar estado
    builder.addCase(resetAll, () => initialState);
  },
});

export const {
  setLoadingAgendamento,
  setLoadingExecutante,
  buscarHorariosFetch,
  setHorariosFiltrados,
  setBuscarHorarios,
  buscaDiasDisponiveisFetch,
  setBuscaDiasDisponiveis,
  setLoadingBuscarDiasDisponiveis,
  resetBuscaDiasDisponiveis,
  bloquearHorarioFetch,
  setBloquearHorario,
  processarRespostaQuestionarioFetch,
  setProcessarRespostaQuestionario,
  resetProcessarRespostaQuestionario,
  confirmarAgendamentoFetch,
  setConfirmarAgendamento,
  tipoExameFetch,
  setTipoExame,
  resetTipoExame,
  convenioFetch,
  setConvenio,
  resetConverio,
  setConveniosFiltrados,
  setPlanosFiltrados,
  convenioTodosFetch,
  setConvenioTodos,
  localFetch,
  setLocal,
  resetLocal,
  executanteFetch,
  setExecutante,
  setExecutantesFiltrados,
  procedimentoFetch,
  setProcedimento,
  resetProcedimento,
  setProcedimentosFiltrados,
  setMensagemProcedimento,
  setModalDiasDisponiveis,
  setSegmentedValue,
  setPrimeiroDia,
  setListaDiasDisponiveis,
} = agendamentoSlice.actions;

export default agendamentoSlice.reducer;

import "./style/index.scss";
import { LoginState } from "../../redux/slices/account/loginSlice";
import { useAppSelector } from "../../util/hooks";
import { Button } from "antd";
import { RightOutlined } from "@ant-design/icons";

const BotaoPrimarioComponent = (props: any) => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);

  return (
    <Button style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria }} className="botaoPrimario" htmlType="submit">
      {props.title}
    </Button>
  );
};

export default BotaoPrimarioComponent;

import "./style/index.scss";
import { useEffect, useState } from "react";
import { AgendamentoState, buscarHorariosFetch } from "../../../redux/slices/agendamento/agendamentoSlice";
import { ConfiguracoesState, setPaginaAgendamento, setPaginaAnterior, setVoltar } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import { Button, Col, Divider, Flex, FloatButton, Form, Row, Space, Tooltip, Typography } from "antd";
import StepsComponent from "../../../components/steps";
import Flecha from "../../../components/flecha";
import ListaExamesComponent from "../../../components/listaExames";
import ScrollContainer from "react-indiana-drag-scroll";
import IconeCarrinhoComponent from "../../../components/carrinho";
import { CloseCircleOutlined, DeleteOutlined, PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import Spinner from "../../../components/spinner/spinner";
import { CarrinhoState, limparCarrinhoFetch } from "../../../redux/slices/carrinho/carrinhoSlice";

const { Title, Text } = Typography;

const BuscarHorarios = () => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);
  const agendamentoState: AgendamentoState = useAppSelector((state) => state.agendamento);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const carrinhoState: CarrinhoState = useAppSelector((state) => state.carrinho);

  //Hooks
  const dispatch = useAppDispatch();

  //Estados Locais
  const [buscouHorarios, setBuscouHorarios] = useState(false);

  useEffect(() => {
    dispatch(setVoltar(true));
    dispatch(setPaginaAnterior("exames"));
    setBuscouHorarios(false);
  }, []);

  const buscarHorarios = async () => {
    setBuscouHorarios(true);
    dispatch(buscarHorariosFetch({ buscarAParirDe: null, buscarApenasDia: null }));
  };

  const goExames = () => {
    dispatch(setPaginaAgendamento("exames"));
  };

  const limparCarrinho = () => {
    dispatch(limparCarrinhoFetch());
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <>{buscouHorarios ? <Spinner texto={"Buscando horários"} /> : <Spinner />}</>
      ) : (
        <Flex vertical style={{ height: "100%" }} gap={10} flex={"1 1 auto"}>
          <StepsComponent current={1} />
          <Flex justify="flex-start" align="center" gap={10}>
            <Flecha />
            <Title className="titulo" level={4}>
              Exames a realizar
            </Title>
            <IconeCarrinhoComponent />
          </Flex>
          {carrinhoState.carrinho.procedimentos === null ? (
            <></>
          ) : (
            <>
              {carrinhoState.carrinho.valorTotal !== null ? (
                <Flex justify="space-between" style={{ width: "100%" }} align="center">
                  <Text style={{ fontWeight: "bold", fontSize: "12px" }}>Total: {carrinhoState.carrinho.valorTotal}</Text>
                  <Button
                    onClick={limparCarrinho}
                    style={{ backgroundColor: "transparent", color: "#000000", border: "none", boxShadow: "none", padding: 0, paddingRight: 10, marginTop: -4 }}
                    disabled={carrinhoState.carrinho.procedimentos === null || carrinhoState.loading}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: "12px", textDecoration: "underline" }}>Limpar Exames</Text>
                    <DeleteOutlined rev={undefined} style={{ marginLeft: "5px", fontWeight: "bold" }} />
                  </Button>
                </Flex>
              ) : (
                <Flex justify="flex-end" style={{ width: "100%" }} align="center">
                  <Button
                    onClick={limparCarrinho}
                    style={{ backgroundColor: "transparent", color: "#000000", border: "none", boxShadow: "none", padding: 0, paddingRight: 10, marginTop: -4 }}
                    disabled={carrinhoState.carrinho.procedimentos === null || carrinhoState.loading}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: "12px", textDecoration: "underline" }}>Limpar Exames</Text>
                    <DeleteOutlined rev={undefined} style={{ marginLeft: "5px", fontWeight: "bold" }} />
                  </Button>
                </Flex>
              )}
            </>
          )}
          <Flex vertical style={{ marginBottom: "90px" }}>
            <ListaExamesComponent podeRemover={true} />
          </Flex>
          <Button onClick={goExames} className="botaoAdicionarHorario" style={{ backgroundColor: "#909090" }}>
            ADICIONAR OUTRO EXAME
          </Button>
          <div className="divisor" />
          <Button onClick={buscarHorarios} className="botaoBuscarHorarios" style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria }} disabled={carrinhoState.carrinho.procedimentos === null || carrinhoState.loading}>
            AVANÇAR
          </Button>
        </Flex>
      )}
    </>
  );
};

export default BuscarHorarios;

import "./style/index.scss";
import { useEffect, useState } from "react";
import { AgendamentoState, buscarHorariosFetch, confirmarAgendamentoFetch, resetProcessarRespostaQuestionario } from "../../../redux/slices/agendamento/agendamentoSlice";
import { ConfiguracoesState, setPaginaAgendamento, setPaginaAnterior, setVoltar } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import BotaoPrimario from "../../../components/botaoPrimario";
import Spinner from "../../../components/spinner/spinner";
import { Button, Card, Col, Divider, Flex, Form, Modal, Row, Space, Tooltip, Typography, Image } from "antd";
import StepsComponent from "../../../components/steps";
import Flecha from "../../../components/flecha";
import ScrollContainer from "react-indiana-drag-scroll";
import ListaExamesComponent from "../../../components/listaExames";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import { PerfilState } from "../../../redux/slices/perfil/perfilSlice";
import { CarrinhoState } from "../../../redux/slices/carrinho/carrinhoSlice";
import { CalendarOutlined, ClockCircleOutlined, GlobalOutlined, UserOutlined } from "@ant-design/icons";
import { MapPin, Hourglass } from "@phosphor-icons/react";
import Meta from "antd/es/card/Meta";
import IconeCarrinhoComponent from "../../../components/carrinho";
import moment from "moment";
import imgNaoEncontrado from "../../../util/assets/nao_encontrado.png";
import agendConf from "../../../util/assets/agendConf.png";
import { tempoBloqueio } from "../../../services/agendamentoService";

const { Title, Text } = Typography;

const Conclusao = () => {
  //Estados Globais
  const agendamentoState: AgendamentoState = useAppSelector((state) => state.agendamento);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const loginState: LoginState = useAppSelector((state) => state.login);
  const perfilState: PerfilState = useAppSelector((state) => state.perfil);
  const carrinhoState: CarrinhoState = useAppSelector((state) => state.carrinho);

  //Hooks
  const dispatch = useAppDispatch();

  //Estados Locais
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [timerMinutes, setTimerMinutes] = useState<number>(0);
  const [timerSeconds, setTimerSeconds] = useState<number>(0);
  const [openModalTempo, setOpenModalTempo] = useState<boolean>(false);
  const [loadingTimer, setLoadingTimer] = useState<boolean>(true);
  const [tempo, setTempo] = useState<number>(0);

  let interval: any;

  const startTimer = (tempo: number) => {
    let countDownDate: number;
    countDownDate = new Date().getTime() + tempo * 1000;

    interval = setInterval(() => {
      const now = new Date().getTime();

      const distance = countDownDate - now;

      const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
      const seconds = Math.floor((distance % (60 * 1000)) / 1000);

      if (distance < 0) {
        clearInterval(interval);
        setTimerMinutes(0);
        setTimerSeconds(0);
        setOpenModalTempo(true);
      } else {
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
      if (distance > 0) {
        setLoadingTimer(false);
      }
    }, 1000); // update every second
  };

  const fetchTimer = async () => {
    const retorno = await tempoBloqueio(agendamentoState.bloquearHorario.idAgendamento);
    startTimer(retorno.data.data);
  };

  useEffect(() => {
    dispatch(setVoltar(true));
    dispatch(setPaginaAnterior("horarios"));
    dispatch(resetProcessarRespostaQuestionario());
    fetchTimer();
    let intervalId = setInterval(() => {
      setTempo((prevTempo) => {
        if (prevTempo >= 500) {
          clearInterval(intervalId);
          return prevTempo;
        }
        return prevTempo + 1;
      });
    }, 1);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const confirmarAgendamento = () => {
    setOpenModal(false);
    dispatch(confirmarAgendamentoFetch());
  };

  const buscarHorarios = () => {
    setOpenModal(false);
    dispatch(buscarHorariosFetch({ buscarAParirDe: null, buscarApenasDia: null }));
  };

  return (
    <>
      {configuracoesState.loadingSpinner || loadingTimer ? (
        <Spinner />
      ) : (
        <Flex vertical style={{ height: "100%" }} gap={10} flex={"1 1 auto"}>
          <StepsComponent current={3} />
          <Flex justify="flex-start" align="center" gap={10}>
            <Flecha />
            <Title className="titulo" level={4}>
              Revisar agendamento
            </Title>
            <IconeCarrinhoComponent />
          </Flex>
          <Flex justify="center" align="center" vertical>
            <Text>Você deve concluir seu agendamento em:</Text>
            <Text style={{ fontSize: "25px", textAlign: "center" }}>
              {timerMinutes.toString().padStart(2, "0")}:{timerSeconds.toString().padStart(2, "0")}
            </Text>
          </Flex>
          <Form layout="vertical">
            <Form.Item label="Informações">
              <Card size="small" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: "100%", height: "85px", textAlign: "center", padding: "15px" }}>
                <Flex vertical justify="center" style={{ width: "100%" }} gap={3}>
                  <Flex align="flex-start" justify="flex-start" style={{ width: "100%" }} vertical gap={3}>
                    <Flex justify="flex-start" align="center" style={{ width: "100%" }} gap={5}>
                      <Tooltip title="Nome paciente">
                        <UserOutlined rev={undefined} />
                      </Tooltip>
                      <Text ellipsis={{ tooltip: agendamentoState.bloquearHorario.nomePaciente }} style={{ width: "100%", textAlign: "start" }}>
                        {agendamentoState.bloquearHorario.nomePaciente}
                      </Text>
                    </Flex>
                    <Flex justify="flex-start" align="center" gap={5} style={{ width: "100%" }}>
                      <Tooltip title="Local">
                        <MapPin />
                      </Tooltip>
                      <Text>{agendamentoState.listLocal.find((a) => a.idLocalExterno === carrinhoState.montagemCarrinho.idLocal)?.denominacao}</Text>
                    </Flex>
                  </Flex>
                  <Flex gap={10} align="center" justify="flex-start" style={{ width: "100%" }}>
                    <Flex justify="flex-start" align="center" gap={5}>
                      <Tooltip title="Data de marcação">
                        <CalendarOutlined rev={undefined} />
                      </Tooltip>
                      <Text>{moment(agendamentoState.bloquearHorario.dataHoraInicial).format("DD/MM/YYYY")}</Text>
                    </Flex>
                    <Flex justify="flex-start" align="center" gap={5}>
                      <Tooltip title="Hora marcada">
                        <ClockCircleOutlined rev={undefined} />
                      </Tooltip>
                      <Text>{moment(agendamentoState.bloquearHorario.dataHoraInicial).format("HH:mm")}</Text>
                    </Flex>
                    <Flex justify="flex-start" align="center">
                      <Tooltip title="Duração estimada">
                        <Hourglass />
                      </Tooltip>
                      <Text>{agendamentoState.bloquearHorario.duracaoEstimada + "min"}</Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            </Form.Item>
          </Form>
          <Flex justify="space-between" style={{ marginBottom: "8px" }}>
            <Text style={{ fontSize: "14px" }}>{"Lista de exames" + "(" + carrinhoState.carrinho.procedimentos?.length + ")"}</Text>
            <Text style={carrinhoState.carrinho.valorTotal !== null ? { fontWeight: "bold" } : { visibility: "hidden" }}>Total: {carrinhoState.carrinho.valorTotal}</Text>
          </Flex>
          <Flex vertical style={{ marginBottom: "30px" }}>
            <ListaExamesComponent podeRemover={false} />
          </Flex>
          <Button onClick={() => setOpenModal(true)} className="botaoConcluir" style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria }}>
            CONCLUIR AGENDAMENTO
          </Button>
        </Flex>
      )}
      <Modal width={320} centered open={openModal} footer={null} destroyOnClose closeIcon={false}>
        <Flex vertical justify="center" align="center" gap={10}>
          <Image preview={false} src={imgNaoEncontrado} />
          <Text>No dia do seu exame, não se esqueça de trazer as requisições médicas originais dos exames.</Text>
          <Button style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }} onClick={confirmarAgendamento}>
            ESTOU CIENTE
          </Button>
        </Flex>
      </Modal>
      <Modal width={320} centered open={openModalTempo} footer={null} destroyOnClose closeIcon={false}>
        <Flex vertical justify="center" align="center" gap={10}>
          <Image preview={false} src={agendConf} />
          <Text style={{ fontSize: "13px", textAlign: "left" }}>O tempo para concluir o agendamento expirou. Por favor, clique aqui para buscar novamente os horários disponíveis</Text>
          <Button style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }} onClick={buscarHorarios}>
            BUSCAR HORÁRIOS
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

export default Conclusao;

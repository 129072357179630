import "./style/index.scss";
import { AgendamentoState, convenioFetch, localFetch, resetTipoExame } from "../../../redux/slices/agendamento/agendamentoSlice";
import { ConfiguracoesState, setPaginaAgendamento, setPaginaAnterior, setVoltar } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import { Button, Card, Col, Flex, Modal, Row, Typography } from "antd";
import StepsComponent from "../../../components/steps";
import Flecha from "../../../components/flecha";
import { LocalModel } from "../../../models/agendamento/localModel";
import { CarrinhoState, limparCarrinhoLocalFetch, resetMontagemCarrinho, setMontagemCarrinho } from "../../../redux/slices/carrinho/carrinhoSlice";
import { useEffect, useState } from "react";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import Spinner from "../../../components/spinner/spinner";
import { EnvironmentOutlined, RightOutlined } from "@ant-design/icons";

const { Title, Text, Link } = Typography;

const Unidade = () => {
  //Estados Globais
  const agendamentoState: AgendamentoState = useAppSelector((state) => state.agendamento);
  const carrinhoState: CarrinhoState = useAppSelector((state) => state.carrinho);
  const loginState: LoginState = useAppSelector((state) => state.login);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);

  //Hooks
  const dispatch = useAppDispatch();

  //Estados Locais
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [idLocal, setIdLocal] = useState<number>(0);

  useEffect(() => {
    dispatch(setPaginaAnterior("paciente"));
    dispatch(resetMontagemCarrinho());
    dispatch(resetTipoExame());
    dispatch(setVoltar(true));
    dispatch(localFetch());
  }, []);

  const onChangeLocal = async (value: number) => {
    setIdLocal(value);
    var localSelecionado = agendamentoState.listLocal.filter((a: LocalModel) => a.idLocalExterno === value)[0];
    if (carrinhoState.carrinho.procedimentos !== null && carrinhoState.carrinho.procedimentos.length > 0 && carrinhoState.carrinho.local !== localSelecionado.denominacao) {
      setOpenModal(true);
    } else {
      var montagemCarrinho = { ...carrinhoState.montagemCarrinho };
      montagemCarrinho.idLocal = value;
      dispatch(setMontagemCarrinho(montagemCarrinho));
      dispatch(setPaginaAgendamento("convenios"));
      dispatch(convenioFetch(value));
    }
  };

  const handleCancelModal = () => {
    setOpenModal(false);
  };

  const limparCarrinho = async () => {
    setOpenModal(false);
    dispatch(limparCarrinhoLocalFetch(idLocal));
  };

  const comoChegar = (value: any) => {
    window.open(value, "_blank");
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <Flex vertical style={{ height: "100%" }} gap={14}>
          <Flex>
            <StepsComponent current={0} />
          </Flex>
          <Row gutter={4}>
            <Col span={2} className="flechaCol">
              <Flecha />
            </Col>
            <Col span={20}>
              <Flex vertical>
                <Title className="titulo" level={4}>
                  Escolha da unidade
                </Title>
                <Title className="subtitulo" level={5}></Title>
              </Flex>
            </Col>
          </Row>
          {agendamentoState.listLocal.map((a: LocalModel, index) => (
            <Card
              key={index}
              className="cardUnidade"
              onClick={(e) => {
                e.stopPropagation();
                onChangeLocal(a.idLocalExterno);
              }}
            >
              <Flex style={{ width: "100%" }} gap={16} justify="space-between">
                <Flex vertical gap={5} justify="flex-start">
                  <Text style={{ wordBreak: "break-all", fontSize: "13px" }}>{a.denominacao}</Text>
                  <Text style={{ wordBreak: "break-all", fontSize: "12px", color: "#606060", marginTop: "-6px" }}>{a.endereco}</Text>
                  <Link
                    className="comoChegar"
                    onClick={(e) => {
                      e.stopPropagation();
                      comoChegar(a.comoChegar);
                    }}
                  >
                    <EnvironmentOutlined rev={undefined} style={{ marginRight: "3px" }} />
                    Ver Mapa
                  </Link>
                </Flex>
                <Flex align="center" justify="flex-end">
                  <RightOutlined rev={undefined} style={{ color: "#909090", fontSize: "12px" }} />
                </Flex>
              </Flex>
            </Card>
          ))}
          <Modal open={openModal} title={""} footer={null} onCancel={handleCancelModal} destroyOnClose closeIcon={false}>
            <Flex vertical style={{ width: "100%" }} gap={14}>
              <Text style={{ fontSize: "14px" }}>Seu carrinho atualmente contém exames da unidade {carrinhoState.carrinho.local}. Ao selecionar uma nova unidade, o carrinho será esvaziado. Deseja prosseguir?</Text>
              <Flex justify="center" align="center" gap={3}>
                <Button style={{ backgroundColor: "#909090", color: "#ffffff" }} onClick={handleCancelModal}>
                  Não
                </Button>
                <Button style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff" }} onClick={limparCarrinho}>
                  Sim
                </Button>
              </Flex>
            </Flex>
          </Modal>
        </Flex>
      )}
    </>
  );
};

export default Unidade;

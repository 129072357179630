import "./style/index.scss";
import { useEffect, useState } from "react";
import { AgendamentoState, resetConverio, resetLocal, resetProcessarRespostaQuestionario } from "../../../redux/slices/agendamento/agendamentoSlice";
import {
  ConfiguracoesState,
  resetAgilizouAnexos,
  setIdAtendimento,
  setPaginaAgendamento,
  setPaginaAnterior,
  setSolicitarCarteirinha,
  setSolicitarGuia,
  setVemdeMeusAgendamentos,
  setVoltar,
} from "../../../redux/slices/configuracoes/configuracoesSlice";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import BotaoPrimario from "../../../components/botaoPrimario";
import Spinner from "../../../components/spinner/spinner";
import { Col, Flex, Form, Row, Space, Typography, Image, Button, Modal, Upload, UploadProps, UploadFile } from "antd";
import Flecha from "../../../components/flecha";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import { useNavigate } from "react-router-dom";
import StepsComponent from "../../../components/steps";
import agenConf from "../../../util/assets/agendConf.png";
import { CheckCircleOutlined, CheckOutlined, PlusOutlined } from "@ant-design/icons";
import CardOptions from "./components/cardOptions";
import { RcFile } from "antd/es/upload";
import { preparoAgendamentoFetch } from "../../../redux/slices/perfil/perfilSlice";

const { Title, Text } = Typography;

const Finalizacao = () => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);
  const agendamentoState: AgendamentoState = useAppSelector((state) => state.agendamento);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);

  //Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //Estados Locais

  useEffect(() => {
    dispatch(setVoltar(false));
  }, []);

  const voltarPainel = () => {
    dispatch(resetLocal());
    dispatch(resetConverio());
    dispatch(resetAgilizouAnexos());
    dispatch(resetProcessarRespostaQuestionario());
    navigate("/painel/" + loginState.empresa, { replace: true });
  };

  const goPreparo = () => {
    dispatch(setPaginaAgendamento("preparacao"));
  };

  const enviarFoto = () => {
    dispatch(setVemdeMeusAgendamentos(false));
    dispatch(setSolicitarCarteirinha(agendamentoState.confirmarAgendamento.solicitarCarteirinha));
    dispatch(setSolicitarGuia(true));
    dispatch(setIdAtendimento(agendamentoState.confirmarAgendamento.idAtendimento));
    dispatch(setPaginaAgendamento("anexos"));
  };

  return (
    <>
      <Flex vertical style={{ height: "100%" }} gap={10}>
        <Flex justify="center" align="center" gap={10} vertical>
          <CheckCircleOutlined style={{ fontSize: "80px", color: loginState.configuracaoAparencia.corPrimaria }} rev={undefined} />
          <Title className="titulo" style={{ textAlign: "center" }} level={4}>
            Agendamento concluído com sucesso.
          </Title>
        </Flex>
        <Flex vertical flex={"1 1 auto"} gap={16}>
          {agendamentoState.confirmarAgendamento.possuiPreparo && agendamentoState.confirmarAgendamento.pdfPreparo !== null ? (
            <CardOptions
              title="Preparação para o Exame"
              description="Para garantir o sucesso e a segurança dos seus exames, é essencial que você leia as seguintes orientações"
              buttonTitle="Ver Preparo do Exame"
              backgroundColor={"#909090"}
              onClick={goPreparo}
            />
          ) : (
            <></>
          )}
          {!configuracoesState.agilizouAnexos && !agendamentoState.bloquearHorario.obrigatorioSolicitarDocumentos ? (
            <CardOptions
              title="Agilize seu atendimento"
              description="Envie a foto da sua requisição de seu médico e da sua carteirinha do convênio e reduza o seu tempo de espera no dia do exame"
              buttonTitle="Enviar foto"
              backgroundColor="#909090"
              onClick={enviarFoto}
            />
          ) : (
            <></>
          )}
        </Flex>
        <Flex>
          <Button onClick={voltarPainel} style={{ width: "100%", backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff" }}>
            VOLTAR PARA PÁGINA INICIAL
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default Finalizacao;

import "./style/index.scss";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import { useNavigate } from "react-router-dom";
import { ConfiguracoesState } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import { AccountState, criarUsuarioFetch, recuperarSenhaFetch } from "../../../redux/slices/account/accountSlice";
import { RecuperarSenhaModel } from "../../../models/account/recuperarSenhaModel";
import { CriarUsuarioModel } from "../../../models/account/criarUsuarioModel";
import { UsuarioRegisterModel } from "../../../models/account/usuarioRegisterModel";
import { Button, Col, Flex, Form, Input, Modal, Row, Typography, Image, Tooltip } from "antd";
import Spinner from "../../../components/spinner/spinner";
import { EyeInvisibleOutlined, EyeTwoTone, InfoCircleOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { InformacoesConfirmacaoPacienteModel } from "../../../models/account/informacoesConfirmacaoPacienteModel";
import imgConfirmacao from "../../../util/assets/confirmacao_id.png";
import { resetAll, resetPersistedStates } from "../../../redux/actions/actions";
import { MaskedInput } from "antd-mask-input";
import { formatTelefone } from "../../../util/formats";

const { Title, Text } = Typography;

interface FormValues {
  email: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
}

const CadastrarAtualizarUsuario = () => {
  //Estados globais
  const accountState: AccountState = useAppSelector((state) => state.account);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const loginState: LoginState = useAppSelector((state) => state.login);

  //Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //Estados Locais
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    form.setFieldValue("email", accountState.paciente.email);
    form.setFieldValue(
      "phoneNumber",
      accountState.paciente.telefones !== null
        ? formatTelefone(
            accountState.paciente.telefones.length !== 0
              ? accountState.paciente.telefones[0].numero.length === 10
                ? accountState.paciente.telefones![0].numero.slice(0, 2) + "9" + accountState.paciente.telefones![0].numero.slice(2)
                : accountState.paciente.telefones[0].numero
              : configuracoesState.emailOrCell
          )
        : ""
    );
  }, []);

  const submit = async (values: FormValues) => {
    if (values.password !== values.confirmPassword) {
      toast.warning("As senhas não conferem");
    } else {
      if (configuracoesState.esqueciSenha) {
        var args: RecuperarSenhaModel = {
          password: values.password,
          confirmPassword: values.confirmPassword,
          clientId: loginState.empresa,
          userId: accountState.paciente.idUsuario,
          otpSessionId: accountState.OtpSessionId,
        };
        dispatch(recuperarSenhaFetch(args));
      } else {
        var usuarioRegister: UsuarioRegisterModel = {
          password: values.password,
          confirmPassword: values.confirmPassword,
          roleName: "user",
          email: values.email,
          phoneNumber: values.phoneNumber.replace(/[^0-9]/g, ""),
          clientId: loginState.empresa,
        };
        var InformacoesPaciente: InformacoesConfirmacaoPacienteModel = {
          idUsuario: accountState.paciente.idUsuario,
          idPaciente: accountState.paciente.idPaciente,
          telefones: configuracoesState.emailOrCell.includes("@") ? null : [{ numero: configuracoesState.emailOrCell }],
          email: configuracoesState.emailOrCell.includes("@") ? configuracoesState.emailOrCell : null,
          nomePaciente: accountState.paciente.nomePaciente,
          idUnidade: accountState.paciente.idUnidade,
          pacienteDuplicado: accountState.paciente.pacienteDuplicado,
          busca: accountState.InformacoesBusca,
        };
        var argsCriar: CriarUsuarioModel = {
          usuarioRegister: usuarioRegister,
          idPacienteExistente: accountState.paciente.idPaciente,
          novoPaciente: null,
          informacoesConfirmacaoPaciente: InformacoesPaciente,
        };
        dispatch(criarUsuarioFetch(argsCriar));
      }
    }
  };

  const irParaPainel = () => {
    navigate("/painel/" + loginState.empresa, { replace: true });
  };

  const voltarLogin = () => {
    dispatch(resetAll());
    dispatch(resetPersistedStates());
    navigate("/" + loginState.empresa, { replace: true });
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <>
          <Flex vertical style={{ height: "100%" }} gap={10}>
            <Title style={{ textAlign: "center" }} level={4}>
              CONFIRMAÇÃO DE IDENTIDADE
            </Title>
            <Text style={{ color: "#101010", textAlign: "center", marginTop: "-15px" }}>{configuracoesState.esqueciSenha ? "Digite uma nova senha diferente da anterior." : "Cadastre uma nova senha para ter acesso a plataforma"}</Text>
            <Form layout="vertical" onFinish={submit} form={form}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Digite o seu e-mail",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                  >
                    <Input type="text" disabled={configuracoesState.esqueciSenha} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Telefone"
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: "Digite corretamente seu telefone",
                        pattern: /^\([1-9]{2}\)(?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/,
                        validateTrigger: "onSubmit",
                      },
                    ]}
                  >
                    <MaskedInput className="masked" mask="(00)00000-0000" inputMode="numeric" style={{ width: "100%" }}></MaskedInput>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <>
                        Senha
                        <Tooltip title="A senha deve conter no mínimo 6 caracteres, incluindo pelo menos uma letra maiúscula e um número.">
                          <InfoCircleOutlined style={{ marginLeft: "3px" }} rev={undefined} />
                        </Tooltip>
                      </>
                    }
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Digite a sua senha",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Digite a senha" iconRender={(visible) => (visible ? <EyeTwoTone rev={undefined} /> : <EyeInvisibleOutlined rev={undefined} />)} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Confirme sua Senha"
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: "Digite a sua senha",
                        validateTrigger: "onSubmit",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Digite a senha" iconRender={(visible) => (visible ? <EyeTwoTone rev={undefined} /> : <EyeInvisibleOutlined rev={undefined} />)} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Button htmlType="submit" style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }}>
                    SALVAR
                  </Button>
                </Col>
              </Row>
            </Form>
          </Flex>
          <Modal width={320} centered open={accountState.modal} footer={null} destroyOnClose closeIcon={false}>
            <Flex vertical justify="center" align="center" gap={10}>
              <Image preview={false} src={imgConfirmacao} />
              <Text style={{ textAlign: "center", color: "#101010" }}>{configuracoesState.esqueciSenha ? "Sua senha foi alterada com sucesso" : "Seu cadastro foi realizado com sucesso"}</Text>
              <Button style={configuracoesState.esqueciSenha ? { display: "none", width: "100%" } : { backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }} onClick={irParaPainel}>
                PROSSEGUIR
              </Button>
              <Button style={{ backgroundColor: "#909090", color: "#ffffff", width: "100%" }} onClick={voltarLogin}>
                VOLTAR PARA LOGIN
              </Button>
            </Flex>
          </Modal>
        </>
      )}
    </>
  );
};

export default CadastrarAtualizarUsuario;

import "./style/index.scss";
import { useEffect, useState } from "react";
import { AgendamentoState, procedimentoFetch, resetProcedimento, setMensagemProcedimento, setProcedimento, setProcedimentosFiltrados, tipoExameFetch } from "../../../redux/slices/agendamento/agendamentoSlice";
import { ConfiguracoesState, setPaginaAgendamento, setPaginaAnterior, setVoltar } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import Spinner from "../../../components/spinner/spinner";
import { Flex, List, Segmented, Typography, Card, Input, Button, Modal, Image } from "antd";
import StepsComponent from "../../../components/steps";
import Flecha from "../../../components/flecha";
import { ProcedimentoModel } from "../../../models/agendamento/procedimentoModel";
import { CarrinhoState, adicionarCarrinhoFetch, setMontagemCarrinho, setTipoExameSelecionado } from "../../../redux/slices/carrinho/carrinhoSlice";
import { TipoExameModel } from "../../../models/agendamento/tipoExameModel";
import { AgendamentoSelecaoCarrinhoModel } from "../../../models/carrinho/agendamentoSelecaoCarrinhoModel";
import ScrollContainer from "react-indiana-drag-scroll";
import { ListarProcedimentoModel } from "../../../models/agendamento/listarProcedimentoModel";
import IconeCarrinhoComponent from "../../../components/carrinho";
import { LoginState, setTurnedSwipeExames } from "../../../redux/slices/account/loginSlice";
import SwipeComponent from "../../../components/swipe";
import { ArrowRightOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { PlanoModel } from "../../../models/agendamento/convenioModel";
import { ArrowBendDownRight } from "@phosphor-icons/react";
import imgNaoEncontrado from "../../../util/assets/nao_encontrado.png";
import Parser from "html-react-parser";

const { Title, Text, Link } = Typography;

const Exames = () => {
  //Estados Globais
  const agendamentoState: AgendamentoState = useAppSelector((state) => state.agendamento);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const carrinhoState: CarrinhoState = useAppSelector((state) => state.carrinho);
  const loginState: LoginState = useAppSelector((state) => state.login);

  //Hooks
  const dispatch = useAppDispatch();

  //Estados Locais
  const [onSwipe, setOnSwipe] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setVoltar(true));
    if (loginState.turnedSwipeExames === false) {
      dispatch(setTurnedSwipeExames(true));
      setOnSwipe(true);
    }
    if (configuracoesState.orcamento) {
      dispatch(setPaginaAnterior("paciente"));
    } else {
      dispatch(setPaginaAnterior("convenios"));
    }
    dispatch(tipoExameFetch({ idLocal: carrinhoState.montagemCarrinho.idLocal, idConvenio: carrinhoState.montagemCarrinho.idConvenio, idPlano: carrinhoState.montagemCarrinho.idPlano }));
  }, []);

  const onChangeTipoExame = async (value: any) => {
    setFilterValue("");

    var exame = agendamentoState.listTipoExame.filter((a) => a.idTipoExameExterno === value)[0];
    dispatch(setMensagemProcedimento(exame.mensagemExameNaoEncontrado));
    dispatch(setTipoExameSelecionado(value));
    dispatch(setProcedimento(exame.procedimentos));
    dispatch(setProcedimentosFiltrados(exame.procedimentos));
  };

  const onChangeProcedimento = (value: number) => {
    if (configuracoesState.orcamento) {
      var carrinhoArgs: AgendamentoSelecaoCarrinhoModel = { ...carrinhoState.montagemCarrinho, idProcedimento: value, tipoPreferencia: 1, idExecutantePreferencia: null };
      dispatch(adicionarCarrinhoFetch(carrinhoArgs));
      dispatch(setPaginaAgendamento("buscarHorarios"));
    } else {
      var exame = agendamentoState.listTipoExame.filter((a) => a.idTipoExameExterno === carrinhoState.tipoExameSelecionado)[0];
      if (exame.permiteMedicoPreferencia) {
        var carrinhoArgs: AgendamentoSelecaoCarrinhoModel = { ...carrinhoState.montagemCarrinho, idProcedimento: value };
        dispatch(setMontagemCarrinho(carrinhoArgs));
        dispatch(setPaginaAgendamento("medico"));
      } else {
        var carrinhoArgs: AgendamentoSelecaoCarrinhoModel = { ...carrinhoState.montagemCarrinho, idProcedimento: value, tipoPreferencia: 1, idExecutantePreferencia: null };
        dispatch(adicionarCarrinhoFetch(carrinhoArgs));
        dispatch(setPaginaAgendamento("buscarHorarios"));
      }
    }
  };

  const turnOffSwipe = () => {
    setOnSwipe(false);
  };

  const filtroProcedimento = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilterValue(e.target.value);
    const inputWords = e.target.value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .split(" ")
      .filter((word) => word); // Remove empty strings

    const listaOriginal = agendamentoState.listProcedimento;

    let filtrado = listaOriginal.filter((a) =>
      inputWords.every((inputWord) =>
        a.textoBusca
          .toLowerCase()
          .split(" ")
          .some((b) => b.includes(inputWord))
      )
    );

    dispatch(setProcedimentosFiltrados(filtrado));
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <>
          <Flex vertical style={{ height: "100%" }} gap={14} flex={"1 1 auto"}>
            <StepsComponent current={1} />
            <Flex justify="flex-start" align="center" gap={10}>
              <Flex justify="flex-start" align="center" gap={10} style={{ width: "100%" }}>
                <Flecha />
                <Title className="tituloFormaAgendamento" level={4}>
                  Forma de agendamento
                </Title>
              </Flex>
              <Flex justify="flex-end">
                <IconeCarrinhoComponent />
              </Flex>
            </Flex>
            <Flex justify="flext-start">
              <Flex style={{ marginTop: "-20px" }} align="flex-start" justify="flex-start" gap={3} vertical>
                <Text style={{ marginLeft: "24.5px", fontSize: "13px" }}>{agendamentoState.listConvenio.filter((a) => a.idConvenioExterno === carrinhoState.montagemCarrinho.idConvenio)[0].denominacao}</Text>
                {carrinhoState.montagemCarrinho.idPlano !== null && agendamentoState.listConvenio.filter((a) => a.idConvenioExterno === carrinhoState.montagemCarrinho.idConvenio)[0].mostrarPlano === true ? (
                  <>
                    <ArrowBendDownRight style={{ marginLeft: "31.5px", marginTop: "-6px" }} size={16} />
                    <Flex align="center" style={{ marginTop: "-3px", marginLeft: "3px" }}>
                      <Text style={{ fontSize: "13px", marginLeft: "47.5px", marginTop: "-17px" }}>
                        {agendamentoState.listConvenio.filter((a) => a.idConvenioExterno === carrinhoState.montagemCarrinho.idConvenio)[0].planos.filter((b: PlanoModel) => b.idPlanoExterno === carrinhoState.montagemCarrinho.idPlano)[0].denominacao}
                      </Text>
                      <Text style={{ fontSize: "11px", marginTop: "-18px", marginLeft: "3px", textDecoration: "underline", color: "blue", textDecorationSkipInk: "none", cursor: "pointer" }} onClick={() => dispatch(setPaginaAgendamento("convenios"))}>
                        {"(Trocar)"}
                      </Text>
                    </Flex>
                  </>
                ) : (
                  <></>
                )}
              </Flex>
              {carrinhoState.montagemCarrinho.idPlano !== null && agendamentoState.listConvenio.filter((a) => a.idConvenioExterno === carrinhoState.montagemCarrinho.idConvenio)[0].mostrarPlano === true ? (
                <></>
              ) : (
                <Text style={{ fontSize: "11px", marginTop: "-18px", marginLeft: "3px", textDecoration: "underline", color: "blue", textDecorationSkipInk: "none", cursor: "pointer" }} onClick={() => dispatch(setPaginaAgendamento("convenios"))}>
                  {"(Trocar)"}
                </Text>
              )}
            </Flex>
            <Flex justify="flex-start" align="center" gap={10}>
              <Flecha />
              <Title className="titulo" level={4}>
                Escolha dos exames
              </Title>
            </Flex>
            {agendamentoState.listTipoExame.length !== 0 ? (
              <>
                <Text style={{ fontSize: "11px", marginLeft: "23px", marginTop: "-18px", wordBreak: "break-all", textDecoration: "underline", color: "blue", textDecorationSkipInk: "none", cursor: "pointer" }} onClick={() => setOpenModal(true)}>
                  {"(Não encontrei o meu exame)"}
                </Text>
                <Flex vertical gap={10}>
                  <Flex style={{ marginBottom: "5px" }}>
                    <ScrollContainer onScroll={turnOffSwipe}>
                      {onSwipe && agendamentoState.listTipoExame.length > 2 ? <SwipeComponent /> : <></>}
                      <Segmented
                        size="middle"
                        onChange={onChangeTipoExame}
                        options={agendamentoState.listTipoExame.map((a: TipoExameModel) => ({
                          value: a.idTipoExameExterno,
                          label: a.denominacao,
                        }))}
                      />
                    </ScrollContainer>
                  </Flex>
                  <Input
                    onChange={filtroProcedimento}
                    allowClear
                    prefix={<SearchOutlined style={{ color: "#d9d9d9" }} rev={undefined} />}
                    placeholder="Busque pelo nome do exame"
                    type="text"
                    value={filterValue}
                    style={{ backgroundColor: "#ffffff", height: 40, border: "1px solid #b3b3b3", marginBottom: "10px" }}
                  />
                  <Flex vertical>
                    {agendamentoState.procedimentosFiltrados.map((a: ProcedimentoModel, index) => (
                      <Card key={index} className="cardProcedimentos" onClick={() => onChangeProcedimento(a.idProcedimentoExterno)}>
                        <Flex style={{ width: "100%" }} gap={16} justify="space-between" align="center">
                          <Text style={{ wordBreak: "break-all", fontSize: "13px" }}>{a.denominacao}</Text>
                          <Flex align="center" justify="center">
                            <RightOutlined rev={undefined} style={{ color: "#909090", fontSize: "12px" }} />
                          </Flex>
                        </Flex>
                      </Card>
                    ))}
                  </Flex>
                </Flex>
              </>
            ) : (
              <Text style={{ marginLeft: "23px", marginTop: "-18px" }}>Não possui tipos de exames para a forma de agendamento selecionada</Text>
            )}
          </Flex>
          <Modal width={320} centered open={openModal} footer={null} onCancel={() => setOpenModal(false)} destroyOnClose closeIcon={false}>
            <Flex vertical justify="center" align="center" gap={10}>
              <Image preview={false} src={imgNaoEncontrado} />
              {Parser(agendamentoState.mensagemProcedimento)}
              <Button style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }} onClick={() => setOpenModal(false)}>
                VOLTAR
              </Button>
            </Flex>
          </Modal>
          <div style={agendamentoState.loadingAgendamento ? { position: "fixed", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "#F2F2F2", opacity: 0.6, maxWidth: "500px", margin: "0 auto" } : { display: "none" }}>
            <Spinner />
          </div>
        </>
      )}
    </>
  );
};

export default Exames;

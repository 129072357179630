import api from "../util/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { LoginModel } from "../models/account/loginModel";
import { AlterarSenhaModel } from "../models/account/alterarSenhaModel";
import { CriarUsuarioModel } from "../models/account/criarUsuarioModel";
import { RecuperarSenhaModel } from "../models/account/recuperarSenhaModel";
import { EnviarCodigoModel } from "../models/account/enviarCodigoModel";
import { VerificarCodigoModel } from "../models/account/verificarCodigoModel";
import { ConfirmacaoUsuarioModel } from "../models/account/confirmacaoUsuarioModel";
import axios from "axios";
import environment from "../environment";
import { VerificarPodeUtilizarLoginModel } from "../models/account/verificarPodeUtilizarLoginModel";

export const criarUsuario = async (args: CriarUsuarioModel) => {
  return await api.post("/Account/criarUsuario", args);
};

export const login = async (args: LoginModel) => {
  return await api.post("/Account/login", args);
};

export const alterarSenha = async (args: AlterarSenhaModel) => {
  return await api.post("/Account/alterarSenha", args);
};

export const recuperarSenha = async (args: RecuperarSenhaModel) => {
  return await api.post("/Account/recuperarSenha", args);
};

export const enviarCodigo = async (args: EnviarCodigoModel) => {
  return await api.post("/Account/enviarCodigo", args);
};

export const verificarCodigo = async (args: VerificarCodigoModel) => {
  return await api.post("/Account/verificarCodigo", args);
};

export const confirmacaoUsuario = async (args: ConfirmacaoUsuarioModel) => {
  return await api.get("/Account/confirmacaoUsuario", {
    params: args,
  });
};

export const mensagemAtualizacaoCadastro = async (clientId: string) => {
  return await api.get("/Account/mensagemAtualizacaoCadastro", {
    params: { clientId: clientId },
  });
};

export const verificarPodeUtilizarLogin = async (args: VerificarPodeUtilizarLoginModel) => {
  return await api.get("/Account/verificarPodeUtilizarLogin", {
    params: args,
  });
};

export const configuracaoEmpresa = async (clientId: string) => {
  const instance = axios.create({});
  instance.interceptors.request.use(async (config: any) => {
    config.baseURL = environment.baseURL;
    return config;
  });
  return await instance.get("/Account/configuracaoEmpresa", {
    params: { clientId: clientId },
  });
};

import "./style/index.scss";
import moment from "moment";
import { Card, Divider, Flex, List, Typography, Image, Avatar, Collapse, Modal, Button, Dropdown, Menu, Radio, RadioChangeEvent, Tooltip } from "antd";
import { ConfiguracoesState, setIdAtendimento, setPaginaAgendamento, setSolicitarCarteirinha, setSolicitarGuia, setVemdeMeusAgendamentos, setVoltar } from "../../redux/slices/configuracoes/configuracoesSlice";
import { PerfilState, meusAgendamentosFetch, preparoAtendimentoFetch, removerProcedimentoFetch } from "../../redux/slices/perfil/perfilSlice";
import { useAppDispatch, useAppSelector } from "../../util/hooks";
import Flecha from "../../components/flecha";
import { ExamesModel, MeusAgendamentosModel } from "../../models/perfil/meusAgendamentosModel";
import { useEffect, useState } from "react";
import { CalendarOutlined, ClockCircleOutlined, DeleteOutlined, EllipsisOutlined, FileDoneOutlined, FileExcelOutlined, InfoCircleOutlined, StopOutlined, UserOutlined, WarningOutlined } from "@ant-design/icons";
import { Hourglass, MapPin, ThumbsUp } from "@phosphor-icons/react";
import Spinner from "../../components/spinner/spinner";
import iconGeral from "../../util/assets/icons/geral.svg";
import ScrollContainer from "react-indiana-drag-scroll";
import { LoginState } from "../../redux/slices/account/loginSlice";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;
const { Panel } = Collapse;

moment.locale("pt-br");

const MeusAgendamentos = () => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);
  const perfilState: PerfilState = useAppSelector((state) => state.perfil);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);

  //Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //Estados Locais
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalTodos, setOpenModalTodos] = useState<boolean>(false);
  const [idAgenda, setIdAgenda] = useState<number>(0);
  const [idAgendaTodos, setIdAgendaTodos] = useState<number[]>([]);
  const [checked, setChecked] = useState<number | null>(1);

  const meusAgendamentosProximos = perfilState.meusAgendamentos.filter((a: MeusAgendamentosModel) => !a.eHistorico);

  useEffect(() => {
    dispatch(setVoltar(false));
    setOpenModal(false);
    setOpenModalTodos(false);
    dispatch(meusAgendamentosFetch());
  }, []);

  const removerProcedimento = () => {
    dispatch(removerProcedimentoFetch([idAgenda]));
    setOpenModal(false);
  };

  const removerTodosProcedimentos = () => {
    dispatch(removerProcedimentoFetch(idAgendaTodos));
    setOpenModalTodos(false);
  };

  const abrirModal = (idAgenda: number) => {
    setIdAgenda(idAgenda);
    setOpenModal(true);
  };

  const abrirModalCancelarTodos = (idAgendaTodos: number[]) => {
    setIdAgendaTodos(idAgendaTodos);
    setOpenModalTodos(true);
  };

  const abrirPreparo = (idAtendimento: number) => {
    dispatch(setVemdeMeusAgendamentos(true));
    dispatch(preparoAtendimentoFetch(idAtendimento));
    dispatch(setPaginaAgendamento("preparacao"));
    navigate("/agendamento/" + loginState.empresa, { replace: true });
  };

  const abrirAnexos = (idAtendimento: number) => {
    dispatch(setIdAtendimento(idAtendimento));
    dispatch(setVemdeMeusAgendamentos(true));
    dispatch(setSolicitarCarteirinha(perfilState.meusAgendamentos.filter((a: MeusAgendamentosModel) => a.idAtendimento === idAtendimento)[0].solicitarCarteirinha));
    dispatch(setSolicitarGuia(perfilState.meusAgendamentos.filter((a: MeusAgendamentosModel) => a.idAtendimento === idAtendimento)[0].solicitarGuia));
    dispatch(setPaginaAgendamento("anexos"));
    navigate("/agendamento/" + loginState.empresa, { replace: true });
  };

  const onChangeRadio = ({ target: { value } }: RadioChangeEvent) => {
    if (value === 1) {
      setChecked(1);
    } else {
      setChecked(2);
    }
  };

  const menu = (value: MeusAgendamentosModel) => (
    <Menu>
      <Menu.Item key="1" onClick={() => abrirModalCancelarTodos(value.idsAgendaAtendimento)}>
        Cancelar tudo
      </Menu.Item>
      {value.possuiPreparo ? (
        <Menu.Item key="2" onClick={() => abrirPreparo(value.idAtendimento)}>
          Ver Preparo
        </Menu.Item>
      ) : (
        <></>
      )}
      {perfilState.meusAgendamentos.filter((a: MeusAgendamentosModel) => a.idAtendimento === value.idAtendimento)[0].solicitarCarteirinha === false &&
      perfilState.meusAgendamentos.filter((a: MeusAgendamentosModel) => a.idAtendimento === value.idAtendimento)[0].solicitarGuia === false ? (
        <></>
      ) : (
        <Menu.Item key="3" onClick={() => abrirAnexos(value.idAtendimento)}>
          Enviar Documentos
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <>
          <Flex vertical style={{ height: "100%" }} gap={10} flex={"1 1 auto"}>
            <Flex justify="flex-start" align="center" gap={10}>
              <Flecha />
              <Title className="titulo" level={4}>
                {perfilState.meusAgendamentos.length === 0 ? "Você não possui agendamentos" : "Meus Agendamentos" + " (" + perfilState.meusAgendamentos.length + ")"}
              </Title>
            </Flex>
            <Flex justify="center">
              <Radio.Group value={checked} onChange={onChangeRadio} optionType="button" buttonStyle="solid">
                <Radio value={1}>PRÓXIMOS</Radio>
                <Radio style={{ marginLeft: "10px" }} value={2}>
                  HISTÓRICO
                </Radio>
              </Radio.Group>
            </Flex>
            <Flex vertical>
              {checked === 1 && meusAgendamentosProximos.length === 0 ? (
                <Flex justify="center" style={{ marginTop: "24px" }}>
                  <Title level={5}>Você não possui agendamentos futuros</Title>
                </Flex>
              ) : (
                <>
                  {perfilState.meusAgendamentos
                    .filter((a: MeusAgendamentosModel) => (checked === 1 ? !a.eHistorico : a.eHistorico))
                    .map((a: MeusAgendamentosModel, index) => {
                      return (
                        <Card key={index} size="small" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: "100%", textAlign: "center", marginTop: "14px", height: "fit-content" }}>
                          <Flex vertical style={{ width: "100%" }}>
                            <Flex vertical justify="center" gap={3} style={{ width: "100%", padding: "8px" }}>
                              <Flex gap={10} align="center" justify="flex-start" style={{ width: "100%" }}>
                                <Flex vertical gap={3} justify="flex-start" style={{ width: "90%" }}>
                                  <Flex justify="flex-start" align="center" style={{ width: "100%" }} gap={5}>
                                    <UserOutlined rev={undefined} />
                                    <Text ellipsis={{ tooltip: a.nomePaciente }} style={{ width: "100%", textAlign: "start" }}>
                                      {a.nomePaciente}
                                    </Text>
                                  </Flex>
                                  <Flex justify="flex-start" align="center" gap={5} style={{ width: "100%" }}>
                                    <MapPin />
                                    <Text>{a.local}</Text>
                                  </Flex>
                                </Flex>
                                <Flex justify="flex-end" style={{ width: "10%" }}>
                                  {!a.agendamentoImpedido ? (
                                    <ThumbsUp size={20} />
                                  ) : (
                                    <Tooltip title={"Agendamento com alertas"}>
                                      <WarningOutlined style={{ color: "#F4BB44", fontSize: 22 }} rev={undefined} />
                                    </Tooltip>
                                  )}
                                </Flex>
                              </Flex>
                              <Flex gap={20} justify="space-between" align="center" style={{ width: "100%" }}>
                                <Flex gap={3} justify="flex-start" style={{ width: "85%" }} vertical>
                                  <Flex justify="flex-start" align="center" gap={5}>
                                    <CalendarOutlined rev={undefined} />
                                    <Text>{moment(a.dataAtendimento).format("DD/MM/YYYY")}</Text>
                                  </Flex>
                                  <Flex gap={10}>
                                    <Flex justify="flex-start" align="center" gap={5}>
                                      <ClockCircleOutlined rev={undefined} />
                                      <Text>{a.horarioInicial}</Text>
                                    </Flex>
                                    <Flex justify="flex-start" align="center" gap={5}>
                                      <Hourglass />
                                      <Text>{a.duracaoAtendimento}min</Text>
                                    </Flex>
                                  </Flex>
                                  <Flex justify="flex-start" align="center" gap={5}>
                                    {!a.solicitarCarteirinha && !a.solicitarGuia ? (
                                      <>
                                        <FileDoneOutlined rev={undefined} />
                                        <Text>Documentos enviados</Text>
                                      </>
                                    ) : (
                                      <>
                                        <FileExcelOutlined style={{ color: "#F4BB44" }} rev={undefined} />
                                        <Text>Documentos não enviados</Text>
                                      </>
                                    )}
                                  </Flex>
                                  {a.informacoesAgendamento !== null ? (
                                    <>
                                      <Flex justify="center" align="flex-start" vertical>
                                        <Flex justify="flex-start" align="center" gap={5}>
                                          <InfoCircleOutlined rev={undefined} />
                                          <Text>Informações:</Text>
                                        </Flex>
                                        <Text style={{ marginLeft: 20, fontSize: "12px", fontWeight: "normal", textAlign: "justify", textJustify: "inter-word", wordBreak: "break-word", hyphens: "auto" }} lang="pt-b4">
                                          {a.informacoesAgendamento}
                                        </Text>
                                      </Flex>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Flex>
                                {checked === 1 ? (
                                  <Flex justify="flex-end" style={{ width: "15%" }}>
                                    <Dropdown dropdownRender={() => menu(a)}>
                                      <Button>
                                        <EllipsisOutlined rev={undefined} />
                                      </Button>
                                    </Dropdown>
                                  </Flex>
                                ) : (
                                  <></>
                                )}
                              </Flex>
                            </Flex>
                            <Collapse accordion ghost className="listaExames">
                              <Panel
                                header={
                                  <Flex justify="space-between" align="center">
                                    {"Ver exames" + " (" + a.exames.length + ")"}
                                  </Flex>
                                }
                                key="1"
                              >
                                <List
                                  rowKey="id"
                                  style={{ width: "100%" }}
                                  itemLayout="vertical"
                                  dataSource={a.exames}
                                  renderItem={(item: ExamesModel) => (
                                    <List.Item style={{ width: "100%" }}>
                                      <List.Item.Meta
                                        avatar={<Image preview={false} src={iconGeral} height={"30px"} width={"30px"} />}
                                        title={<Text style={{ fontSize: "13px", textAlign: "left" }}>{item.procedimento}</Text>}
                                        description={
                                          <Flex vertical style={{ marginTop: "-12px" }}>
                                            <Text style={{ fontSize: "10px", textAlign: "left" }}>{item.convenio}</Text>
                                            <Text style={{ fontSize: "10px", textAlign: "left" }}>{item.precoExame}</Text>
                                            <Text style={{ fontSize: "10px", textAlign: "left" }}>{item.preferencia}</Text>
                                          </Flex>
                                        }
                                      />
                                      {checked === 1 ? (
                                        <Button style={{ backgroundColor: "red", marginTop: "12px", color: "#ffffff" }} onClick={() => abrirModal(item.idAgenda)}>
                                          Cancelar
                                        </Button>
                                      ) : (
                                        <></>
                                      )}
                                    </List.Item>
                                  )}
                                />
                              </Panel>
                            </Collapse>
                          </Flex>
                        </Card>
                      );
                    })}
                </>
              )}
            </Flex>
          </Flex>
          <Modal width={320} centered open={openModal} footer={null} destroyOnClose closeIcon={false}>
            <Flex vertical justify="center" align="center" gap={10}>
              <Text style={{ textAlign: "center", color: "#101010" }}>Deseja cancelar o exame?</Text>
              <Flex gap={20}>
                <Button style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100px" }} onClick={removerProcedimento}>
                  SIM
                </Button>
                <Button style={{ width: "100px" }} onClick={() => setOpenModal(false)}>
                  NÃO
                </Button>
              </Flex>
            </Flex>
          </Modal>
          <Modal width={320} centered open={openModalTodos} footer={null} destroyOnClose closeIcon={false}>
            <Flex vertical justify="center" align="center" gap={10}>
              <Text style={{ textAlign: "center", color: "#101010" }}>Deseja cancelar todos os exames?</Text>
              <Flex gap={20}>
                <Button style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100px" }} onClick={removerTodosProcedimentos}>
                  SIM
                </Button>
                <Button style={{ width: "100px" }} onClick={() => setOpenModalTodos(false)}>
                  NÃO
                </Button>
              </Flex>
            </Flex>
          </Modal>
        </>
      )}
    </>
  );
};

export default MeusAgendamentos;

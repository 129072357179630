import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import { ConvenioModel, PlanoModel } from "../../../models/agendamento/convenioModel";
import { ConfiguracoesState, setPaginaAgendamento, setPaginaAnterior } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { CarrinhoState, setMontagemCarrinho } from "../../../redux/slices/carrinho/carrinhoSlice";
import { AgendamentoState, setPlanosFiltrados } from "../../../redux/slices/agendamento/agendamentoSlice";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import Spinner from "../../../components/spinner/spinner";
import { Card, Flex, Input, Modal, Typography, Image, Button } from "antd";
import StepsComponent from "../../../components/steps";
import Flecha from "../../../components/flecha";
import { RightOutlined, SearchOutlined } from "@ant-design/icons";
import imgNaoEncontrado from "../../../util/assets/nao_encontrado.png";
import Parser from "html-react-parser";

const { Title, Text } = Typography;

const Planos = () => {
  //Estados Globais
  const agendamentoState: AgendamentoState = useAppSelector((state) => state.agendamento);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const carrinhoState: CarrinhoState = useAppSelector((state) => state.carrinho);
  const loginState: LoginState = useAppSelector((state) => state.login);

  //Hooks
  const dispatch = useAppDispatch();

  //Estados Locais
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [entity, setEntity] = useState<PlanoModel>({} as PlanoModel);
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    dispatch(setPaginaAnterior("convenios"));
  }, []);

  const onChangePlano = (value: PlanoModel) => {
    if (value.alertaAgendamento === null) {
      var montagemCarrinho = { ...carrinhoState.montagemCarrinho };
      montagemCarrinho.idPlano = value.idPlanoExterno;
      dispatch(setMontagemCarrinho(montagemCarrinho));
      dispatch(setPaginaAgendamento("exames"));
    } else {
      setMessage(value.alertaAgendamento);
      setEntity(value);
      setOpenModal(true);
    }
  };

  const estouCiente = () => {
    if (entity.agendamentoPermitido) {
      var montagemCarrinho = { ...carrinhoState.montagemCarrinho };
      montagemCarrinho.idPlano = entity.idPlanoExterno;
      dispatch(setMontagemCarrinho(montagemCarrinho));
      dispatch(setPaginaAgendamento("exames"));
    } else {
      setOpenModal(false);
    }
  };

  const filtroPlano = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputWords = e.target.value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .split(" ")
      .filter((word) => word); // Remove empty strings

    const listaOriginal = agendamentoState.listConvenio.filter((a: ConvenioModel) => a.idConvenioExterno === carrinhoState.montagemCarrinho.idConvenio)[0].planos;

    let filtrado = listaOriginal.filter((a) =>
      inputWords.every((inputWord) =>
        a.denominacao
          .toLowerCase()
          .split(" ")
          .some((b) => b.includes(inputWord))
      )
    );

    dispatch(setPlanosFiltrados(filtrado));
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <Flex vertical style={{ height: "100%" }} gap={14} flex={"1 1 auto"}>
          <StepsComponent current={1} />
          <Flex justify="flex-start" align="center" gap={10}>
            <Flecha />
            <Title className="titulo" level={4}>
              Forma de agendamento
            </Title>
          </Flex>
          <Flex vertical gap={10}>
            <Input
              onChange={filtroPlano}
              allowClear
              prefix={<SearchOutlined style={{ color: "#d9d9d9" }} rev={undefined} />}
              placeholder="Busque pelo nome do plano"
              type="text"
              style={{ backgroundColor: "#ffffff", height: 40, border: "1px solid #b3b3b3", marginBottom: "10px" }}
            />
            {agendamentoState.planosFiltrados.map((item: PlanoModel, index) => (
              <Card key={index} className="cardConvenios" style={{ height: "30px" }} onClick={() => onChangePlano(item)}>
                <Flex style={{ width: "100%" }} gap={16} justify="space-between" align="center">
                  <Text style={{ wordBreak: "break-all", fontSize: "13px" }}>{item.denominacao}</Text>
                  <Flex align="center" justify="center">
                    <RightOutlined rev={undefined} style={{ color: "#909090", fontSize: "12px" }} />
                  </Flex>
                </Flex>
              </Card>
            ))}
          </Flex>
        </Flex>
      )}
      <Modal width={320} centered open={openModal} footer={null} destroyOnClose closeIcon={false}>
        <Flex vertical justify="center" align="center" gap={10}>
          <Image preview={false} src={imgNaoEncontrado} />
          {Parser(message)}
          <Button style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }} onClick={estouCiente}>
            ESTOU CIENTE
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

export default Planos;

export const formatTelefone = (value: string) => {
  const cleaned = ("" + value).replace(/\D/g, "");
  const match = cleaned.match(/^0*(\d{2})(\d{4,6})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ")" + match[2] + (cleaned.length > 10 ? "-" : "") + match[3];
  }
  return null;
};

export const formatTelefoneHidden = (value: string) => {
  const cleaned = ("" + value).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);
  if (match) {
    return "(" + "**" + ")" + "*".repeat(match[2].length) + "-" + "*".repeat(match[3].length - 2) + match[3].slice(-2);
  }
  return null;
};

export const formatEmailHidden = (value: string) => {
  const emailParts = value.split("@");
  if (emailParts.length === 2) {
    const username = emailParts[0];
    const domain = emailParts[1];
    if (username.length < 4) {
      return username + "@" + domain;
    }
    const hiddenUsername = username.slice(0, 2) + "*".repeat(username.length - 4);
    return hiddenUsername + "@" + domain;
  }
  return null;
};

export const formatCPF = (value: string) => {
  const cleaned = ("" + value).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);
  if (match) {
    return match[1] + "." + match[2] + "." + match[3] + "-" + match[4];
  }
  return null;
};

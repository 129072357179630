import "./style/index.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../util/hooks";
import Flecha from "../../components/flecha";
import { ConfiguracoesState, setLoading, setOrcamento, setPaginaAgendamento, setVoltar } from "../../redux/slices/configuracoes/configuracoesSlice";
import { LoginState } from "../../redux/slices/account/loginSlice";
import { PerfilState, perfilFetch } from "../../redux/slices/perfil/perfilSlice";
import { AgendamentoState, convenioFetch, localFetch } from "../../redux/slices/agendamento/agendamentoSlice";
import Spinner from "../../components/spinner/spinner";
import { Flex, Typography, Image, Row, Col, Modal, Button } from "antd";
import { CarrinhoState, limparCarrinhoFetch, listarCarrinhoFetch, resetMontagemCarrinho } from "../../redux/slices/carrinho/carrinhoSlice";
import { DollarOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const Painel = () => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);
  const perfilState: PerfilState = useAppSelector((state) => state.perfil);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const agendamentoState: AgendamentoState = useAppSelector((state) => state.agendamento);
  const carrinhoState: CarrinhoState = useAppSelector((state) => state.carrinho);

  //Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //Estados Locais

  useEffect(() => {
    dispatch(resetMontagemCarrinho());
    dispatch(setVoltar(false));
    dispatch(setLoading(false));
    dispatch(listarCarrinhoFetch());
    dispatch(setPaginaAgendamento("paciente"));
  }, []);

  useEffect(() => {
    if (Object.values(perfilState.perfil).length === 0) {
      dispatch(perfilFetch());
    }
  }, [perfilState.perfil]);

  const goPlanoExame = () => {
    if (configuracoesState.orcamento === true && carrinhoState.carrinho.procedimentos !== null && carrinhoState.carrinho.procedimentos.length > 0) {
      dispatch(limparCarrinhoFetch());
    }
    dispatch(setOrcamento(false));
    navigate("/agendamento/" + loginState.empresa, { replace: true });
  };

  const goMeusAgendamentos = () => {
    navigate("/meusAgendamentos/" + loginState.empresa, { replace: true });
  };

  const goPlanoExameOrcamento = () => {
    dispatch(setOrcamento(true));
    if (carrinhoState.carrinho.procedimentos !== null && carrinhoState.carrinho.procedimentos.length > 0) {
      dispatch(limparCarrinhoFetch());
    }
    navigate("/agendamento/" + loginState.empresa, { replace: true });
  };

  return (
    <>
      <div style={configuracoesState.loadingSpinner ? {} : { display: "none" }}>
        <Spinner />
      </div>
      <Flex vertical style={configuracoesState.loadingSpinner ? { display: "none" } : { height: "100%" }} gap={14}>
        <Flex justify="flex-start" align="center" gap={10}>
          <Flecha />
          <Title className="titulo" level={4}>
            Olá, {perfilState.perfil.nomePaciente}
          </Title>
        </Flex>
        <Flex>
          <Text style={{ marginTop: "-28px", marginLeft: "23px" }}>Escolha um serviço abaixo para iniciar</Text>
        </Flex>
        <Flex style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria }} className="cardOption" onClick={goPlanoExame} justify="space-between" gap={10} align="flex-start" vertical>
          <Image preview={false} width={22} src={require("../../util/assets/icone_agendamento.png")} />
          <Text style={{ fontSize: 12, color: "#ffffff" }}>AGENDAMENTO DE EXAMES</Text>
        </Flex>
        <Flex gap={10}>
          <Flex style={{ backgroundColor: "#808080" }} className="cardOption" onClick={goMeusAgendamentos} justify="space-between" align="flex-start" gap={10} vertical>
            <Image preview={false} width={22} src={require("../../util/assets/icone_resultado.png")} />
            <Flex vertical>
              <Text style={{ fontSize: 12, color: "#ffffff" }}>MEUS</Text>
              <Text style={{ fontSize: 12, color: "#ffffff" }}>AGENDAMENTOS</Text>
            </Flex>
          </Flex>
          <Flex style={{ backgroundColor: "#505050" }} className="cardOption" onClick={goPlanoExameOrcamento} justify="space-between" align="flex-start" gap={10} vertical>
            <DollarOutlined style={{ fontSize: 26 }} rev={undefined} />
            <Flex vertical>
              <Text style={{ fontSize: 12, color: "#ffffff" }}>ORÇAMENTO</Text>
              <Text style={{ fontSize: 12, color: "#ffffff" }}>DE EXAMES</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Painel;

import "./style/index.scss";
import { Card, Divider, Flex, Input, Modal, Typography, Image, Button } from "antd";
import StepsComponent from "../../../components/steps";
import Flecha from "../../../components/flecha";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import { AgendamentoState, convenioFetch, setConveniosFiltrados, setPlanosFiltrados } from "../../../redux/slices/agendamento/agendamentoSlice";
import { ConvenioModel } from "../../../models/agendamento/convenioModel";
import { RightOutlined, SearchOutlined } from "@ant-design/icons";
import { ConfiguracoesState, setPaginaAgendamento, setPaginaAnterior } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { CarrinhoState, setMontagemCarrinho } from "../../../redux/slices/carrinho/carrinhoSlice";
import Spinner from "../../../components/spinner/spinner";
import imgNaoEncontrado from "../../../util/assets/nao_encontrado.png";
import Parser from "html-react-parser";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import { PerfilState } from "../../../redux/slices/perfil/perfilSlice";

const { Title, Text } = Typography;

const Convenios = () => {
  //Estados Globais
  const agendamentoState: AgendamentoState = useAppSelector((state) => state.agendamento);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const carrinhoState: CarrinhoState = useAppSelector((state) => state.carrinho);
  const loginState: LoginState = useAppSelector((state) => state.login);
  const perfilState: PerfilState = useAppSelector((state) => state.perfil);

  //Hooks
  const dispatch = useAppDispatch();

  //Estados Locais
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [entity, setEntity] = useState<ConvenioModel>({} as ConvenioModel);
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    if (loginState.configuracaoAparencia.idLocalPadrao !== null) {
      dispatch(setPaginaAnterior("paciente"));
    } else {
      dispatch(setPaginaAnterior("unidade"));
    }
    dispatch(convenioFetch(carrinhoState.montagemCarrinho.idLocal));
  }, []);

  const onChangeConvenio = (value: ConvenioModel) => {
    if (value.alertaAgendamento === null) {
      avancar(value);
    } else {
      setMessage(value.alertaAgendamento);
      setEntity(value);
      setOpenModal(true);
    }
  };

  const estouCiente = () => {
    if (entity.agendamentoPermitido) {
      avancar(entity);
    } else {
      setOpenModal(false);
    }
  };

  const avancar = (value: ConvenioModel) => {
    var montagemCarrinho = { ...carrinhoState.montagemCarrinho };
    if (value.mostrarPlano) {
      montagemCarrinho.idConvenio = value.idConvenioExterno;
      dispatch(setMontagemCarrinho(montagemCarrinho));
      dispatch(setPlanosFiltrados(value.planos));
      dispatch(setPaginaAgendamento("planos"));
    } else {
      montagemCarrinho.idConvenio = value.idConvenioExterno;
      montagemCarrinho.idPlano = value.idPlanoPadrao;
      dispatch(setMontagemCarrinho(montagemCarrinho));
      dispatch(setPaginaAgendamento("exames"));
    }
  };

  const filtroConvenio = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputWords = e.target.value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .split(" ")
      .filter((word) => word); // Remove empty strings

    const listaOriginal = agendamentoState.listConvenio;

    let filtrado = listaOriginal.filter((a) =>
      inputWords.every((inputWord) =>
        a.denominacao
          .toLowerCase()
          .split(" ")
          .some((b) => b.includes(inputWord))
      )
    );

    dispatch(setConveniosFiltrados(filtrado));
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <Flex vertical style={{ height: "100%" }} gap={14} flex={"1 1 auto"}>
          <StepsComponent current={1} />
          <Flex justify="flex-start" align="center" gap={10}>
            <Flecha />
            <Title className="titulo" level={4}>
              Forma de agendamento
            </Title>
          </Flex>
          <Flex vertical gap={10}>
            <Input
              onChange={filtroConvenio}
              allowClear
              prefix={<SearchOutlined style={{ color: "#d9d9d9" }} rev={undefined} />}
              placeholder="Busque pelo nome do convênio"
              type="text"
              style={{ backgroundColor: "#ffffff", height: 40, border: "1px solid #b3b3b3", marginBottom: "10px" }}
            />
            {agendamentoState.conveniosFiltrados
              .filter((a: ConvenioModel) => a.particularPadrao === true)
              .map((item: ConvenioModel, index) => (
                <Card key={index} className="cardConvenios" style={{ height: "30px" }} onClick={() => onChangeConvenio(item)}>
                  <Flex style={{ width: "100%" }} gap={16} justify="space-between" align="center">
                    <Text style={{ wordBreak: "break-all", fontSize: "13px" }}>{item.denominacao}</Text>
                    <Flex align="center" justify="center">
                      <RightOutlined rev={undefined} style={{ color: "#909090", fontSize: "12px" }} />
                    </Flex>
                  </Flex>
                </Card>
              ))}
            {agendamentoState.conveniosFiltrados.filter((a: ConvenioModel) => a.idConvenioExterno === perfilState.perfil.idConvenioPreferencia).length !== 0 &&
            agendamentoState.conveniosFiltrados.filter((a: ConvenioModel) => a.idConvenioExterno !== perfilState.perfil.idConvenioPreferencia).length !== 0 ? (
              <Divider style={{ margin: "0px 0px" }} />
            ) : (
              <></>
            )}
            {agendamentoState.conveniosFiltrados
              .filter((a: ConvenioModel) => a.idConvenioExterno === perfilState.perfil.idConvenioPreferencia)
              .map((item: ConvenioModel, index) => (
                <Card key={index} className="cardConvenios" style={{ height: "30px" }} onClick={() => onChangeConvenio(item)}>
                  <Flex style={{ width: "100%" }} gap={16} justify="space-between" align="center">
                    <Text style={{ wordBreak: "break-all", fontSize: "13px" }}>{item.denominacao}</Text>
                    <Flex align="center" justify="center">
                      <RightOutlined rev={undefined} style={{ color: "#909090", fontSize: "12px" }} />
                    </Flex>
                  </Flex>
                </Card>
              ))}

            {agendamentoState.conveniosFiltrados.filter((a: ConvenioModel) => a.particularPadrao === true || a.idConvenioExterno === perfilState.perfil.idConvenioPreferencia).length !== 0 && agendamentoState.conveniosFiltrados.filter((a: ConvenioModel) => a.particularPadrao === false || a.idConvenioExterno === perfilState.perfil.idConvenioPreferencia).length !== 0 ? (
              <Divider style={{ margin: "0px 0px" }} />
            ) : (
              <></>
            )}
            {agendamentoState.conveniosFiltrados
              .filter((a: ConvenioModel) => a.particularPadrao === false && a.idConvenioExterno !== perfilState.perfil.idConvenioPreferencia)
              .map((item: ConvenioModel, index) => (
                <Card key={index} className="cardConvenios" style={{ height: "30px" }} onClick={() => onChangeConvenio(item)}>
                  <Flex style={{ width: "100%" }} gap={16} justify="space-between" align="center">
                    <Text style={{ wordBreak: "break-all", fontSize: "13px" }}>{item.denominacao}</Text>
                    <Flex align="center" justify="center">
                      <RightOutlined rev={undefined} style={{ color: "#909090", fontSize: "12px" }} />
                    </Flex>
                  </Flex>
                </Card>
              ))}
          </Flex>
        </Flex>
      )}
      <Modal width={320} centered open={openModal} footer={null} destroyOnClose closeIcon={false}>
        <Flex vertical justify="center" align="center" gap={10}>
          <Image preview={false} src={imgNaoEncontrado} />
          {Parser(message)}
          <Button style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }} onClick={estouCiente}>
            ESTOU CIENTE
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

export default Convenios;

import { useEffect } from "react";
import Flecha from "../../../components/flecha";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import { ConfiguracoesState, setVoltar } from "../../../redux/slices/configuracoes/configuracoesSlice";
import Spinner from "../../../components/spinner/spinner";
import { Button, Col, Flex, Form, Input, InputNumber, List, Row, Select, Typography } from "antd";
import { LoginState } from "../../../redux/slices/account/loginSlice";
import { MaskedInput } from "antd-mask-input";
import { PacienteModel } from "../../../models/perfil/pacienteModel";
import { PerfilState, atualizarDependenteFetch, criarDependenteFetch } from "../../../redux/slices/perfil/perfilSlice";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const { Title } = Typography;

type FormDependente = {
  nome: string;
  sexo: string;
  cpf: string;
  dataNascimento: string;
  peso: number;
  parentesco: string;
};

const NovoDependente = () => {
  //Estados Globais
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const loginState: LoginState = useAppSelector((state) => state.login);
  const perfilState: PerfilState = useAppSelector((state) => state.perfil);

  //Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //Estados Locais
  const [form] = Form.useForm<FormDependente>();

  const editar = Object.values(perfilState.atualizarDependente).length !== 0;

  useEffect(() => {
    dispatch(setVoltar(true));
    if (editar) {
      form.setFieldsValue({
        nome: perfilState.atualizarDependente.nomePaciente,
        sexo: perfilState.atualizarDependente.sexo,
        cpf: perfilState.atualizarDependente.cpf,
        dataNascimento: moment(perfilState.atualizarDependente.dataNascimento).format("DD/MM/YYYY"),
        peso: parseInt(perfilState.atualizarDependente.peso),
        parentesco: perfilState.atualizarDependente.parentesco,
      } as FormDependente);
    }
  }, []);

  const submit = (values: FormDependente) => {
    if (validateCPF(values.cpf.replace(/[^0-9]/g, "")) && validateDateOfBirth(values.dataNascimento)) {
      var dependente: PacienteModel = {} as PacienteModel;
      dependente.nomePaciente = values.nome;
      dependente.sexo = values.sexo;
      dependente.cpf = values.cpf.replace(/\D/g, "");
      dependente.peso = values.peso.toString();
      dependente.parentesco = values.parentesco;
      dependente.dataNascimento = new Date(moment(values.dataNascimento.replace(/\D/g, ""), "DDMMYYYY").format("YYYY-MM-DD"));

      if (editar) {
        dependente.idPaciente = perfilState.atualizarDependente.idPaciente;
        dependente.idUsuario = perfilState.perfil.idUsuario;
        dispatch(atualizarDependenteFetch({ value: dependente, navigate: navigate }));
      } else {
        dispatch(criarDependenteFetch({ value: dependente, navigate: navigate }));
      }
    } else {
      toast.error("CPF ou Data de nascimento inválidos");
    }
  };

  const validateCPF = (cpf: any) => {
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
    let result = true;

    [9, 10].forEach((j) => {
      let sum = 0;
      let r;
      cpf
        .split(/(?=)/)
        .splice(0, j)
        .forEach((e: any, i: any) => {
          sum += parseInt(e) * (j + 2 - (i + 1));
        });
      r = 11 - (sum % 11);
      r = r > 9 ? 0 : r; // If the result is 10 or 11, set it to 0
      if (r != cpf.substring(j, j + 1)) result = false;
    });

    return result;
  };

  const validateDateOfBirth = (dob: string) => {
    const dobParts = dob.split("/");

    if (dobParts.length !== 3 || dobParts[0].length !== 2 || dobParts[1].length !== 2 || dobParts[2].length !== 4) {
      return false;
    }

    const dobDate = new Date(`${dobParts[2]}-${dobParts[1]}-${dobParts[0]}`);

    if (dobDate.toString() === "Invalid Date") {
      return false;
    }
    if (dobDate.getTime() > Date.now()) {
      return false;
    }

    return true;
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <>
          <Flex justify="flex-start" align="center" gap={10}>
            <Flecha />
            <Title className="titulo" level={4}>
              {editar ? "Atualizar Dependente" : "Cadastro de Dependente"}
            </Title>
          </Flex>
          <Form layout="vertical" form={form} onFinish={submit}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="Nome completo"
                  name="nome"
                  rules={[
                    {
                      required: true,
                      message: "digite o nome completo do dependente",
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <Input type="text" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="Grau de Parentesco"
                  name="parentesco"
                  rules={[
                    {
                      required: true,
                      message: "selecione o grau de parentesco do dependente",
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    options={[
                      { value: "Avô", label: "Avô" },
                      { value: "Avó", label: "Avó" },
                      { value: "Pai", label: "Pai" },
                      { value: "Mãe", label: "Mãe" },
                      { value: "Filho", label: "Filho" },
                      { value: "Irmão", label: "Irmão" },
                      { value: "Cônjuge", label: "Cônjuge" },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={10}>
                <Form.Item
                  label="CPF"
                  name="cpf"
                  rules={[
                    {
                      required: true,
                      message: "digite o CPF do dependente",
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <MaskedInput className="masked" mask="000.000.000-00" inputMode="numeric" style={{ width: "100%" }}></MaskedInput>
                </Form.Item>
              </Col>
              <Col span={14}>
                <Form.Item
                  label="Data de nascimento"
                  name="dataNascimento"
                  rules={[
                    {
                      required: true,
                      message: "digite a data de nascimento do dependente",
                      pattern: /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4}|[0-9]{2})$/,
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <MaskedInput className="masked" mask="00/00/0000" inputMode="numeric" style={{ width: "100%" }}></MaskedInput>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Sexo Biológico"
                  name="sexo"
                  rules={[
                    {
                      required: true,
                      message: "selecione o sexo biológico do dependente",
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    options={[
                      { value: "M", label: "Masculino" },
                      { value: "F", label: "Feminino" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={8} className="inputNumberClass">
                <Form.Item
                  label="Peso"
                  name="peso"
                  rules={[
                    {
                      type: "number",
                      required: true,
                      message: "digite o peso do dependente",
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <InputNumber controls={false} precision={0} min={0} max={999} maxLength={3} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Button htmlType="submit" style={{ backgroundColor: loginState.configuracaoAparencia.corPrimaria, color: "#ffffff", width: "100%" }}>
                  {editar ? "ATUALIZAR" : "SALVAR"}
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

export default NovoDependente;

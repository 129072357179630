import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../util/hooks";
import Paciente from "./paciente";
import Unidade from "./unidade";
import Exames from "./exames";
import Medico from "./medico";
import BuscarHorarios from "./buscarHorarios";
import Conclusao from "./conclusao";
import Preparacao from "./preparacao";
import Finalizacao from "./finalizacao";
import { Flex, Typography } from "antd";
import { LoginState } from "../../redux/slices/account/loginSlice";
import { ConfiguracoesState, setVoltar } from "../../redux/slices/configuracoes/configuracoesSlice";
import Horarios from "./horarios";
import Anexos from "./anexos";
import Convenios from "./convenios";
import Planos from "./planos";
import AnexosObrigatorios from "./anexosObrigatorios";
import Questionario from "./questionario";

const { Title } = Typography;

const NotFound = () => {
  //Estados Globais
  const loginState: LoginState = useAppSelector((state) => state.login);

  //Hooks
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/painel/" + loginState.empresa, { replace: true });
  }, []);

  return <></>;
};

const pages: any = {
  paciente: Paciente,
  unidade: Unidade,
  exames: Exames,
  medico: Medico,
  buscarHorarios: BuscarHorarios,
  horarios: Horarios,
  conclusao: Conclusao,
  preparacao: Preparacao,
  finalizacao: Finalizacao,
  anexos: Anexos,
  anexosObrigatorios: AnexosObrigatorios,
  convenios: Convenios,
  planos: Planos,
  questionario: Questionario,
  notFound: NotFound,
};

const AgendamentoPageController = () => {
  //Estados Globais
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);

  const Page = pages[configuracoesState.paginaAgendamento];

  return (
    <>
      <Page />
    </>
  );
};

export default AgendamentoPageController;

import { createSlice } from "@reduxjs/toolkit";
import { PacienteModel } from "../../../models/perfil/pacienteModel";
import { MeusAgendamentosModel } from "../../../models/perfil/meusAgendamentosModel";
import { resetAll } from "../../actions/actions";
import { AnexoModel } from "../../../models/perfil/anexoModel";
import { criarDependente } from "../../../services/perfilService";
import { set } from "react-hook-form";

export interface PerfilState {
  loading: boolean;
  perfil: PacienteModel;
  meusAgendamentos: MeusAgendamentosModel[];
  pdfPreparo: string;
  anexoCarteirinha: AnexoModel;
  anexoGuia: AnexoModel;
  atualizarDependente: PacienteModel;
}

const initialState: PerfilState = {
  loading: false,
  perfil: {} as PacienteModel,
  meusAgendamentos: [],
  pdfPreparo: "",
  anexoCarteirinha: {} as AnexoModel,
  anexoGuia: {} as AnexoModel,
  atualizarDependente: {} as PacienteModel,
};

export const perfilSlice = createSlice({
  name: "perfil",
  initialState,
  reducers: {
    perfilFetch: () => {},
    setPerfil: (state, action) => {
      state.perfil = action.payload;
    },
    resetPerfil: (state) => {
      state.perfil = {} as PacienteModel;
    },
    updatePerfilFetch: (state, action) => {},
    cadastrarAnexoCarteirinhaFetch: (state, action) => {},
    setAnexoCarteirinha: (state, action) => {
      state.anexoCarteirinha = action.payload;
    },
    cadastrarAnexoGuiaFetch: (state, action) => {},
    setAnexoGuia: (state, action) => {
      state.anexoGuia = action.payload;
    },
    meusAgendamentosFetch: () => {},
    setMeusAgendamentos: (state, action) => {
      state.meusAgendamentos = action.payload;
    },
    removerProcedimentoFetch: (state, action) => {},
    preparoAtendimentoFetch: (state, action) => {},
    preparoAgendamentoFetch: (state, action) => {},
    setPdfPreparo: (state, action) => {
      state.pdfPreparo = action.payload;
    },
    criarDependenteFetch: (state, action) => {},
    atualizarDependenteFetch: (state, action) => {},
    removerDependenteFetch: (state, action) => {},
    setAtualizarDependente: (state, action) => {
      state.atualizarDependente = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Resetar estado
    builder.addCase(resetAll, () => initialState);
  },
});

export const {
  perfilFetch,
  setPerfil,
  resetPerfil,
  updatePerfilFetch,
  cadastrarAnexoCarteirinhaFetch,
  setAnexoCarteirinha,
  cadastrarAnexoGuiaFetch,
  setAnexoGuia,
  meusAgendamentosFetch,
  setMeusAgendamentos,
  removerProcedimentoFetch,
  preparoAgendamentoFetch,
  preparoAtendimentoFetch,
  setPdfPreparo,
  criarDependenteFetch,
  atualizarDependenteFetch,
  removerDependenteFetch,
  setAtualizarDependente,
} = perfilSlice.actions;

export default perfilSlice.reducer;

import { AgendamentoSelecaoCarrinhoModel } from "../models/carrinho/agendamentoSelecaoCarrinhoModel";
import api from "../util/api";

export const adicionarItemCarrinho = async (args: AgendamentoSelecaoCarrinhoModel) => {
  return await api.post("/Agendamento/Carrinho", args);
};

export const listarItensCarrinho = async () => {
  return await api.get("/Agendamento/Carrinho");
};

export const limparCarrinho = async () => {
  return await api.delete("/Agendamento/Carrinho");
};

export const removerItemCarrinho = async (id: number) => {
  return await api.delete("/Agendamento/Carrinho/" + id);
};

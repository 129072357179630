import "./style/index.scss";
import { useAppDispatch, useAppSelector } from "../../util/hooks";
import { CarrinhoState, removerItemCarrinhoFetch } from "../../redux/slices/carrinho/carrinhoSlice";
import { Card, Flex, List, Row, Typography, Image, Divider } from "antd";
import { ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";
import { ProcedimentosModel } from "../../models/carrinho/listaCarrinhoModel";
import iconGeral from "../../util/assets/icons/geral.svg";
import Spinner from "../spinner/spinner";

const { Text } = Typography;

const ListaExamesComponent = (props: any) => {
  //Estados Globais
  const carrinhoState: CarrinhoState = useAppSelector((state) => state.carrinho);

  //Hooks
  const dispatch = useAppDispatch();

  const deletarItemCarrinho = (value: number) => {
    dispatch(removerItemCarrinhoFetch(value));
  };

  return (
    <>
      {carrinhoState.loading ? (
        <Spinner />
      ) : (
        <List
          rowKey="id"
          style={{ width: "100%" }}
          itemLayout="vertical"
          grid={{ gutter: 16, column: 1 }}
          dataSource={carrinhoState.carrinho.procedimentos !== null ? carrinhoState.carrinho.procedimentos : []}
          renderItem={(item: ProcedimentosModel) => (
            <List.Item style={{ width: "100%" }}>
              <Card style={{ padding: "8px" }}>
                <Flex vertical style={{ width: "100%" }}>
                  <Flex align="center" justify="flex-start" gap={10} style={{ width: "100%" }}>
                    <Image preview={false} src={iconGeral} height={"30px"} width={"30px"} />
                    <Flex vertical>
                      <Text style={{ fontSize: "13px", wordBreak: "break-all" }}>{item.procedimento}</Text>
                      {item.convenio.toLowerCase() === item.plano.toLowerCase() ? (
                        <Text style={{ fontSize: "10px" }}>{item.convenio}</Text>
                      ) : (
                        <Flex align="center">
                          <Text style={{ fontSize: "10px" }}>{item.convenio}</Text>
                          <ArrowRightOutlined rev={undefined} style={{ fontSize: "9px", marginRight: "2px", marginLeft: "2px"}} />
                          <Text style={{ fontSize: "10px" }}>{item.plano}</Text>
                        </Flex>
                      )}
                      <Text style={{ fontSize: "10px" }}>{item.precoExame}</Text>
                    </Flex>
                    {props.podeRemover ? (
                      <Flex justify="flex-end" style={{ flexGrow: 1 }}>
                        <DeleteOutlined onClick={() => deletarItemCarrinho(item.id)} style={{ fontSize: "16px" }} rev={undefined} />
                      </Flex>
                    ) : (
                      <></>
                    )}
                  </Flex>
                  <Divider className="listDivider" />
                  <Text style={{ fontSize: "14px" }}>{item.preferencia}</Text>
                </Flex>
              </Card>
            </List.Item>
          )}
        ></List>
      )}
    </>
  );
};

export default ListaExamesComponent;

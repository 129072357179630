import "./style/index.scss";
import { useEffect, useRef, useState } from "react";
import {
  AgendamentoState,
  bloquearHorarioFetch,
  buscaDiasDisponiveisFetch,
  buscarHorariosFetch,
  setBuscaDiasDisponiveis,
  setHorariosFiltrados,
  setListaDiasDisponiveis,
  setModalDiasDisponiveis,
  setSegmentedValue,
} from "../../../redux/slices/agendamento/agendamentoSlice";
import { ConfiguracoesState, setPaginaAgendamento, setPaginaAnterior, setVoltar } from "../../../redux/slices/configuracoes/configuracoesSlice";
import { useAppDispatch, useAppSelector } from "../../../util/hooks";
import BotaoPrimario from "../../../components/botaoPrimario";
import Spinner from "../../../components/spinner/spinner";
import { Button, Card, Col, Divider, Flex, Form, Input, List, Modal, Radio, Row, Segmented, Select, Space, Typography } from "antd";
import StepsComponent from "../../../components/steps";
import Flecha from "../../../components/flecha";
import ScrollContainer from "react-indiana-drag-scroll";
import ListaExamesComponent from "../../../components/listaExames";
import { LoginState, setTurnedSwipeHorarios } from "../../../redux/slices/account/loginSlice";
import { DataDisponivel } from "../../../models/agendamento/agendamentoDiasDisponiveisModel";
import moment from "moment";
import "moment/locale/pt-br";
import dayjs from "dayjs";
import { LeftOutlined, PlusCircleOutlined, PlusOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { AgendamentoSugestaoHorarioModel } from "../../../models/agendamento/agendamentoSugestaoHorarioModel";
import { buscaDiasDisponiveis } from "../../../services/agendamentoService";
import { Divide } from "@phosphor-icons/react/dist/ssr";
import { toast } from "react-toastify";
import SwipeComponent from "../../../components/swipe";

dayjs.locale("pt-br");
moment.locale("pt-br");

const { Title, Text } = Typography;

const Horarios = () => {
  //Estados Globais
  const agendamentoState: AgendamentoState = useAppSelector((state) => state.agendamento);
  const configuracoesState: ConfiguracoesState = useAppSelector((state) => state.configuracoes);
  const loginState: LoginState = useAppSelector((state) => state.login);

  //Hooks
  const dispatch = useAppDispatch();

  //Estados Locais
  const [onSwipe, setOnSwipe] = useState<boolean>(false);

  useEffect(() => {
    if (loginState.turnedSwipeHorarios === false) {
      dispatch(setTurnedSwipeHorarios(true));
      setOnSwipe(true);
    }
    dispatch(setVoltar(true));
    dispatch(setPaginaAnterior("buscarHorarios"));
    dispatch(setSegmentedValue(agendamentoState.buscarHorarios.diaBuscado !== null ? moment(agendamentoState.buscarHorarios.diaBuscado).format("YYYY-MM-DD") : ""));
  }, []);

  const onChangeHorario = (value: AgendamentoSugestaoHorarioModel) => {
    dispatch(bloquearHorarioFetch(value));
  };

  const onChangeDia = (value: any) => {
    if (value === "posterior") {
      var index = agendamentoState.buscarHorarios.diasDisponiveis.diasDisponiveis.findIndex((a: DataDisponivel) => a.data === agendamentoState.listaDiasDisponiveis[agendamentoState.listaDiasDisponiveis.length - 1].data);
      if (
        agendamentoState.buscarHorarios.diasDisponiveis.diasDisponiveis[agendamentoState.buscarHorarios.diasDisponiveis.diasDisponiveis.length - 1].data === agendamentoState.listaDiasDisponiveis[agendamentoState.listaDiasDisponiveis.length - 1].data
      ) {
        dispatch(buscaDiasDisponiveisFetch(agendamentoState.buscarHorarios.diasDisponiveis.fimDataBusca));
      } else {
        if (index + 7 < agendamentoState.buscarHorarios.diasDisponiveis.diasDisponiveis.length) {
          dispatch(setListaDiasDisponiveis(agendamentoState.buscarHorarios.diasDisponiveis.diasDisponiveis.slice(index + 1, index + 8)));
        } else {
          dispatch(
            setListaDiasDisponiveis(
              agendamentoState.buscarHorarios.diasDisponiveis.diasDisponiveis.slice(agendamentoState.buscarHorarios.diasDisponiveis.diasDisponiveis.length - 7, agendamentoState.buscarHorarios.diasDisponiveis.diasDisponiveis.length)
            )
          );
        }
      }
    } else if (value === "anterior") {
      var index = agendamentoState.buscarHorarios.diasDisponiveis.diasDisponiveis.findIndex((a: DataDisponivel) => a.data === agendamentoState.listaDiasDisponiveis[0].data);
      if (agendamentoState.buscarHorarios.diasDisponiveis.diasDisponiveis[0].data === agendamentoState.listaDiasDisponiveis[0].data) {
        toast.warning("Não existem mais datas anteriores disponíveis");
      } else {
        if (index - 7 > 0) {
          dispatch(setListaDiasDisponiveis(agendamentoState.buscarHorarios.diasDisponiveis.diasDisponiveis.slice(index - 7, index)));
        } else {
          dispatch(setListaDiasDisponiveis(agendamentoState.buscarHorarios.diasDisponiveis.diasDisponiveis.slice(0, 7)));
        }
      }
    } else {
      dispatch(setSegmentedValue(value));
      buscarHorarios(value);
    }
  };

  const buscarHorarios = async (value: any) => {
    dispatch(setSegmentedValue(value));
    dispatch(setModalDiasDisponiveis(false));
    dispatch(buscarHorariosFetch({ buscarAParirDe: null, buscarApenasDia: dayjs(value).toDate() }));
  };

  const turnOffSwipe = () => {
    setOnSwipe(false);
  };

  const filtroHorarios = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputWords = e.target.value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .split(" ")
      .filter((word) => word);

    const listaOriginal = agendamentoState.buscarHorarios.horarios;

    let filtrado = listaOriginal.filter((a) =>
      inputWords.every((inputWord) =>
        moment(a.dataHora)
          .format("HH:mm")
          .toLowerCase()
          .split(" ")
          .some((b) => b.includes(inputWord))
      )
    );

    dispatch(setHorariosFiltrados(filtrado));
  };

  return (
    <>
      {configuracoesState.loadingSpinner ? (
        <Spinner />
      ) : (
        <>
          <Flex vertical style={{ height: "100%" }} gap={10}>
            <StepsComponent current={2} />
            <Flex justify="flex-start" align="center" gap={10}>
              <Flecha />
              <Title className="titulo" level={4}>
                Horários disponíveis
              </Title>
            </Flex>
            <Form layout="vertical" className="formHorarios">
              <Form.Item label="Dias disponíveis">
                <ScrollContainer onScroll={turnOffSwipe}>
                  {onSwipe && agendamentoState.listaDiasDisponiveis.length > 3 ? <SwipeComponent /> : <></>}
                  <Segmented
                    size="middle"
                    onChange={onChangeDia}
                    className="segmentedDias"
                    value={agendamentoState.segmentedValue}
                    options={[
                      { value: "anterior", label: <LeftOutlined rev={undefined} /> },
                      ...agendamentoState.listaDiasDisponiveis.map((a: DataDisponivel) => ({
                        value: moment(a.data).format("YYYY-MM-DD"),
                        label: moment(a.data).format("ddd DD/MM").toUpperCase(),
                      })),
                      { value: "posterior", label: <RightOutlined rev={undefined} /> },
                    ]}
                  />
                </ScrollContainer>
              </Form.Item>
              <Flex vertical gap={10}>
                <Input
                  onChange={filtroHorarios}
                  allowClear
                  prefix={<SearchOutlined style={{ color: "#d9d9d9" }} rev={undefined} />}
                  placeholder="Busque pelo horário desejado"
                  type="text"
                  style={{ backgroundColor: "#ffffff", height: 40, border: "1px solid #b3b3b3", marginBottom: "10px" }}
                />
                {agendamentoState.horariosFiltrados.map((a: AgendamentoSugestaoHorarioModel, index) => (
                  <Card key={index} className="cardMedicos" onClick={() => onChangeHorario(a)}>
                    <Flex style={{ width: "100%" }} gap={16} justify="space-between" align="center">
                      <Text style={{ wordBreak: "break-all", fontSize: "13px" }}>{moment(a.dataHora).format("HH:mm") + " " + "(Duração estimada: " + a.duracaoEstimada + " min)"}</Text>
                      <Flex align="center" justify="center">
                        <RightOutlined rev={undefined} style={{ color: "#909090", fontSize: "12px" }} />
                      </Flex>
                    </Flex>
                  </Card>
                ))}
              </Flex>
            </Form>
          </Flex>
          <div
            style={
              agendamentoState.loadingBuscarDiasDisponiveis || agendamentoState.loadingBuscarHorarios
                ? { position: "fixed", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "#F2F2F2", opacity: 0.6, maxWidth: "500px", margin: "0 auto" }
                : { display: "none" }
            }
          >
            <Spinner />
          </div>
        </>
      )}
    </>
  );
};

export default Horarios;

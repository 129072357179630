import "./style/index.scss";
import { useAppDispatch, useAppSelector } from "../../util/hooks";
import { setPaginaAgendamento } from "../../redux/slices/configuracoes/configuracoesSlice";
import { Badge, Flex } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { CarrinhoState, listarCarrinhoFetch } from "../../redux/slices/carrinho/carrinhoSlice";
import { useEffect } from "react";

const IconeCarrinhoComponent = () => {
  //Estados Globais
  const carrinhoState: CarrinhoState = useAppSelector((state) => state.carrinho);

  //Hooks
  const dispatch = useAppDispatch();

  const goBuscarHorarios = () => {
    if (carrinhoState.carrinho.procedimentos !== null && carrinhoState.carrinho.procedimentos.length > 0) {
      dispatch(setPaginaAgendamento("buscarHorarios"));
    }
  };

  return (
    <Badge count={carrinhoState.carrinho.procedimentos !== null ? carrinhoState.carrinho.procedimentos.length : 0}>
      <ShoppingCartOutlined onClick={goBuscarHorarios} className="iconShopCarrinho" rev={undefined} />
    </Badge>
  );
};

export default IconeCarrinhoComponent;
